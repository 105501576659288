import { lazy, useCallback } from 'react';
import type { ContentState, RawDraftContentState } from 'draft-js';

import { Header, Form, Input, Button } from '@jl/components';
import {
  useDocumentQuery,
  DocumentResult,
  useUpdateDocumentMutation,
  documentUpdateProps,
} from '@jl/graphql-schema';
import {
  formatTime,
  useTitle,
  isAdmin,
  editButton,
  maybeAddNamespace,
  linkify,
} from '@jl/utils';
import { Container, Row } from '@jl/assets';

import { useParams, useHistory, useAuth } from '../../hooks';

const Editor = lazy(() => import('@jl/components/src/editor.default'));

const DocumentWithConverters = ({
  convertToRaw,
  convertFromRaw,
}: {
  convertToRaw: (state: ContentState) => RawDraftContentState;
  convertFromRaw: (state: RawDraftContentState) => ContentState;
}) => {
  const [user] = useAuth();
  const { uri, id, edit } = useParams(['uri', 'id', 'edit']);
  const { replace } = useHistory();

  const documentId = maybeAddNamespace('document', { uri, id });
  const { data } = useDocumentQuery({
    variables: { id: documentId || 'UNSET' },
  });
  const document = (data && data.document) || {
    id: documentId || 'UNSET',
    title: undefined,
    updatedAt: '',
    __typename: 'Document',
  };
  useTitle([document?.title || documentId || 'Documents']);

  const documentValues = (d: DocumentResult | undefined | null) => {
    let content = undefined;
    try {
      content =
        (d && d.content && convertFromRaw(JSON.parse(d.content))) || undefined;
    } catch (e) {}

    return {
      content,
      title: (d && d.title) || '',
    };
  };

  const [mutate] = useUpdateDocumentMutation();
  const onSubmit = useCallback(
    (v: ReturnType<typeof documentValues>) => {
      if (document) {
        const val = {
          title: v.title,
          content: (v.content && JSON.stringify(convertToRaw(v.content))) || '',
        };
        mutate(documentUpdateProps(document, val));
        replace(linkify(document.id));
      }
    },
    [document],
  );

  return (
    <>
      <Header
        title={document ? document.title : ''}
        subtitle={
          document?.updatedAt ? `Updated ${formatTime(document.updatedAt)}` : ''
        }
        rightButtons={[
          isAdmin(user)
            ? editButton({ edit, replace, id: documentId })
            : undefined,
        ]}
      />
      <Container>
        <Form initialValues={documentValues(document)} onSubmit={onSubmit}>
          {!!edit && <Input name="title" label="Title" />}
          <Editor name="content" readOnly={!edit} />
          <Row>
            {!!edit && (
              <Button
                icon="upload"
                text="Update Document"
                type="submit"
                outline
              />
            )}
          </Row>
        </Form>
      </Container>
    </>
  );
};

const Document = lazy(async () => {
  const converters = await (
    await import('@jl/components/src/editor.converters')
  ).default;

  return { default: () => <DocumentWithConverters {...converters} /> };
});

export default Document;
