import {
  ChangeEvent,
  InputHTMLAttributes,
  lazy,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Row } from '@jl/assets';
import {
  geocode,
  isAddressValue,
  AddressValue,
  currentLocation,
  reverseGeocode,
  isOrUndefined,
} from '@jl/utils';

import { useField } from './use-form';
import { Boundary } from './boundary';
import { Label } from './label';
import { Field, Area } from './input.styles';
import { Button } from './button';
import { Dropdown, Column } from './address.styles';

const AddressMap = lazy(() => import('./address.map'));

export type AddressProps = {
  name: string;
  label: string;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'disabled'>;

const addressPlaceholders: Record<keyof AddressValue, string> = {
  display: '',
  latitude: 'Latitude',
  longitude: 'Longitude',
};

const addressKeys: (keyof AddressValue)[] = [
  'display',
  'latitude',
  'longitude',
];

export const Address = ({ name, label, disabled }: AddressProps) => {
  const { value, setValue } = useField(name, {
    guard: isOrUndefined(isAddressValue),
    default: { display: '' },
  });
  const [locationError, setLocationError] = useState<string>();
  const [advanced, setAdvanced] = useState(false);
  const [options, setOptions] = useState<AddressValue[]>([]);
  const [search, setSearch] = useState<string>();
  const setSearchValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.currentTarget.value;
    if (text) {
      setSearch(text);
    } else if (value && text === '') {
      setSearch(undefined);
      setValue(undefined);
    }
  };
  const setOption = (o?: AddressValue) => {
    if (o) {
      setValue(o);
    }
    setOptions([]);
    setSearch(undefined);
  };
  const setAdvancedValue = (k: string, kv: string) => {
    try {
      let actual: string | number = kv;
      if (k !== 'display') {
        const parsed = parseFloat(kv);
        if (typeof parsed === 'number') actual = parsed;
      }
      const newValue = {
        display: '',
        latitude: undefined,
        longitude: undefined,
        ...value,
        [k]: actual,
      };
      setValue(newValue);
    } catch {}
  };
  const setCurrentLocation = async () => {
    setLocationError(undefined);
    try {
      const location = await currentLocation();
      const result = await reverseGeocode(location);
      setValue(result);
    } catch (e) {
      if (e instanceof Error) {
        setLocationError(e.message);
      } else {
        setLocationError('Unavailable');
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search) {
        geocode(search).then(setOptions);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [search]);

  return useMemo(
    () => (
      <Label
        text={label}
        action={[
          {
            text: advanced ? 'Simple' : 'Advanced',
            onClick: () => setAdvanced(!advanced),
          },
          {
            text: 'Current Location',
            disabled: !!locationError,
            title: locationError,
            onClick: setCurrentLocation,
          },
        ]}
      >
        <Row>
          {advanced ? (
            <Column>
              {addressKeys.map((k) => (
                <Field
                  key={k}
                  placeholder={addressPlaceholders[k]}
                  value={(value && value[k]) || ''}
                  onChange={(e) => setAdvancedValue(k, e.currentTarget.value)}
                />
              ))}
            </Column>
          ) : (
            <Area
              disabled={disabled}
              value={(search === undefined ? value?.display : search) || ''}
              onChange={setSearchValue}
              onBlur={() => setTimeout(setOption, 400)}
            />
          )}
          <Boundary>
            <AddressMap
              value={value?.display ? value : undefined}
              setValue={setValue}
            />
          </Boundary>
        </Row>
        <Dropdown>
          {options.map((o) => (
            <Button
              key={o.display}
              text={o.display}
              style="standard"
              onClick={() => setOption(o)}
              size={16}
              full
            />
          ))}
        </Dropdown>
      </Label>
    ),
    [search, advanced, options, value],
  );
};
