const ADMIN_ROLES = ['admin', 'god'];
const SETUP_ROLES = [...ADMIN_ROLES, 'setup'];

export const isRole = (test: string[]) => ({ roles }: { roles?: string[] }) =>
  !!(roles || []).find(
    (r) => !!test.find((t) => t.toLocaleLowerCase() === r.toLocaleLowerCase()),
  );

export const isAdmin = isRole(ADMIN_ROLES);
export const isSetupUser = isRole(SETUP_ROLES);
