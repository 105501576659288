import { styled } from '@jl/assets';

export const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-self: stretch;
  flex: 1 1 380px;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 99%;
  width: 100%;

  background: ${(props) => props.theme.background};
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2);
  z-index: 30;
`;
