import { useMemo, useState } from 'react';

import { Emphasize, Instruction, Row, Warning } from '@jl/assets';
import {
  Boundary,
  Button,
  Dropdown,
  Modal,
  StrappingChart,
  useForm,
} from '@jl/components';
import { useGaugesQuery } from '@jl/graphql-schema';
import { SortDirection, specialCharacters } from '@jl/utils';

const CopyGaugeForm = ({
  setModal,
}: {
  setModal: (modal: boolean) => void;
}) => {
  const { data } = useGaugesQuery();
  const strappingTables = useMemo(
    () =>
      data.accounts
        .flatMap((a) => a.gauges.map((g) => ({ ...g, account: a })))
        .filter((g) => !!g.setup?.strapping.length),
    [data.accounts],
  );

  const [selected, setSelected] = useState<(typeof strappingTables)[number]>();
  const form = useForm();

  return (
    <>
      <Dropdown
        label="Tank"
        placeholder="Select a Tank"
        empty="No Tanks Found"
        value={selected}
        setValue={setSelected}
        items={strappingTables}
        options={{
          sort: (t) => `${t.model}-${t.account.label}-${t.tank?.label}`,
          order: SortDirection.ASCENDING,
          keys: ['model', 'tank.label'],
          map: ({ id, model, tank, account }) => ({
            key: id,
            label: model || specialCharacters.enDash,
            detail: [account.label, tank?.label || specialCharacters.enDash],
          }),
        }}
      />
      <StrappingChart data={selected?.setup?.strapping || []} showEmpty />
      <Row>
        <Instruction>
          <Warning>
            {selected ? (
              <>
                <Emphasize strong>Warning: </Emphasize> Existing Settings Will
                Be Overwritten.
              </>
            ) : (
              specialCharacters.nbsp
            )}
          </Warning>
        </Instruction>
      </Row>
      <Row>
        <Button
          type="button"
          icon="copy"
          text="Copy"
          outline
          disabled={!selected}
          onClick={() => {
            if (selected?.setup) {
              form.setValue(
                'strapping',
                selected.setup.strapping.map(({ mm, litres }) => ({
                  mm,
                  litres,
                })),
              );
              form.setValue('model', selected.model);
              setModal(false);
            }
          }}
        />
      </Row>
    </>
  );
};

export const CopyGauge = ({
  modal,
  setModal,
}: {
  modal: boolean;
  setModal: (modal: boolean) => void;
}) => (
  <Modal
    visible={modal}
    setVisible={setModal}
    title="Copy an Existing Strapping Table"
  >
    <Boundary spinner>
      <CopyGaugeForm setModal={setModal} />
    </Boundary>
  </Modal>
);
