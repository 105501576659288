import { position, transparentize } from 'polished';

import { breakPoint, styled } from '@jl/assets';

export const AbsoluteBackground = styled.div`
  ${position('absolute', 0)};
  display: flex;
  z-index: 888;

  backdrop-filter: blur(5px);
  cursor: pointer;
`;

export const FixedBackground = styled(AbsoluteBackground)`
  ${position('fixed', 0)}
  z-index: 999;

  background-color: ${(props) => transparentize(0.2, props.theme.border)};
  backdrop-filter: blur(3px);
`;

export const Content = styled.div<{ width: number }>`
  background-color: ${(props) => props.theme.background};
  border-radius: 5px;
  cursor: default;

  height: auto;
  max-height: 98%;
  width: 98%;
  max-width: ${(props) => props.width}px;
  margin: auto;
  padding: 10px;
  overflow-y: auto;

  @media (max-width: ${breakPoint}px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
`;
