import { ResponsiveLine } from '@nivo/line';

import { useTheme, Hint, Rule } from '@jl/assets';
import { avg, specialCharacters, sum, useShortTime } from '@jl/utils';

export interface StatusLineProps {
  timestamps: number[];
  durations: number[];
  invocations?: number[];
  label: string;
  mode?: string;
}

export const StatusLine = ({
  label,
  timestamps,
  invocations,
  durations,
}: StatusLineProps) => {
  const theme = useTheme();
  const average = (avg(durations) / 1000).toFixed(1);
  const data = durations.map((duration, i) => ({
    x: new Date(timestamps[i] * 1000).toISOString(),
    y: duration,
  }));
  const uptime = invocations
    ? invocations.length
      ? Math.round((sum(invocations) / invocations.length) * 100)
      : specialCharacters.enDash
    : undefined;
  const latest = `latest ${((durations[0] || 0) / 1000).toFixed(2)}s`;
  const age = useShortTime(timestamps[0] * 1000);

  return (
    <>
      <Hint>
        <strong>{label}: </strong>
        <span>
          {latest} ({age})
        </span>
        <span> | avg {average}s</span>
        {uptime && <span> | uptime {uptime}%</span>}
      </Hint>
      <div style={{ width: '100%', height: 50 }}>
        {!!data.length && (
          <ResponsiveLine
            data={[{ id: 'duration', data }]}
            xScale={{
              type: 'time',
              format: '%Y-%m-%dT%H:%M:%S.%LZ',
              precision: 'hour',
            }}
            axisLeft={null}
            axisBottom={null}
            colors={[theme.success]}
            curve="monotoneX"
            enableArea={true}
            isInteractive={false}
            enablePoints={false}
            enableSlices={false}
            enableGridX={false}
            enableGridY={false}
          />
        )}
      </div>
      <Rule />
    </>
  );
};
