import { Children, ReactNode } from 'react';

import { Action, Box, Display } from './count.styles';
import { Button, ButtonProps } from './button';

export type CountProps = {
  filtered: number;
  total: number;
  label: string;
  children?: ReactNode;
  action?: ButtonProps[];
};

export const Count = ({
  filtered,
  total,
  label,
  children,
  action,
}: CountProps) => (
  <Box>
    <Display filtered={filtered !== total}>
      {filtered !== total && (
        <span>
          Showing <strong>{filtered}</strong> of{' '}
        </span>
      )}
      <strong>{total}</strong> {label}
      {children && Children.map(children, (c) => <span> | {c}</span>)}
    </Display>
    {action?.length && !!filtered && (
      <Action>
        {action.map((a) => (
          <Button key={a.text} size={16} {...a} />
        ))}
      </Action>
    )}
  </Box>
);
