import { styled, breakPoint } from '@jl/assets';

export const MainHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px;
  margin-bottom: 15px;

  width: 100%;

  * {
    position: relative;
    z-index: 10;
  }
`;

export const Logo = styled.img`
  height: 30px;
`;

export const Links = styled.div<{ visible: boolean }>`
  button:first-of-type {
    display: none;
  }
  @media (max-width: ${breakPoint}px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;

    padding: 2px 80px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    background-color: ${props => props.theme.background};
    max-height: ${props => (props.visible ? '300px' : '50px')};
    transition: max-height 0.6s ease;
    overflow: hidden;

    button:first-of-type {
      display: flex;
    }
  }
`;

export const Link = styled.button<{ selected: boolean }>`
  background: none;
  border: 0 solid ${props => props.theme.action};
  color: ${props => props.theme.border};
  margin: 0.4rem 0 0.4rem 1rem;
  border-bottom-width: ${props => (props.selected ? 2 : 0)}px;
  padding: 8px 10px;
  padding-bottom: ${props => (props.selected ? 6 : 8)}px;

  font-size: 1.4rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.4rem;
  font-style: italic;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
