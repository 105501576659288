import { Title, Text, Rule, apiLogo, Emphasize } from '@jl/assets';
import { ApiError, NotFoundError, isNotNullOrUndefined } from '@jl/utils';

import { Border, Link } from './error-display.styles';
import { Logo } from './navigation.styles';

export interface ErrorDisplayProps {
  small?: boolean;
  error: Error;
}

export const ErrorDisplay = ({ small, error }: ErrorDisplayProps) => {
  let title = 'Error';
  let message = [error.message];

  if (error instanceof ApiError) {
    title = 'Server Error';
    message = [
      ...error.graphQLErrors.map((g) => g.message),
      error.networkError?.message,
    ].filter(isNotNullOrUndefined);
  }
  if (error instanceof NotFoundError) {
    title = `${error.type} Not Found`;
  }

  return (
    <Border small={small}>
      {small ? (
        <Text>
          <Emphasize strong>{title}</Emphasize>
        </Text>
      ) : (
        <>
          <Logo src={apiLogo('JoyLevel')} alt="JoyLevel" />
          <Title>{title}</Title>
          <Rule />
        </>
      )}
      {message.map((m, i) => (
        <Text key={i}>{m}</Text>
      ))}
      {!small && <Link href="/">Go To JoyLevel Home</Link>}
    </Border>
  );
};
