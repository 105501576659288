import { useMemo, useState } from 'react';

import { Disabled, Hint, Instruction, Row, Subtitle } from '@jl/assets';
import { SortOptions, sortAndFilterAndMap, specialCharacters } from '@jl/utils';

import { Info, Item, Items, Search, SelectableItem } from './dropdown.styles';
import { Label } from './label';
import { BoxSize } from './label.styles';
import { Button } from './button';
import { Icon } from './icon';

const DropdownItem = ({
  label,
  detail,
  selected,
  onSelect,
}: {
  label: string;
  detail: string[];
  selected?: boolean;
  onSelect?: () => void;
}) => (
  <SelectableItem aria-selected={selected} onClick={onSelect} type="button">
    <Info>
      <Subtitle>{label}</Subtitle>
      <Hint>{detail.join(' | ')}</Hint>
    </Info>
  </SelectableItem>
);

export type DropdownProps<V extends object> = {
  label: string;
  placeholder?: string;
  empty?: string;
  size?: BoxSize;
  value: V | undefined;
  setValue: (value: V | undefined) => void;
  items: V[];
  options: SortOptions<V> & {
    keys: string[];
    map: (i: V) => { key: string; label: string; detail: string[] };
  };
};

export const Dropdown = <V extends object>({
  label,
  placeholder,
  empty,
  size,
  value,
  setValue,
  items,
  options,
}: DropdownProps<V>) => {
  const [search, setSearch] = useState('');

  const filtered = useMemo(
    () =>
      sortAndFilterAndMap(items, {
        ...options,
        map: (o) => (
          <DropdownItem {...options.map(o)} onSelect={() => setValue(o)} />
        ),
        search,
      }),
    [items, options, search],
  );

  return (
    <Label text={label} size={size}>
      {value ? (
        <Row align="center">
          <DropdownItem selected {...options.map(value)} />
          <Button
            icon="times"
            style="standard"
            size={14}
            onClick={() => setValue(undefined)}
          />
        </Row>
      ) : (
        <Items>
          <Item type="button">
            <Search
              placeholder={placeholder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={() => setTimeout(() => setSearch(''), 200)}
            />
            <Icon name="angleDown" size={14} />
          </Item>
          <div className="list">
            {filtered.length ? (
              filtered
            ) : (
              <Item type="button">
                <Instruction>
                  <Disabled>{empty || specialCharacters.enDash}</Disabled>
                </Instruction>
              </Item>
            )}
          </div>
        </Items>
      )}
    </Label>
  );
};
