import { linkify } from './namespace';

export type EditButtonProps = {
  id: string | undefined;
  edit: string | undefined;
  replace: (page: string) => void;
};

const cancelB = { icon: 'times', style: 'cancel' } as const;
const editB = { icon: 'edit', style: 'standard' } as const;

export const editButton = ({ id, edit, replace }: EditButtonProps) =>
  (id &&
    (edit
      ? {
          key: 'cancel',
          ...cancelB,
          onClick: () => replace(linkify(id)),
        }
      : {
          key: 'edit',
          ...editB,
          onClick: () => replace(linkify(id, { params: { edit: '1' } })),
        })) ||
  undefined;
