import {
  InputHTMLAttributes,
  RefObject,
  TextareaHTMLAttributes,
  useMemo,
} from 'react';
import { isString, isNumber, isBoolean, useAutoResize } from '@jl/utils';

import { useTheme } from '@jl/assets';

import { useField } from './use-form';
import { Label } from './label';
import { BoxSize } from './label.styles';
import { Field, Area, ClearButton } from './input.styles';
import { Icon } from './icon';

export type InputProps = {
  name: string;
  label: string;
  search?: boolean;
  inputRef?: RefObject<HTMLInputElement & HTMLTextAreaElement>;
  size?: BoxSize;
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'disabled' | 'autoFocus' | 'readOnly' | 'step'
>;

export const Input = ({
  name,
  label,
  search,
  inputRef,
  size,
  ...rest
}: InputProps) => {
  const theme = useTheme();
  let guard: (e: unknown) => e is string | number | boolean;
  switch (rest.type) {
    case 'number': {
      guard = isNumber;
      break;
    }
    case 'checkbox': {
      guard = isBoolean;
      break;
    }
    default: {
      guard = isString;
      break;
    }
  }
  const inputProps = useField(name, { guard, default: '' });

  return useMemo(
    () => (
      <Label text={label} size={size}>
        <Field
          {...rest}
          {...inputProps}
          value={`${inputProps.value}`}
          ref={inputRef}
        />
        {search && inputProps.value && (
          <ClearButton onClick={() => inputProps.setValue('')}>
            <Icon name="times" colour={theme.background} size={16} />
          </ClearButton>
        )}
      </Label>
    ),
    [inputProps, search, label, theme],
  );
};

export const TextArea = ({
  name,
  label,
  ...rest
}: InputProps & Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'rows'>) => {
  const inputProps = useField(name, { guard: isString, default: '' });
  const ref = useAutoResize(inputProps.value);

  return useMemo(
    () => (
      <Label text={label}>
        <Area ref={ref} {...rest} {...inputProps} />
      </Label>
    ),
    [inputProps, label],
  );
};
