import {
  Html,
  Head,
  Preview,
  Body,
  Container,
  Row,
  Text,
  Link,
} from '@react-email/components';

import { EmailTemplate } from './types';
import { body, container } from './styles';
import { Footer } from './footer';

type BasicProps = {
  text: string;
  link: { text: string; href: string };
};

export const Basic: EmailTemplate<BasicProps> = {
  subject: () => `This is a basic email`,
  tracking: () => ({ ids: [] }),
  email: ({ text, link, address, host, support }) => (
    <Html>
      <Head />
      <Preview>Basic Email</Preview>
      <Body style={body}>
        <Container style={container}>
          <Row>
            <Text>{text}</Text>
            <Link href={link.href}>{link.text}</Link>
          </Row>
        </Container>
        <Footer address={address} host={host} support={support} />
      </Body>
    </Html>
  ),
};
