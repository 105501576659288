import { position, transparentize } from 'polished';

import { styled, breakPoint } from '@jl/assets';
import { Button } from './button';

export const Outer = styled.div`
  background: ${(props) => props.theme.background};

  @media (max-width: ${breakPoint}px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
  }

  @media (min-width: ${breakPoint}px) {
    min-width: 60px;
  }
`;

export const Inner = styled.div<{ show: boolean }>`
  @media (max-width: ${breakPoint}px) {
    ${position('fixed', 0)}
    display: ${(props) => (props.show ? 'flex' : 'none')};
    z-index: 10;

    cursor: pointer;
    background-color: ${(props) => transparentize(0.6, props.theme.borderPale)};
  }

  @media (min-width: ${breakPoint}px) {
    transition: margin-left 0.4s ease;
    margin-left: ${(props) => (props.show ? 0 : -300)}px;
    width: 300px;
  }
`;

export const Contents = styled.div`
  @media (max-width: ${breakPoint}px) {
    cursor: default;

    background-color: ${(props) => props.theme.background};
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: -1px 0 5px 1px
      ${(props) => transparentize(0.9, props.theme.border)};

    height: auto;
    max-height: 85%;
    width: 100%;
    margin: auto;
    margin-bottom: 0;
    padding: 10px;
    overflow-y: auto;
  }
`;

export const Toggle = styled(Button)`
  @media (max-width: ${breakPoint}px) {
    position: absolute;
    top: -70px;
    right: 40px;
    z-index: 9;
  }

  @media (min-width: ${breakPoint}px) {
    margin: 20px auto;
  }
`;
