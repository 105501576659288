import { Container, Hint } from '@jl/assets';
import { StatusLine } from '@jl/components';
import { useMetricsApiQuery } from '@jl/graphql-schema';

const ResponseTimes = () => {
  const { data } = useMetricsApiQuery();
  const api = data && data.metrics.api;
  if (!api) return null;

  const appsync = api.find(({ Id }) => Id === 'appsync_latency');
  const durations = api.find(({ Id }) => Id === 'mutations_duration');

  const received = api.find(({ Id }) => Id === 'mutations_received');
  const receivedCount = received && received.Values[0];
  const failed = api.find(({ Id }) => Id === 'mutations_failed');
  const failedCount = failed && failed.Values[0];

  return (
    <Container>
      <StatusLine
        label="API"
        timestamps={(appsync && appsync.Timestamps) || []}
        durations={(appsync && appsync.Values) || []}
      />
      <Hint>
        <br />
        <strong>Updates:</strong> {receivedCount} ({failedCount} failed)
      </Hint>
      <StatusLine
        label="Duration"
        timestamps={(durations && durations.Timestamps) || []}
        durations={(durations && durations.Values) || []}
      />
    </Container>
  );
};

export default ResponseTimes;
