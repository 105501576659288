import { transparentize } from 'polished';

import { styled, shadowFade, TTheme } from '@jl/assets';

export type TButtonScheme =
  | 'default'
  | 'success'
  | 'danger'
  | 'standard'
  | 'highlight'
  | 'cancel';
export const buttonScheme = ({
  theme,
  scheme,
  disabled,
}: {
  theme: TTheme;
  scheme: TButtonScheme;
  disabled?: boolean;
}): { background: string; foreground: string } => {
  if (disabled) {
    return { background: theme.disabled, foreground: theme.background };
  }

  switch (scheme) {
    case 'success':
      return { background: theme.success, foreground: theme.background };
    case 'danger':
      return { background: theme.danger, foreground: theme.background };
    case 'standard':
      return { background: 'transparent', foreground: theme.foreground };
    case 'highlight':
      return {
        background: transparentize(0.6, theme.borderPale),
        foreground: theme.foreground,
      };
    case 'cancel':
      return { background: 'transparent', foreground: theme.danger };
    case 'default':
    default:
      return { background: theme.action, foreground: theme.background };
  }
};

export const Border = styled.button<{
  scheme: TButtonScheme;
  outline?: boolean;
  square?: boolean;
  full?: boolean;
  centre?: boolean;
  ratio: number;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.full ? 'flex-start' : 'center')};
  padding: ${(props) => props.ratio}rem
    ${(props) => props.ratio * (props.square || props.full ? 1 : 3)}rem;
  margin: ${(props) => props.ratio}rem
    ${(props) => (props.full ? 0 : props.ratio)}rem;
  width: ${(props) => (props.full ? '100%' : 'auto')};

  background-color: ${(props) =>
    props.outline ? 'transparent' : buttonScheme(props).background};
  border-color: ${(props) => buttonScheme(props).background};
  border-radius: ${(props) => (props.full ? 0 : '100px')};
  border-style: solid;
  border-width: ${(props) => (props.outline ? 2 : 0)}px;
  color: ${(props) =>
    props.outline
      ? buttonScheme(props).background
      : buttonScheme(props).foreground};
  cursor: pointer;

  &:focus {
    animation: ${shadowFade} 0.4s 1 ease-in-out;
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Text = styled.span<{ size: number; spaced?: boolean }>`
  margin-left: ${(props) => (props.spaced ? 8 : 0)}px;

  font-size: ${(props) => props.size * 0.75}px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: ${(props) => props.size}px;
`;
