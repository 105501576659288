import { useCallback } from 'react';

import {
  AccountResult,
  useRefreshAccountMutation,
  useDeleteAccountMutation,
  accountDeleteProps,
} from '@jl/graphql-schema';
import { Label, Button, Display, ClickAndCopy } from '@jl/components';
import { Container, Text, Row, Instruction, Anchor } from '@jl/assets';
import {
  downloadCSV,
  imeiFromGaugeId,
  specialCharacters,
  tankFromGaugeId,
  useEvent,
} from '@jl/utils';

import { useHistory, Link, useConfig, useNotifications } from '../../hooks';

type ViewProps = { account: AccountResult };

const ColibriView = ({ account: { url } }: ViewProps) => (
  <Display label="URL" text={url} />
);

const JoyGaugeView = ({ account: { label, gauges, tanks } }: ViewProps) => (
  <Display
    label="Gauges"
    text={gauges.map((g) => g.name) || []}
    action={[
      {
        text: 'Download as CSV',
        onClick: () =>
          downloadCSV(
            gauges.map((g) => ({
              imei: imeiFromGaugeId(g.id),
              tankLabel: tanks.find((t) => t.id === tankFromGaugeId(g.id))
                ?.label,
              activatedAt: g.activatedAt || '',
              reportedAt: g.latest?.time || '',
              updatedAt: g.updatedAt || '',
            })),
            {
              cells: [
                'imei',
                'tankLabel',
                'activatedAt',
                'reportedAt',
                'updatedAt',
              ],
              headers: {
                imei: 'IMEI',
                tankLabel: 'Label',
                activatedAt: 'Activated At',
                reportedAt: 'Last Reported',
                updatedAt: 'Updated At',
              },
              file: `${label.replace(/ /g, '-').toLocaleLowerCase()}-gauges`,
            },
          ),
      },
    ]}
  />
);

const ProGaugeView = ({ account: { fairbanksUsers } }: ViewProps) => (
  <Display label="FTP Users" text={fairbanksUsers?.map((fb) => fb.id) || []} />
);

const RMSDeltaView = ({ account: { serialNumbers } }: ViewProps) => (
  <Display label="Serial Numbers" text={serialNumbers || []} />
);

const SmartFillView = ({ account: { reference, secret } }: ViewProps) => (
  <>
    <Display label="Reference" text={reference} />
    <Display label="Secret" text={secret} />
  </>
);

const viewByApi = {
  Colibri: ColibriView,
  JoyGauge: JoyGaugeView,
  ProGauge: ProGaugeView,
  RMSDelta: RMSDeltaView,
  SmartFill: SmartFillView,
};

const AccountView = ({ account }: ViewProps) => {
  useEvent({
    category: 'Account',
    action: 'view',
    label: account.id,
  });
  const { push } = useHistory();
  const [onRefresh] = useRefreshAccountMutation({
    variables: { id: account.id },
  });
  const [mutate] = useDeleteAccountMutation();
  const onDelete = useCallback(() => {
    mutate(accountDeleteProps(account));
    push(`/accounts`);
  }, ['tank']);
  const apiHost = useConfig('readme_api_host');
  const { addNotification } = useNotifications();

  const View = viewByApi[account.api];

  return (
    <Container small>
      <Display label="Notes" text={account.notes || specialCharacters.nbsp} />
      {account.apiAccess && (
        <Label text="API Access">
          <Text>
            Documentation:{' '}
            <Anchor target="_blank" href={apiHost}>
              {apiHost}
            </Anchor>
          </Text>
          <Text>
            Key:{' '}
            <ClickAndCopy
              text={account.apiAccess.value}
              onCopy={(text) =>
                addNotification({
                  id: text,
                  content: 'API Key Copied',
                  status: 'info',
                })
              }
            />{' '}
            {!account.apiAccess.enabled && '(disabled)'}
          </Text>
        </Label>
      )}
      <View account={account} />
      <Display
        label="Account Users"
        text={account.roles.map(
          ({ userId, type }) => `${userId.replace('jl:user:', '')} (${type})`,
        )}
      />
      <Label text="Tanks">
        {account.tanks.map(({ id, label }) => (
          <Text key={id}>
            <Link to={`/${id}`}>{label}</Link>
          </Text>
        ))}
      </Label>
      <Row align="center">
        <Button
          icon="sync"
          text="Refresh Account"
          outline
          onClick={() => onRefresh()}
        />
        <Instruction>
          Clicking this button will refresh the account, fetching the latest
          tanks &amp; levels from {account.api}.
        </Instruction>
      </Row>
      <Row align="center">
        <Button
          icon="minus"
          text="Remove Account"
          style="danger"
          outline
          onClick={onDelete}
        />
        <Instruction>
          Clicking this button will delete the account and all it&apos;s tanks
          from the system. Best be careful.
        </Instruction>
      </Row>
    </Container>
  );
};

export default AccountView;
