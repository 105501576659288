import { ChangeEvent, SelectHTMLAttributes, useMemo } from 'react';

import { isStringArray } from '@jl/utils';
import { Text } from '@jl/assets';

import { useField } from './use-form';
import { Label } from './label';
import { SelectOptions } from './select';
import { SSelect, OptionRow } from './select.styles';
import { Icon } from './icon';
import { Button } from './button';

export type MultiSelectProps = {
  name: string;
  label: string;
  placeholder: string;
  options: SelectOptions;
} & Pick<SelectHTMLAttributes<HTMLSelectElement>, 'disabled'>;

export const MultiSelect = ({
  name,
  label,
  placeholder,
  options,
}: MultiSelectProps) => {
  const { value, setValue } = useField<(string | number)[]>(name, {
    guard: isStringArray,
    default: [],
  });

  const onAdd = (e: ChangeEvent<HTMLSelectElement>) =>
    setValue([...(value || []), e.currentTarget.value]);
  const onRemove = (i: number) => {
    const newValue = value?.slice() || [];
    newValue.splice(i, 1);
    setValue(newValue);
  };
  return useMemo(() => {
    const available = options.filter((o) => value?.indexOf(o.value) === -1);
    return (
      <Label text={label} containsLabels>
        {(value || []).map((v, i) => (
          <OptionRow key={v}>
            <Text>{options.find((o) => o.value === v)?.label || v}</Text>
            <Button
              icon="minus"
              style="danger"
              size={12}
              onClick={() => onRemove(i)}
              outline
            />
          </OptionRow>
        ))}
        {!!available.length && (
          <>
            <SSelect name={name} value="" onChange={onAdd}>
              <option value="" disabled>
                {placeholder}
              </option>
              {available.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label || value}
                </option>
              ))}
            </SSelect>
            <Icon name="angleDown" />
          </>
        )}
      </Label>
    );
  }, [value, label, options]);
};
