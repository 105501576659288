import { useMemo } from 'react';

import { Navigation } from '@jl/components';
import { useAuth, useConfig, useHistory } from '../../hooks';
import { getAuthConfig, isAdmin, isSetupUser } from '@jl/utils';

const UserNavigation = () => {
  const [user] = useAuth();
  const version = useConfig('version');
  const { location, push } = useHistory();

  return useMemo(() => {
    const links = [];
    const buildLink = (text: string, pathPart: string, namePart?: string) => ({
      text,
      selected:
        location.pathname === `/${pathPart}` ||
        (!!namePart && location.pathname.startsWith(`/${namePart}`)),
      onClick: () =>
        location.pathname !== `/${pathPart}` && push(`/${pathPart}`),
    });

    if (isAdmin(user)) {
      links.push(
        buildLink('Accounts', 'accounts', 'account'),
        buildLink('Users', 'users'),
        buildLink('Status', 'status'),
      );
    }
    if (isSetupUser(user)) {
      links.splice(2, 0, buildLink('Gauges', 'gauges', 'gauge'));
    }

    return (
      <Navigation
        name={user.email}
        version={version || ''}
        home={() => location.pathname !== '/' && push('/')}
        links={links}
        buttons={[
          {
            name: 'accountEdit',
            icon: 'userEdit',
            text: 'Edit Account',
            onClick: () => {
              location.pathname !== '/me' && push('/me');
            },
          },
          {
            name: 'termsOfService',
            icon: 'book',
            text: 'Terms Of Service',
            onClick: () => {
              location.pathname !== '/documents/terms-of-service' &&
                push('/documents/terms-of-service');
            },
          },
          {
            name: 'signOut',
            icon: 'signOut',
            text: 'Sign Out of JoyLevel',
            onClick: async () => {
              localStorage.clear();
              window.location.href = (await getAuthConfig()).logoutUrl;
            },
          },
        ]}
      />
    );
  }, [user.email, user.roles, location.pathname, version]);
};

export default UserNavigation;
