import { ResponsiveLine } from '@nivo/line';

import { useTheme, Hint } from '@jl/assets';

import { TooltipBox } from './tank-history.styles';

export interface StrappingChartProps {
  current?: { mm: number; litres: number };
  data: { mm: number; litres: number }[];
  showEmpty?: boolean;
}

export const StrappingChart = ({
  current,
  data,
  showEmpty,
}: StrappingChartProps) => {
  const theme = useTheme();
  if (!data.length && !showEmpty) return null;

  return (
    <div style={{ width: '100%', height: 200 }}>
      <ResponsiveLine
        margin={{ top: 20, right: 20, bottom: 40, left: 50 }}
        data={
          data.length
            ? [
                {
                  id: 'strapping',
                  data: data.map(({ mm, litres }) => ({
                    x: mm || 0,
                    y: litres || 0,
                  })),
                },
              ]
            : []
        }
        colors={[theme.success]}
        theme={{
          grid: { line: { stroke: theme.foreground, strokeWidth: 0.5 } },
          axis: {
            ticks: {
              line: { stroke: theme.foreground, strokeWidth: 0.5 },
              text: { fill: theme.foreground },
            },
          },
          crosshair: { line: { stroke: theme.foreground } },
        }}
        enableArea={true}
        axisLeft={{
          format: (value) => `${value}L`,
          tickValues: 4,
          tickRotation: -30,
        }}
        axisBottom={{
          format: (value) => `${value}mm`,
          tickRotation: -30,
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          clamp: true,
        }}
        xScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          clamp: true,
        }}
        curve="monotoneX"
        enableSlices="x"
        sliceTooltip={(item) => {
          const data = item.slice.points[0].data;
          return (
            <TooltipBox>
              <Hint>{`${data.x} mm - ${data.y} L`}</Hint>
            </TooltipBox>
          );
        }}
        markers={
          current && [
            {
              axis: 'y',
              value: current.litres,
              lineStyle: {
                stroke: theme.action,
                strokeWidth: 2,
              },
              legend: `${current.mm.toFixed(2)}mm - ${current.litres}L`,
              textStyle: {
                fontSize: 12,
                fontWeight: 700,
                fontStyle: 'italic',
                fill: theme.foreground,
              },
            },
          ]
        }
      />
    </div>
  );
};
