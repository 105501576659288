import { Row, Subtitle, Stretch, Hint } from '@jl/assets';
import { formatTime, specialCharacters } from '@jl/utils';

import { Info, Logo } from './account-info.styles';
import { Icon } from './icon';

export interface UserInfoProps {
  email: string;
  email_verified: boolean;
  picture?: string | null;
  app_metadata?: {
    login_count?: number | null;
    last_login?: string | null;
  } | null;
}

export const UserInfo = ({
  email,
  email_verified,
  picture,
  app_metadata,
}: UserInfoProps) => {
  return (
    <Info key={email} type="button">
      <Row align="center">
        <Logo src={picture || ''} />
        <Subtitle>
          <strong>{email}</strong>
          &nbsp;
        </Subtitle>
        <Icon name={email_verified ? 'checkCircle' : 'circle'} />
        <Stretch />
      </Row>
      <Hint>
        <strong>Last Login:</strong>{' '}
        {app_metadata && app_metadata.last_login
          ? formatTime(app_metadata.last_login)
          : specialCharacters.enDash}
      </Hint>
      <Hint>
        <strong>Login Count:</strong>{' '}
        {(app_metadata && app_metadata.login_count) || 0}
      </Hint>
    </Info>
  );
};
