import { z } from 'zod';
import { toGuard } from './guards';

export const addressSchema = z.object({
  display: z.string(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});

export type AddressValue = z.infer<typeof addressSchema>;

export const isAddressValue = toGuard(addressSchema);

export const isVisibleAddress = (address: AddressValue | undefined) =>
  !!address &&
  typeof address.latitude === 'number' &&
  typeof address.longitude === 'number' &&
  (address.latitude !== 0 || address.longitude !== 0);
