import { styled } from './theme';

export const Title = styled.h3`
  font-size: 3rem;
  font-weight: bold;
  margin: 0.6rem 0;
`;

export const Subtitle = styled.h4`
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0.3rem 0;
  cursor: ${(props) => (!!props.onClick ? 'pointer' : 'auto')};
`;

export const Hint = styled.p`
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.4;
  margin: 0;
`;

export const Text = styled.p<{ preformatted?: boolean }>`
  padding: 3px 2px 5px;
  margin: 0;

  font-size: 1.6rem;
  line-height: 1.4;
  white-space: ${(props) => (props.preformatted ? 'pre-wrap' : 'normal')};
`;

export const Anchor = styled.a``;

export const Instruction = styled.p`
  padding: 3px 2px 5px;
  margin: 0;

  font-size: 1.6rem;
  font-style: italic;
  line-height: 1.4;
  max-width: 470px;
`;

export const Success = styled.span`
  display: block;
  margin: 1rem 0;
  color: ${(props) => props.theme.success};
`;

export const Warning = styled.span`
  display: block;
  margin: 1rem 0;
  text-align: center;
  color: ${(props) => props.theme.warning};
`;

export const Failure = styled.span`
  display: block;
  margin: 1rem 0;
  text-align: center;
  color: ${(props) => props.theme.danger};
`;

export const Disabled = styled.span`
  display: block;
  margin: 1rem 0;
  text-align: center;
  color: ${(props) => props.theme.disabled};
`;

export const Emphasize = styled.span<{ strong?: boolean; italic?: boolean }>`
  font-weight: ${(props) => (props.strong ? '600' : 'inherit')};
  font-style: ${(props) => (props.italic ? 'italic' : 'inherit')};
`;
