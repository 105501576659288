import { HttpLink } from '@apollo/client/link/http';

import { getAuthToken, checkAuth } from '@jl/utils';

export const httpLink = new HttpLink({
  uri: '/graphql',
  fetch: async (uri, defaults) => {
    const options = {
      ...defaults,
      headers: {
        ...(defaults && defaults.headers),
        authorization: getAuthToken() || '',
      },
    };
    let result = await fetch(uri, options);
    if (result.status === 401) {
      await checkAuth();
      options.headers.authorization = getAuthToken() || '';
      result = await fetch(uri, options);
    }

    return result;
  },
});
