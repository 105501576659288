import { useEffect } from 'react';

export const useTitle = (parts: string[]) => {
  const title = [...parts, 'JoyLevel'].join(' | ');
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
};
