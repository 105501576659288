import { specialCharacters } from './special-characters';

export type ScheduleOptions = {
  timeZone?: string | null;
  startTime: number[];
  endTime?: number | null;
  frequency: number;
  every?: 'Week' | 'Fortnight' | 'Month' | null;
  days: {
    Sunday: boolean;
    Saturday: boolean;
    Friday: boolean;
    Thursday: boolean;
    Wednesday: boolean;
    Tuesday: boolean;
    Monday: boolean;
  };
};

export const dayKeys = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export const displayDays = (days: ScheduleOptions['days']) => {
  const selected = dayKeys.map((day) => days[day]);

  // If every day
  if (selected.every((day) => day)) {
    return 'Daily';
  }

  // If week days
  if (selected.slice(0, 5).every((day) => day)) {
    return 'Weekdays';
  }

  // If weekend days
  if (selected.slice(5).every((day) => day)) {
    return 'Weekends';
  }

  // If run of days
  const first = selected.indexOf(true);
  const last = selected.lastIndexOf(true);
  if (selected.slice(first, last + 1).every((day) => day) && last - first > 1) {
    return `${dayKeys[first]} - ${dayKeys[last]}`;
  }

  // Otherwise just show the days
  return dayKeys.filter((day) => days[day]).join(', ');
};

export const displaySchedule = (
  options: ScheduleOptions | null | undefined,
) => {
  if (!options) return undefined;

  const {
    timeZone,
    days,
    frequency,
    every,
    endTime,
    startTime: [hour, minute],
  } = options;
  const startTime = `${hour}:${minute.toString().padStart(2, '0')}`;
  return [
    [
      `Every ${frequency} hours`,
      `${frequency === 24 ? 'at' : 'from'} ${startTime}`,
      frequency !== 24 && !!endTime && `to ${endTime}:00`,
      `(${timeZone || 'UTC'})`,
    ]
      .filter(Boolean)
      .join(' '),
    `Every ${every} (${displayDays(days)})`,
  ].join(' | ');
};

export type StaticAlarmOptions = {
  enabled: boolean;
  polarity?: 'higher' | 'lower' | null;
  threshold?: number | null;
  error?: number | null;
};

export const displayStaticAlarm = (
  options: StaticAlarmOptions | null | undefined,
) => {
  if (!options) return undefined;

  const { enabled, polarity, threshold, error } = options;
  if (!enabled) return 'Disabled';

  return `Ullage ${polarity === 'higher' ? 'above' : 'below'} ${threshold}${
    specialCharacters.plusMinus
  }${error}cm`;
};

export type LoggerOptions = {
  frequency: number;
  sampling: number;
};

export const displayLogger = (options: LoggerOptions | null | undefined) => {
  if (!options) return undefined;

  const { frequency, sampling } = options;
  return `Sampling every ${
    sampling || specialCharacters.enDash
  } mins, Logging every ${frequency || specialCharacters.enDash} hours`;
};

export const displayGSMListen = (value: number | null | undefined) => {
  if (!value) return undefined;

  return `Listening for ${value} mins`;
};

export const displayDelay = (value: number | null | undefined) => {
  if (!value) return undefined;

  return `Delay for ${value} mins`;
};

export type ControlOptions = {
  network?: string | null;
  bundAlarm?: boolean | null;
  crcCheck?: boolean | null;
  verboseLogging?: boolean | null;
};

export const displayControl = (options: ControlOptions | null | undefined) => {
  if (!options) return undefined;

  const { network, bundAlarm, crcCheck, verboseLogging } = options;
  const flags = [
    bundAlarm && 'Bund Alarm',
    crcCheck && 'CRC Check',
    verboseLogging && 'Verbose Logging',
  ]
    .filter(Boolean)
    .join(', ');
  return `Network: ${network || specialCharacters.enDash}, Enabled Flags: ${
    flags || specialCharacters.enDash
  }`;
};

export type DynamicAlarmOptions = {
  enabled: boolean;
  polarity?: 'rising' | 'falling' | null;
  rate?: number | null;
};

export const displayDynamicAlarm = (
  options: DynamicAlarmOptions | null | undefined,
  logger: LoggerOptions | null | undefined,
) => {
  if (!options) return undefined;

  const { enabled, polarity, rate } = options;
  if (!enabled) return 'Disabled';

  return `Ullage ${polarity === 'rising' ? 'rose' : 'fell'} > ${rate}cm/${
    logger?.sampling
  }mins`;
};

export type APNOptions = {
  APN?: string | null;
  APNUsername?: string | null;
  APNPassword?: string | null;
};

export const displayAPN = (options: APNOptions | null | undefined) => {
  if (!options) return undefined;

  const { APN, APNUsername, APNPassword } = options;
  return (
    [[APNUsername, APNPassword].filter(Boolean).join(':'), APN]
      .filter(Boolean)
      .join('@') || undefined
  );
};

export type ServerOptions = {
  ServerURL?: string | null;
  ServerPort?: string | null;
};

export const displayServer = (options: ServerOptions | null | undefined) => {
  if (!options) return undefined;

  const { ServerURL, ServerPort } = options;
  return [ServerURL, ServerPort].filter(Boolean).join(':') || undefined;
};

export type TryOptions = {
  attempts: number;
  period: number;
};

export const displayTry = (options: TryOptions | null | undefined) => {
  if (!options) return undefined;

  const { attempts, period } = options;
  return `Try ${attempts} times every ${period} mins`;
};

export type SonicQualityOptions = {
  rssi: number;
  src: number;
};

export const displaySonicQuality = (
  options: SonicQualityOptions | null | undefined,
) => {
  if (!options) return undefined;

  const { rssi, src } = options;
  return `RSSI: ${rssi}, SRC: ${src}`;
};
