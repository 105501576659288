import { useState } from 'react';

import { Row, Instruction, Container, Success } from '@jl/assets';
import { Header, Button } from '@jl/components';
import { requestPasswordChange, useTitle } from '@jl/utils';

import { useAuth, useHistory } from '../../hooks';

const Me = () => {
  const [message, setMessage] = useState<string>();
  const [user] = useAuth();
  const { goBack } = useHistory();
  useTitle(['Your Account']);

  return (
    <>
      <Header
        title={user.email}
        subtitle="Your JoyLevel Account"
        leftButton={{
          icon: 'arrowLeft',
          style: 'standard',
          onClick: goBack,
        }}
      />
      <Container small>
        <Row align="center">
          <Button
            icon="key"
            text="Reset Password"
            style="success"
            outline
            onClick={() => requestPasswordChange(user.email).then(setMessage)}
          />
          <Instruction>
            Click the button to reset your password. We&apos;ll send you an
            email, and you can set a new password from there.
            {message && <Success>{message}</Success>}
          </Instruction>
        </Row>
      </Container>
    </>
  );
};

export default Me;
