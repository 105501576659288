import {
  Subtitle,
  Hint,
  apiLogo,
  percentColour,
  useTheme,
  Emphasize,
} from '@jl/assets';
import {
  specialCharacters,
  useMemoizedCalculations,
  useShortTime,
} from '@jl/utils';

import {
  Wall,
  Bottom,
  Logo,
  IconList,
  AirGap,
  Top,
} from './tank-summary.styles';
import { Icon } from './icon';
import { TankFill } from './tank-summary';

export interface ObservationProps {
  time: string;
  pressure?: number | null;
  distance?: number | null;
  sleep?: number | null;
  temperature?: number | null;
  voltage?: number | null;
  signal?: number | null;
}

export interface GaugeSetupProps {
  probe_sg: number;
  actual_sg: number;
  offset_mm: number;
  offset_litres: number;
  strapping: { mm: number; litres: number }[];
}

export interface GaugeSummaryProps {
  id: string;
  connection: string;
  name: string;
  notes?: string | null;
  range?: string | null;
  type?:
    | 'ADC'
    | 'Temperature'
    | 'Pressure'
    | 'TemperatureAndPressure'
    | 'Ultrasonic'
    | null;
  latest?: ObservationProps | null;
  setup?: GaugeSetupProps | null;
  onSelect?: (id: string) => void;
}

export const GaugeSummary = ({
  id,
  name,
  connection,
  type,
  latest,
  setup,
  onSelect,
}: GaugeSummaryProps) => {
  const theme = useTheme();
  const percent =
    latest && setup
      ? useMemoizedCalculations(latest, type)(setup).percentNum
      : undefined;
  const colour = percentColour(theme, percent || null);

  return (
    <Wall
      type="button"
      disabled={!latest}
      onClick={() => onSelect && onSelect(id)}
    >
      <AirGap />
      {percent && <TankFill percent={percent} colour={colour} />}
      <Subtitle>
        <Emphasize strong>{name}</Emphasize>
      </Subtitle>
      {latest && (
        <IconList>
          {!!latest.pressure && (
            <li>
              <Icon name="ruler" size={20} />
              <span>{latest.pressure}mm</span>
            </li>
          )}
          {!!latest.distance && (
            <li>
              <Icon name="ruler" size={20} />
              <span>{latest.distance}mm</span>
            </li>
          )}
          {!!latest.temperature && (
            <li>
              <Icon name="thermometer" size={20} />
              <span>
                {latest.temperature}
                {specialCharacters.degree}C
              </span>
            </li>
          )}
          {!!latest.voltage && (
            <li>
              <Icon name="carBattery" size={20} />
              <span>{latest.voltage}%</span>
            </li>
          )}
        </IconList>
      )}

      <Top>
        <Hint>{type}</Hint>
        <Hint>
          <strong>{connection}</strong>
        </Hint>
      </Top>
      <Bottom>
        <Hint>
          <Icon name="history" size={11} /> {useShortTime(latest?.time)}
        </Hint>
        <Logo src={apiLogo('JoyGauge')} />
      </Bottom>
    </Wall>
  );
};
