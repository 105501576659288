import { accountHasRole, TankResult, useGaugeQuery } from '@jl/graphql-schema';
import { gaugeFromTankId, isSetupUser, linkify } from '@jl/utils';
import { Label } from '@jl/components';
import { Text } from '@jl/assets';

import { useAuth, Link } from '../../hooks';

export const GaugeField = ({ tank }: { tank: TankResult }) => {
  const [user] = useAuth();
  if (
    tank.account.api !== 'JoyGauge' ||
    (!isSetupUser(user) &&
      !accountHasRole(tank.account, {
        userId: user.userId,
        roleType: 'Installer',
      }))
  ) {
    return null;
  }

  const gaugeId = gaugeFromTankId(tank.id);
  const { data } = useGaugeQuery({ variables: { id: gaugeId } });

  return (
    <Label text="Gauge">
      <Text>
        {data?.gauge ? (
          <Link to={linkify(data.gauge.id)}>{data.gauge.name}</Link>
        ) : (
          'Gauge not Found'
        )}
      </Text>
    </Label>
  );
};
