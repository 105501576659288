import { useCallback, useMemo } from 'react';

import { Row, Title } from '@jl/assets';
import { useValues, Count, UserInfo, Button } from '@jl/components';
import { useUsersQuery } from '@jl/graphql-schema';
import { sortAndFilter, mapOrEmpty } from '@jl/utils';

import { UserFilters, sorters } from './filters';

export const UserList = () => {
  const { data, fetchMore } = useUsersQuery({
    variables: {
      page: 0,
    },
  });
  const users = (data && data.users) || [];

  const useFetchMore = useCallback(
    () =>
      fetchMore({
        variables: {
          page: Math.floor(users.length / 100) + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) =>
          !fetchMoreResult
            ? prev
            : { ...prev, users: [...prev.users, ...fetchMoreResult.users] },
      }),
    [users.length],
  );

  const { search, sort, order } = useValues<UserFilters>();

  return useMemo(() => {
    const filtered = sortAndFilter(users, {
      search,
      sort: sorters[sort],
      order,
      keys: ['email'],
    });

    return (
      <Row>
        <Count filtered={filtered.length} total={users.length} label="Users" />
        {mapOrEmpty(
          filtered,
          UserInfo,
          <Row>
            <Title>No Users Found.</Title>
          </Row>,
        )}
        {users.length % 100 === 0 && (
          <Button
            icon="angleDown"
            text="Load More Users"
            onClick={useFetchMore}
            outline
          />
        )}
      </Row>
    );
  }, [users, search, sort, order]);
};
