import { useEffect } from 'react';
import * as ga from 'react-ga';
import { loadConfig } from './load-config';

let gaPromise: Promise<void>;
const loadAnalytics = async () =>
  ga.initialize((await loadConfig('analytics_id')) || '', {
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });

export const setupAnalytics = async () => {
  if (!gaPromise) {
    gaPromise = loadAnalytics();
  }
  await gaPromise;
};

export const setUser = (userId: string) =>
  ga.set({ userId, dimension1: userId });

export type EventArgs = {
  category:
    | 'Accounts'
    | 'Account'
    | 'Tanks'
    | 'Tank'
    | 'Feedback'
    | 'Token'
    | 'Users'
    | 'Gauges'
    | 'Gauge';
  action: 'view' | 'edit' | 'new' | 'submit' | 'load';
  label?: string;
  value?: number;
};
export const useEvent = (event: EventArgs) =>
  useEffect(() => {
    ga.event(event);
  });
export const sendEvent = (event: EventArgs) => ga.event(event);

export const sendView = (path: string) => ga.pageview(path);
