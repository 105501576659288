import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { useMemo } from 'react';

import {
  Row,
  Container,
  Instruction,
  Column,
  Emphasize,
  Rule,
  Stretch,
  themes,
} from '@jl/assets';
import {
  Header,
  Select,
  Form,
  Switch,
  Avatar,
  useValues,
} from '@jl/components';
import {
  templateKeys,
  buildTemplate,
  EmailBaseProps,
  TemplateKey,
  TemplateProps,
} from '@jl/emails/src/templates';
import { specialCharacters, useTitle } from '@jl/utils';

const Preview = styled.div`
  box-shadow: 2px 2px 3px 0
    ${(props) => transparentize(0.8, props.theme.border)};
  border-radius: 3px;

  display: block;
  padding: 1rem;
`;

const HtmlBody = styled.iframe`
  width: 100%;
  height: 50vh;

  border: 0;
  margin: 0;
  padding: 1rem;
`;

const TextBody = styled.pre`
  font-family: monospace;
  font-size: 1.5rem;
  min-height: 10rem;

  background: ${(props) => props.theme.disabledPale};
  border-radius: 3px;
  margin: 0;
  padding: 1rem;
`;

const templateOptions = templateKeys.map((value) => ({ value }));

const baseProps: EmailBaseProps = {
  address: '42 St George Crescent, Sandy Point, NSW',
  host: 'joylevel.io',
  support: 'joylevel@joylevel.io',
};

const propsByKey: { [k in TemplateKey]: TemplateProps<k> } = {
  Basic: {
    text: `This is a basic email.`,
    link: { href: 'joylevel.io', text: 'with a link to joylevel' },
  },
  TankWarning: {
    tanks: [
      {
        id: 'jl:tank:4CE2FF',
        accountId: 'jl:account:a44dbe71-1231-4bcc-ae3c-bb65ff1ba1b4',
        name: 'Montrose TAS',
        level: '22%',
        colour: themes.light.warning,
      },
    ],
  },
};

const buildTemplateForKey = (template: TemplateKey) => {
  return buildTemplate(template, baseProps, propsByKey[template]);
};

const EmailDisplay = () => {
  const { template, html } = useValues<{
    template: TemplateKey | undefined;
    html: boolean;
  }>();
  const email = useMemo(
    () => template && buildTemplateForKey(template),
    [template],
  );

  return (
    <Container small>
      <Preview>
        <Row align="center">
          <Column>
            <Instruction>
              <Emphasize strong>From: </Emphasize>notifications@joylevel.io
            </Instruction>
            <Instruction>
              <Emphasize strong>Subject: </Emphasize>
              {email?.subject || specialCharacters.enDash}
            </Instruction>
          </Column>
          <Stretch />
          <Avatar text="Joylevel Notifications" />
        </Row>
        <Rule />
        {html ? (
          <HtmlBody srcDoc={email?.html} />
        ) : (
          <TextBody>{email?.text}</TextBody>
        )}
      </Preview>
    </Container>
  );
};

const Emails = () => {
  useTitle(['Email Templates', 'Development']);

  return (
    <Form
      initialValues={{
        template: undefined as TemplateKey | undefined,
        html: true,
      }}
    >
      <Header title="Email Templates" subtitle="Development" />
      <Container small>
        <Row align="center">
          <Select
            name="template"
            label="Template Type"
            placeholder="Select a Template"
            options={templateOptions}
          />
          <Switch name="html" label="Show HTML Output" size="compact" />
        </Row>
      </Container>
      <EmailDisplay />
    </Form>
  );
};

export default Emails;
