import { transparentize, position } from 'polished';

import { styled, spin } from '@jl/assets';

export const Box = styled.div<{
  fullScreen?: boolean;
  padded?: boolean;
  small?: boolean;
}>`
  display: block;
  height: ${(props) => (props.small ? 40 : 120)}px;
  width: ${(props) => (props.small ? 40 : 120)}px;

  ${(props) =>
    props.fullScreen
      ? position('absolute', '50%', '50%')
      : position('relative')}
  transform: ${(props) =>
    props.fullScreen ? 'translate(-50%, -50%)' : 'initial'};

  margin: ${(props) =>
    props.padded ? `${props.small ? 10 : 30}px auto` : '0'};
`;

export const Circle = styled.div`
  display: block;
  ${position('absolute', 0, 0, 0, 0)}

  border-radius: 50%;
  box-shadow: inset 0 0 0 1px
    ${(props) => transparentize(0.8, props.theme.success)};
`;

export const Mask = styled.div`
  display: block;
  ${position('absolute', 0, 0, 0, 0)}

  mask-image: -webkit-linear-gradient(top, rgba(0,0,0,1), rgba(0,0,0,0));
  animation: ${spin} 1.2s infinite linear;
`;

export const Line = styled.div`
  border: 0;
  ${position('absolute', 0, 0, 0, 0)}

  border-radius: 50%;
  box-shadow: inset 0 0 0 1px
    ${(props) => transparentize(0.5, props.theme.success)};
`;
