import { transparentize } from 'polished';

import { styled, Hint } from '@jl/assets';

export const Gauge = styled.button`
  background: transparent;
  border: none;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  margin: ${(props) => (props.onClick ? `0 5px` : 0)};
  padding: ${(props) => (props.onClick ? `25px 5px 5px` : 0)};
  border-bottom: ${(props) =>
    props.onClick
      ? `1px solid ${transparentize(0.8, props.theme.foreground)}`
      : 'none'};
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};

  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const Level = styled.div<{ percent: number; colour: string }>`
  display: block;
  overflow: hidden;

  position: relative;
  height: 12px;
  width: 100%;

  background-color: ${(props) => props.theme.disabledPale};
  border-radius: 0 4px 0 4px;

  &::before {
    content: '';
    display: block;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: ${(props) => props.percent}%;
    background-color: ${(props) => props.colour};
  }
`;

export const Markers = styled.div`
  display: block;

  position: relative;
  height: 0;
  width: 100%;
`;

export const Marker = styled.div<{ percent: number }>`
  display: block;
  position: absolute;

  left: 0;
  bottom: 0;
  height: 12px;

  width: ${(props) => props.percent}%;
  border-right: 1px dashed ${(props) => props.theme.border};
  opacity: 0.8;

  span {
    background: ${(props) => props.theme.background};
    position: absolute;
    display: block;

    right: 0;
    top: 100%;
    transform: translateX(50%);
    padding: 3px;
    font-size: 1.2rem;
    font-weight: normal;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  padding: 6px 0;

  strong,
  span {
    padding: 0 3px;
  }
`;

export const LeftLabel = styled(Hint)<{ percent: number; colour: string }>`
  text-align: right;

  position: relative;
  width: ${(props) => props.percent}%;

  &::after {
    content: '';
    display: block;

    position: absolute;
    right: -5px;

    border: 6px solid transparent;
    border-top-width: 7px;
    border-top-color: ${(props) => props.colour};
  }
`;

export const RightLabel = styled(Hint)`
  text-align: left;

  flex: 1 1 0;
`;
