import { isArray } from './guards';

export function flatten<T>(array: (T | T[])[], depth = 1) {
  const flattened: T[] = [];
  (function flat(array, depth) {
    for (const el of array) {
      if (isArray(el) && depth > 0) {
        flat(el, depth - 1);
      } else {
        flattened.push(el as T);
      }
    }
  })(array, depth);
  return flattened;
}
