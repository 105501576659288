import { Subtitle, Hint, Row, Stretch } from '@jl/assets';
import {
  formatTime,
  isNumber,
  specialCharacters,
  useMemoizedCalculations,
} from '@jl/utils';

import { Gauge } from './tank-gauge.styles';
import { GaugeSummaryProps } from './gauge-summary';
import { InfoList } from './info-list';

export const GaugeInfo = ({
  id,
  name,
  connection,
  type,
  latest,
  setup,
  onSelect,
}: GaugeSummaryProps) => {
  const calculated =
    latest && setup ? useMemoizedCalculations(latest, type)(setup) : undefined;

  return (
    <Gauge onClick={() => onSelect && onSelect(id)} type="button">
      <Row align="center">
        <Subtitle>
          <InfoList
            items={[
              { text: name, strong: true },
              { text: type },
              { text: connection },
            ]}
          />
        </Subtitle>
        <Stretch />
        <Hint>
          <InfoList
            items={[
              {
                icon: 'ruler',
                text: latest?.pressure,
                unit: 'mm',
                hidden: !latest?.pressure,
              },
              {
                icon: 'ruler',
                text: latest?.distance,
                unit: 'mm',
                hidden: !latest?.distance,
              },
              {
                icon: 'ruler',
                text: calculated?.percentNum,
                unit: '%',
                hidden: !calculated?.percentNum,
                status: (value) => {
                  if (!isNumber(value)) return undefined;
                  if (value <= -10 || value >= 110) return 'warning';

                  return undefined;
                },
              },
              {
                icon: 'thermometer',
                text: latest?.temperature,
                unit: `${specialCharacters.degree}C`,
                hidden: !latest?.temperature,
                status: (value) => {
                  if (!isNumber(value)) return undefined;
                  if (value > 60) return 'danger';
                  if (value > 40) return 'warning';

                  return undefined;
                },
              },
              {
                icon: 'signal',
                text: latest?.signal && ((latest.signal / 31) * 100).toFixed(),
                unit: '%',
                hidden: !latest?.signal,
                status: (value) => {
                  if (!isNumber(value)) return undefined;
                  if (value < 20) return 'danger';
                  if (value < 60) return 'warning';

                  return undefined;
                },
              },
              {
                icon: 'carBattery',
                text: latest?.voltage,
                unit: '%',
                hidden: !latest?.voltage,
                status: (value) => {
                  if (!isNumber(value)) return undefined;
                  if (value < 50) return 'danger';
                  if (value < 75) return 'warning';

                  return undefined;
                },
              },
              {
                icon: 'history',
                text: latest && formatTime(latest.time),
              },
            ]}
          />
        </Hint>
      </Row>
    </Gauge>
  );
};
