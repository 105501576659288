import { ReactNode } from 'react';

import { Row, Rule, Stretch, Subtitle } from '@jl/assets';

import { Button } from './button';
import { AbsoluteBackground, Content, FixedBackground } from './modal.styles';

export type ModalProps = {
  internal?: boolean;
  small?: boolean;
  title?: string;
  heading?: ReactNode;
  children?: ReactNode;
  hideClose?: boolean;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

const Background = ({
  internal,
  children,
  setVisible,
}: Pick<ModalProps, 'internal' | 'children' | 'setVisible'>) => {
  const Selected = internal ? AbsoluteBackground : FixedBackground;

  return (
    <Selected
      onClick={(e) => e.target === e.currentTarget && setVisible(false)}
    >
      {children}
    </Selected>
  );
};

export const Modal = ({
  internal,
  small,
  title,
  heading,
  children,
  hideClose,
  visible,
  setVisible,
}: ModalProps) =>
  visible ? (
    <Background internal={internal} setVisible={setVisible}>
      <Content width={small ? 420 : 600}>
        <Row align="center">
          {heading}
          <Subtitle>{title}</Subtitle>
          <Stretch />
          {!hideClose && (
            <Button
              type="button"
              icon="times"
              style="standard"
              size={12}
              onClick={() => setVisible(false)}
            />
          )}
        </Row>
        <Rule />
        {children}
      </Content>
    </Background>
  ) : null;
