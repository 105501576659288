import { getAuthToken } from './auth-token';
import { getAuthConfig } from './build-authorize-url';

const ns = <K extends string>(name: K) =>
  `https://joylevel.io/${name}` as const;

export const userInfoCache: Record<string, UserInfo> = {};
export type UserInfo = {
  userId: string;
  email: string;
  email_verified: boolean;
  sub: string;
  roles: string[];
  terms: {
    version?: string;
    updated?: string;
    accepted?: string;
  };
};

type UserInfoInput = {
  email: string;
  email_verified: boolean;
  sub: string;
  'https://joylevel.io/roles': string[];
  'https://joylevel.io/terms_version': string;
  'https://joylevel.io/terms_updated': string;
  'https://joylevel.io/terms_accepted': string;
};

export const buildUserInfo = (e: UserInfoInput): UserInfo => ({
  email: e.email,
  email_verified: e.email_verified,
  sub: e.sub,
  userId: `jl:user:${e.email}`,
  roles: e[ns('roles')] || [],
  terms: {
    version: e[ns('terms_version')],
    updated: e[ns('terms_updated')],
    accepted: e[ns('terms_accepted')],
  },
});
export const isUserInfo = (e: unknown): e is UserInfo =>
  !!e &&
  typeof (e as UserInfo).email === 'string' &&
  typeof (e as UserInfo).email_verified === 'boolean' &&
  typeof (e as UserInfo).sub === 'string';

export const fetchUserInfo = async (force?: boolean) => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('Token not set');
  }
  if (!force && userInfoCache[token]) {
    return userInfoCache[token];
  }

  const { userInfoUrl } = await getAuthConfig();
  const result = await fetch(userInfoUrl, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${getAuthToken()}`,
    },
  }).then((r) => r.json());

  userInfoCache[token] = buildUserInfo(result);
  return userInfoCache[token];
};
