import { ReactNode } from 'react';

import { Row } from '@jl/assets';

import { Box, BoxLabel, Text, Action, Rule, BoxSize } from './label.styles';

export interface LabelProps {
  text: string;
  action?: {
    disabled?: boolean;
    title?: string;
    text: string;
    onClick: () => void;
  }[];
  inline?: boolean;
  containsLabels?: boolean;
  size?: BoxSize;
  children: ReactNode;
}

export const Label = ({
  text,
  action,
  inline,
  containsLabels,
  size,
  children,
}: LabelProps) => {
  const bits = (
    <>
      <Text>
        {text}{' '}
        {action?.map((a) => (
          <Action
            key={a.text}
            title={a.title}
            aria-disabled={a.disabled}
            onClick={a.disabled ? undefined : () => a.onClick()}
          >
            {a.text}
          </Action>
        ))}
      </Text>
      {inline ? <Row>{children}</Row> : children}
      <Rule />
    </>
  );

  return containsLabels ? (
    <Box size={size}>{bits}</Box>
  ) : (
    <BoxLabel size={size}>{bits}</BoxLabel>
  );
};
