import { transparentize } from 'polished';

import { styled, Row, IconName, TTheme } from '@jl/assets';

export type TWarningEventStatus =
  | 'QUEUED'
  | 'SENT'
  | 'DELIVERED'
  | 'OPENED'
  | 'CLICKED'
  | 'FAILED'
  | 'DELAYED';

export const statusIcon: Record<TWarningEventStatus, IconName> = {
  QUEUED: 'ellipsisH',
  SENT: 'planeDeparture',
  DELIVERED: 'truckLoading',
  OPENED: 'envelopeOpen',
  CLICKED: 'mouse',
  FAILED: 'exclamation',
  DELAYED: 'ellipsisH',
};

export const statusColour: Record<TWarningEventStatus, keyof TTheme> = {
  QUEUED: 'border',
  SENT: 'action',
  DELIVERED: 'action',
  OPENED: 'success',
  CLICKED: 'success',
  FAILED: 'danger',
  DELAYED: 'warning',
};

export const Warning = styled(Row)`
  align-items: flex-start;
  padding: 4px 12px 4px 8px;

  &:nth-of-type(even) {
    background-color: ${(props) => transparentize(0.9, props.theme.borderPale)};
  }
`;

export const Events = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row-reverse;
`;

export const Event = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 0 3px;
  max-width: 23px;
  transition: max-width 0.2s ease;

  p {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    max-width: 100px;
    p {
      opacity: 1;
      transition-duration: 0.1s;
    }
  }
`;

export const EventIcon = styled.div<{ status: TWarningEventStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;

  background-color: ${(props) => props.theme[statusColour[props.status]]};
  border: 1px solid ${(props) => props.theme.background};
  border-radius: 3rem;
`;

export const EventLabel = styled.p`
  font-size: 1rem;
  margin: 1px 0;
  white-space: nowrap;
  text-align: center;
`;
