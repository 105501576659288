import { Container, Text } from '@jl/assets';
import { CommandInfo, DeviceInfo, Label } from '@jl/components';
import { CommandsQuery, useCommandsQuery } from '@jl/graphql-schema';
import {
  displayAPN,
  displayControl,
  displayDelay,
  displayDynamicAlarm,
  displayGSMListen,
  displayLogger,
  displaySchedule,
  displayServer,
  displaySonicQuality,
  displayStaticAlarm,
  displayTry,
  mapOrEmpty,
  specialCharacters,
} from '@jl/utils';

const settingValuesToStrings = (
  input: CommandsQuery['commands']['setting'],
): Record<string, string | undefined> | undefined => {
  if (!input) return undefined;

  const v = input.values;

  return {
    Logger: displayLogger(v.Logger),
    GSMListen: displayGSMListen(v.GSMListen),
    Schedule: displaySchedule(
      v.Schedule ? { ...v.Schedule, timeZone: input.timeZone } : undefined,
    ),
    Control: displayControl(v.Control),
    StaticAlarm1: displayStaticAlarm(v.StaticAlarm1),
    StaticAlarm2: displayStaticAlarm(v.StaticAlarm2),
    StaticAlarm3: displayStaticAlarm(v.StaticAlarm3),
    DynamicAlarm1: displayDynamicAlarm(v.DynamicAlarm1, v.Logger),
    DynamicAlarm2: displayDynamicAlarm(v.DynamicAlarm2, v.Logger),
    FallbackPhone: v.FallbackPhone || undefined,
    Password: v.Password || undefined,
    APN: displayAPN(v),
    Server: displayServer(v),
    UltrasonicMeasurement: v.UltrasonicMeasurement || undefined,
    Height: v.Height || undefined,
    NetworkOperator: v.NetworkOperator || undefined,
    OperatingBand: v.OperatingBand || undefined,
    Try: displayTry(v.Try),
    Delay: displayDelay(v.Delay),
    SonicQuality: displaySonicQuality(v.SonicQuality),
  };
};

const CommandsView = ({ id }: { id?: string }) => {
  const {
    data: {
      commands: { setting, current, archive },
    },
  } = useCommandsQuery({
    variables: { id: id || 'UNSET', limit: 10 },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container small>
      <Label text="Pending Command">
        <CommandInfo {...current} initialDetail />
      </Label>
      <Label text="Recent Commands">
        {mapOrEmpty(
          archive,
          CommandInfo,
          <Text>{specialCharacters.enDash}</Text>,
        )}
      </Label>
      <Label text="Latest Settings">
        <DeviceInfo
          savedAt={setting?.savedAt}
          text={setting?.text}
          values={settingValuesToStrings(setting)}
          initialDetail
        />
      </Label>
    </Container>
  );
};

export default CommandsView;
