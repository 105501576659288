import { useState } from 'react';

import { useTankDetailQuery, TankResult } from '@jl/graphql-schema';
import { Boundary, TankWarning, TankHistory, Label } from '@jl/components';
import { Text } from '@jl/assets';
import { mapOrEmpty, specialCharacters } from '@jl/utils';

import TankDownload from './download';

export const TankDetailView = ({
  tank: { id, unit, label },
}: {
  tank: TankResult;
}) => {
  const [limit] = useState(5);
  const [downloading, setDownloading] = useState(false);

  const { data } = useTankDetailQuery({
    variables: { limit, id },
  });

  const tank = data?.tank;
  if (!tank) return null;

  return (
    <>
      <Label
        text="History"
        action={[
          {
            disabled: downloading,
            text: 'Download as CSV',
            onClick: () => setDownloading(true),
          },
        ]}
      >
        {!!tank.levels.length && (
          <Boundary>
            <TankHistory items={tank.levels} unit={unit} />
          </Boundary>
        )}
        <TankDownload
          id={id}
          label={label}
          visible={downloading}
          setVisible={setDownloading}
        />
      </Label>
      <Label text="Recent Warnings">
        {mapOrEmpty(
          tank.warnings,
          TankWarning,
          <Text>{specialCharacters.enDash}</Text>,
        )}
      </Label>
    </>
  );
};
