import { getAuthConfig } from './build-authorize-url';
import { silentRefresh } from './silent-refresh';

export const requestPasswordChange = async (email: string) => {
  const { resetPasswordUrl, clientId } = await getAuthConfig();
  return fetch(resetPasswordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      client_id: clientId,
      connection: 'Username-Password-Authentication',
    }),
  }).then(r => r.text());
};

export const requestVerification = () => silentRefresh('verification');
