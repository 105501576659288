export const gaugeNS = 'jl:gauge:';
export const tankNS = 'jl:tank:';
export const fairbanksNS = 'jl:fairbanks-user:';
export const userNS = 'jl:user:';

export const stripNamespace = (uri?: string) => {
  const [company, entity, id] = uri?.split(':') || [];
  return { company, entity, id };
};

export const linkify = (
  uri: string,
  opt?: { path?: string; params?: Record<string, string> },
) => {
  const { entity, id } = stripNamespace(uri);
  const fullPath = [`/${entity}s`, id, opt?.path].filter(Boolean).join('/');
  const fullParams = opt?.params && new URLSearchParams(opt.params).toString();
  return [fullPath, fullParams].join('?');
};

export const addNamespace = (entity: string, id?: string) =>
  id && `jl:${entity}:${id}`;

export const maybeAddNamespace = (
  entity: string,
  { id, uri }: { id?: string; uri?: string },
) => uri || addNamespace(entity, id) || undefined;

export const tankFromGaugeId = (gaugeId: string) =>
  gaugeId.replace(gaugeNS, tankNS);
export const imeiFromGaugeId = (gaugeId: string) =>
  gaugeId.replace(gaugeNS, '');
export const gaugeIdFromImei = (imei: string) => `${gaugeNS}${imei}`;
export const gaugeFromTankId = (tankId: string) =>
  tankId.replace(tankNS, gaugeNS);

export const fairbanksIdFromFTPUser = (ftpUser: string) =>
  ftpUser.replace(/^jl-/, fairbanksNS);
export const ftpUserFromFairbanksId = (userId: string) =>
  userId.replace(fairbanksNS, 'jl-');
export const tankFromFairbanksId = (fairbanksUserId: string, tank: string) =>
  `${fairbanksUserId.replace(fairbanksNS, tankNS)}-${tank}`;

export const tankFromSerialNumber = (serialNumber: string) =>
  `${tankNS}${serialNumber}`;
export const tankFromUnitNumber = ([unitNumber, tankNumber]: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
]) => `${tankNS}${unitNumber}-${tankNumber}`;
export const tankFromSerialAndId = (accountSerial: string, tankId: string) =>
  `${tankNS}${accountSerial}-${tankId}`;

export const emailFromUserId = (userId?: string) => userId?.replace(userNS, '');
