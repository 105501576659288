import {
  Hint,
  percentColour,
  Row,
  Subtitle,
  Stretch,
  apiLogo,
  useTheme,
} from '@jl/assets';
import { formatTime, specialCharacters } from '@jl/utils';

import {
  Gauge,
  Level,
  Label,
  LeftLabel,
  RightLabel,
  Markers,
  Marker,
} from './tank-gauge.styles';
import { Logo } from './account-info.styles';

export interface TankGaugeProps {
  id: string;
  contents: string;
  level: {
    time: Date | string;
    value: number;
    percent: number;
    maximum: number;
  } | null;
  unit: string;
  warnings: number[];

  label?: string;
  account?: {
    label: string;
    detail?: string;
    api: string;
  };
  onSelect?: (id: string) => void;
}

export const TankGauge = ({
  id,
  contents,
  level,
  unit,
  warnings,
  label,
  account,
  onSelect,
}: TankGaugeProps) => {
  const theme = useTheme();
  const colour = percentColour(theme, level && level.percent, warnings);

  const labelLeft = (
    <strong>
      {level ? Math.round(level.percent) : specialCharacters.enDash}%
    </strong>
  );
  const labelRight = (
    <span>
      {level
        ? Math.round(level.value).toLocaleString()
        : ` ${specialCharacters.enDash} `}{' '}
      {unit}
    </span>
  );
  const displayPercent = level ? level.percent : 0;

  return (
    <Gauge
      onClick={onSelect && (() => onSelect(id))}
      type="button"
      disabled={!level}
    >
      {!!label && (
        <Row align="center">
          {!!account && <Logo src={apiLogo(account.api)} />}
          <Subtitle>
            <strong>{label}</strong>
            {!!account && <span> | {account.label}</span>}
            {!!account && !!account.detail && <span> | {account.detail}</span>}
          </Subtitle>
          <Stretch />
          <Hint>
            Last Updated{' '}
            {level ? formatTime(level.time) : specialCharacters.enDash}
          </Hint>
        </Row>
      )}
      <Label>
        <LeftLabel percent={displayPercent} colour={colour}>
          {displayPercent > 15 && labelLeft}
          {displayPercent >= 85 && labelRight}
        </LeftLabel>
        <RightLabel>
          {displayPercent <= 15 && labelLeft}
          {displayPercent < 85 && labelRight}
        </RightLabel>
      </Label>
      <Level percent={displayPercent} colour={colour} />
      <Markers>
        {warnings.map((w, i) => (
          <Marker key={i} percent={w}>
            <span>{w}%</span>
          </Marker>
        ))}
      </Markers>
      <Label>
        <Hint>
          {level && Math.round(level.maximum).toLocaleString()} {unit} of{' '}
          {contents} (SFL)
        </Hint>
      </Label>
    </Gauge>
  );
};
