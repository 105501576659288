import { useEffect, useState } from 'react';

import { Row, Hint, Failure } from '@jl/assets';

import { Button } from './button';
import { Container } from './feedback.styles';
import { Form } from './form';
import { Radio } from './radio';
import { TextArea } from './input';
import { Modal } from './modal';

const feedbackTypes = [
  { value: 'Problem', label: 'Problem?' },
  { value: 'Feature Request', label: 'Suggestion?' },
  { value: 'Service Request', label: 'Service Request?' },
];

export type FeedbackValues = {
  type: 'Problem' | 'Feature Request' | 'Service Request';
  request: string;
};
const initialFeedback: FeedbackValues = { type: 'Problem', request: '' };

export type FeedbackProps = {
  onSubmit: (values: FeedbackValues) => void;
  onShow: () => void;
};

type InternalFeedbackProps = FeedbackProps & {
  setModal: (v: boolean) => void;
  error?: string;
  setError: (e: string) => void;
};

const FeedbackModal = ({
  onSubmit,
  onShow,
  setModal,
  setError,
  error,
}: InternalFeedbackProps) => {
  useEffect(() => {
    onShow();
  });
  return (
    <Form
      initialValues={initialFeedback}
      onSubmit={(v) => {
        try {
          onSubmit(v);
          setModal(false);
        } catch (e) {
          console.error('Form submission failed', e);
          setError('Could not submit form.');
        }
      }}
    >
      <Radio
        label="Do You Have A..."
        name="type"
        options={feedbackTypes}
        inline
      />
      <TextArea label="How Can We Help?" name="request" rows={5} />
      {error && (
        <Hint>
          <Failure>{error}</Failure>
          <Failure>
            Please contact your JoyLevel rep or email{' '}
            <a href="mailto:support@joylevel.io">support@joylevel.io</a>
          </Failure>
        </Hint>
      )}
      <Row>
        <Button
          type="submit"
          icon="paperPlane"
          text="Send"
          style="success"
          outline
        />
      </Row>
    </Form>
  );
};

export const Feedback = (props: FeedbackProps) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState<string>();
  return (
    <>
      <Container>
        <Button
          icon="question"
          style="success"
          onClick={() => setModal(!modal)}
        />
      </Container>
      <Modal
        visible={modal}
        setVisible={setModal}
        title="Want to provide feedback?"
      >
        <FeedbackModal
          {...props}
          setModal={setModal}
          error={error}
          setError={setError}
        />
      </Modal>
    </>
  );
};
