import {
  useNotificationsSubscription,
  useUpdatedAccountIds,
  useUpdatedTankIds,
  useUpdatedDocumentIds,
  useUpdatedGaugeIds,
} from '@jl/graphql-schema';
import { Notification } from '@jl/components';
import { useAuth, useNotifications } from '../../hooks';

const UserSubscriptions = () => {
  const [user] = useAuth();
  const { addNotification } = useNotifications();
  useNotificationsSubscription({
    onData: ({ data: { data } }) => {
      const { message, type } = (data && data.notifications) || {
        message: '',
        type: 'Info',
      };
      addNotification({
        id: crypto.randomUUID(),
        content: message,
        status: type.toLocaleLowerCase() as Notification['status'],
      });
    },
    variables: {
      userId: user.userId,
    },
  });
  useUpdatedAccountIds();
  useUpdatedTankIds();
  useUpdatedDocumentIds();
  useUpdatedGaugeIds();

  return null;
};

export default UserSubscriptions;
