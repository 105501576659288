import { buildAuthorizeUrl } from './build-authorize-url';
import { silentRefresh } from './silent-refresh';
import { fetchUserInfo, isUserInfo } from './fetch-user-info';

type authToken = 'id' | 'access';

export const clearAuthToken = (type: authToken = 'access') => {
  return localStorage.removeItem(`${type}_token`);
};

export const getAuthToken = (type: authToken = 'access') => {
  return localStorage.getItem(`${type}_token`);
};

export const setAuthToken = (value: string, type: authToken = 'access') => {
  return localStorage.setItem(`${type}_token`, value);
};

export const checkAuth = async () => {
  if (window.location.hash) {
    const params = new URLSearchParams(window.location.hash.slice(1));
    const token = params.get('access_token');
    if (token) {
      setAuthToken(token);
    }
  }

  let userInfo;
  if (navigator.onLine) {
    // Try using the existing token
    try {
      userInfo = await fetchUserInfo();
      localStorage.set('user_info', JSON.stringify(userInfo));
    } catch (e) {}

    // If that fails, silently fetch and try again
    if (!userInfo) {
      try {
        await silentRefresh();
        userInfo = await fetchUserInfo();
        localStorage.setItem('user_info', JSON.stringify(userInfo));
      } catch (e) {}
    }
  } else {
    // If not online, pull out of local storage and hope for the best
    const stored = JSON.parse(localStorage.getItem('user_info') || '{}');
    if (isUserInfo(stored)) {
      userInfo = stored;
    }
  }

  if (userInfo) {
    return userInfo;
  } else {
    window.location.href = await buildAuthorizeUrl();
    return undefined;
  }
};
