import { SelectHTMLAttributes, useMemo } from 'react';

import { isString } from '@jl/utils';

import { useField } from './use-form';
import { Label } from './label';
import { BoxSize } from './label.styles';
import { SSelect } from './select.styles';
import { Icon } from './icon';

export type SelectOptions = {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}[];

export type SelectProps = {
  name: string;
  label: string;
  placeholder?: string;
  size?: BoxSize;
  options: SelectOptions;
} & Pick<SelectHTMLAttributes<HTMLSelectElement>, 'disabled'>;

export const Select = ({
  name,
  label,
  size,
  options,
  placeholder,
  ...rest
}: SelectProps) => {
  const fieldProps = useField(name, { guard: isString, default: '' });

  return useMemo(
    () => (
      <Label text={label} size={size}>
        <SSelect name={name} {...fieldProps} {...rest}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label || value}
            </option>
          ))}
        </SSelect>
        <Icon name="angleDown" />
      </Label>
    ),
    [options, fieldProps],
  );
};
