import { ResponsiveBar } from '@nivo/bar';

import { useTheme, Hint, Rule } from '@jl/assets';
import { avg, useShortTime } from '@jl/utils';

export interface StatusBarProps {
  timestamps: number[];
  values: number[];
  label: string;
}

export const StatusBar = ({ label, timestamps, values }: StatusBarProps) => {
  const theme = useTheme();
  const data = values
    .slice()
    .reverse()
    .map((value, i) => ({
      id: `${i}`.padStart(4, '0'),
      value,
    }));
  const average = avg(values).toFixed(1);
  const latest = `latest ${values[0] || 0}`;
  const age = useShortTime(timestamps[0] * 1000);

  return (
    <>
      <Hint>
        <strong>{label}: </strong>
        <span>
          {latest} ({age})
        </span>
        <span> | avg {average}</span>
      </Hint>
      <div style={{ width: '100%', height: 50 }}>
        <ResponsiveBar
          data={data}
          colors={[theme.successPale]}
          minValue={0}
          enableGridY={false}
          enableLabel={false}
          isInteractive={false}
          padding={0.3}
        />
      </div>
      <Rule />
    </>
  );
};
