import UserFeedback from './feedback';
import UserNavigation from './navigation';
import UserNotifications from './notifications';
import UserSubscriptions from './subscriptions';
import UserTerms from './terms';

const Global = () => (
  <>
    <UserNotifications />
    <UserNavigation />
    <UserFeedback />
    <UserSubscriptions />
    <UserTerms />
  </>
);

export default Global;
