export type TScheme = 'light' | 'dark';
export type TSchemeListener = (scheme: TScheme) => void;
export type TSchemeListenerReporter = (listener: TSchemeListener) => void;

const MEDIA = '(prefers-color-scheme: dark)';
const checkMatch = (matcher: { matches: boolean }): TScheme =>
  matcher.matches ? 'dark' : 'light';

export const preferredScheme = (subscribe?: TSchemeListener) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unsubscribe = () => {};
  if (!window.matchMedia)
    return { unsubscribe, value: checkMatch({ matches: false }) };

  const matcher = window.matchMedia(MEDIA);

  if (subscribe) {
    const listener = (e: MediaQueryListEvent) => subscribe(checkMatch(e));
    if (matcher.addEventListener) matcher.addEventListener('change', listener);
    if (matcher.addListener) matcher.addListener(listener);

    unsubscribe = () => {
      if (matcher.removeEventListener)
        matcher.removeEventListener('change', listener);
      if (matcher.removeListener) matcher.removeListener(listener);
    };
  }

  return {
    unsubscribe,
    value: checkMatch(matcher),
  };
};
