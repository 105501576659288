import { CSSProperties } from 'react';

export const body: CSSProperties = {
  color: '#333333',
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

export const container: CSSProperties = {
  margin: '0 auto',
  textAlign: 'center',
  padding: '20px 0',
};

export const logo: CSSProperties = { margin: '0 auto 20px' };

export const title: CSSProperties = { fontSize: '26px' };

export const subtitle: CSSProperties = {
  color: '#666666',
  fontSize: '16px',
  lineHeight: '25px',
};
