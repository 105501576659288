import { useCallback } from 'react';

import {
  calculateVolume,
  PressureCalculationOptions,
  PressureCalculationValues,
  PressureCalculationType,
  calculateInitialPressure,
} from './calculate-reading';
import { specialCharacters } from './special-characters';

const buildDisplayString = (amount: number | undefined, unit: string) =>
  `${amount?.toFixed(2) || specialCharacters.enDash} ${unit}`;

const buildPercent = (amount: number | undefined, max: number | undefined) =>
  amount && max ? Math.round((amount / max) * 100) : undefined;

const buildPercentString = (
  amount: number | undefined,
  max: number | undefined,
  unit: string,
) =>
  max
    ? `${
        buildPercent(amount, max) || specialCharacters.enDash
      }% of ${buildDisplayString(max, unit)}`
    : specialCharacters.enDash;

export const useMemoizedCalculations = (
  input: PressureCalculationValues,
  type: PressureCalculationType,
) =>
  useCallback(
    (options: PressureCalculationOptions) => {
      let pressure;
      let result;
      try {
        result = calculateVolume(input, options, type);
        if (type === 'ADC')
          pressure = calculateInitialPressure(input?.digital, options).pressure;
      } catch {}

      const {
        volumeSFL,
        fillSFL,
        calculatedFill,
        reportedVolume,
        calculatedVolume,
      } = result || {};

      return {
        calculatedPressure: buildDisplayString(pressure, 'mm'),
        reportedVolume: buildDisplayString(reportedVolume, 'L'),
        calculatedFill: buildDisplayString(calculatedFill, 'mm'),
        calculatedVolume: buildDisplayString(calculatedVolume, 'L'),
        percentFill: buildPercentString(calculatedFill, fillSFL, 'mm'),
        percentVolume: buildPercentString(calculatedVolume, volumeSFL, 'L'),
        percentNum: buildPercent(calculatedVolume, volumeSFL),
        line:
          calculatedFill && calculatedVolume
            ? { mm: calculatedFill, litres: calculatedVolume }
            : undefined,
      };
    },
    [input],
  );

export type MemoizedCalulate = ReturnType<typeof useMemoizedCalculations>;

export type MemoizedCalculations = ReturnType<MemoizedCalulate>;
