import { IconName, Text } from '@jl/assets';
import { isArray, specialCharacters } from '@jl/utils';

import { Label, LabelProps } from './label';
import { BoxSize } from './label.styles';
import { Icon } from './icon';

export type DisplayProps = {
  label: string;
  action?: LabelProps['action'];
  size?: BoxSize;
  text: string[] | string | number | null | undefined;
  icon?: IconName;
  preformatted?: boolean;
};

export const Display = ({
  label,
  action,
  size,
  text,
  icon,
  preformatted,
}: DisplayProps) => (
  <Label text={label} size={size} action={action}>
    {isArray(text) ? (
      text.map((t) => <Text key={t}>{t}</Text>)
    ) : (
      <Text preformatted={preformatted}>
        {icon && <Icon name={icon} align="text-bottom" />}
        {icon && text && specialCharacters.nbsp}
        {text}
      </Text>
    )}
  </Label>
);
