import styled from '@emotion/styled';

export type InfoListStatus = 'action' | 'success' | 'warning' | 'danger';

export const List = styled.span`
  display: flex;
  flex-flow: row wrap;

  gap: 1.6rem;
`;

export const Item = styled.span<{ strong?: boolean; status?: InfoListStatus }>`
  position: relative;
  font-weight: ${(props) => (props.strong ? 'bold' : 'inherit')};
  color: ${(props) => (props.status ? props.theme[props.status] : 'inherit')};

  &:not(:first-of-type)::before {
    content: '';
    opacity: 0.4;
    border-left: ${(props) => `1px solid ${props.theme.foreground}`};
    position: absolute;
    top: -10%;
    bottom: -10%;
    left: -0.8rem;
  }
`;
