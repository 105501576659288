import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';

import { Hint, useTheme } from '@jl/assets';
import { TooltipBox } from './tank-history.styles';
import { useMemo } from 'react';
import { isNotNullOrUndefined } from '@jl/utils';

export interface TankHistoryItem {
  time: string;
  value: number;
  percent: number;
  voltage?: number | null;
}
export interface TankHistoryProps {
  items: TankHistoryItem[];
  unit: string;
}

export const TankHistory = ({ items, unit }: TankHistoryProps) => {
  const theme = useTheme();
  const data = useMemo(() => {
    const voltageItems = items
      .map(({ time, voltage }) =>
        voltage ? { x: time, y: voltage } : undefined,
      )
      .filter(isNotNullOrUndefined);

    return [
      {
        id: '%',
        data: items.map(({ time, percent, value, voltage }) => ({
          x: time,
          y: percent,
          v: value,
          b: voltage,
        })),
      },
      voltageItems.length ? { id: 'V', data: voltageItems } : undefined,
    ].filter(isNotNullOrUndefined);
  }, [items]);

  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveLine
        margin={{ top: 20, right: 20, bottom: 60, left: 50 }}
        data={data}
        colors={[theme.success, theme.action]}
        theme={{
          grid: { line: { stroke: theme.foreground, strokeWidth: 0.5 } },
          axis: {
            ticks: {
              line: { stroke: theme.foreground, strokeWidth: 0.5 },
              text: { fill: theme.foreground },
            },
          },
          crosshair: { line: { stroke: theme.foreground } },
        }}
        enableArea={true}
        xScale={{
          type: 'time',
          format: '%Y-%m-%dT%H:%M:%S.%LZ',
          precision: 'hour',
        }}
        xFormat="time:%a, %b %-d, %-I%p"
        axisBottom={{
          format: '%a %d',
          tickRotation: -30,
        }}
        axisLeft={{
          format: (value) => `${value}%`,
          tickValues: [0, 20, 40, 60, 80, 100],
          tickRotation: -30,
        }}
        yScale={{
          type: 'linear',
          min: -10,
          max: 110,
        }}
        gridXValues={[items[0].time]}
        gridYValues={[0, 20, 40, 60, 80, 100]}
        curve="monotoneX"
        enablePoints={false}
        enableSlices="x"
        defs={[
          linearGradientDef('hidden', [
            { offset: 0, color: 'inherit', opacity: 0 },
          ]),
          linearGradientDef('gradientFade', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[
          { match: { id: 'V' }, id: 'hidden' },
          { match: { id: '%' }, id: 'gradientFade' },
        ]}
        sliceTooltip={(item) => {
          const data = item.slice.points.find((p) => p.serieId === '%')
            ?.data as
            | { xFormatted: string; y: number; v: number; b?: number }
            | undefined;
          return data ? (
            <TooltipBox>
              <Hint>{data.xFormatted}</Hint>
              <Hint>
                <strong>{Math.round(data.y)}%</strong>
                {' - '}
                {data.v.toLocaleString()} {unit}
              </Hint>
              {data.b && <Hint>Battery: {Math.round(data.b)}%</Hint>}
            </TooltipBox>
          ) : null;
        }}
      />
    </div>
  );
};
