import { useMemo } from 'react';

import { Row, Title } from '@jl/assets';
import {
  useValues,
  AccountInfo,
  Count,
  RoleInfo,
  RoleInfoProps,
} from '@jl/components';
import { useAccountsQuery, AccountsQuery, RoleType } from '@jl/graphql-schema';
import { sortAndFilterAndMap, mapOrEmpty, linkify } from '@jl/utils';

import { AccountFilters } from './filters';
import { useHistory } from '../../hooks';

const mapRoles = (accounts: AccountsQuery['accounts']): RoleInfoProps[] => {
  const roleMap: Record<string, RoleInfoProps> = {};
  accounts.forEach((account) =>
    account.roles.forEach((role) => {
      roleMap[role.userId] = roleMap[role.userId] || {
        userId: role.userId,
        email: role.userId.replace('jl:user:', ''),
        accounts: [],
      };
      roleMap[role.userId].accounts.push({
        roleId: role.id,
        roleType: role.type,
        label: account.label,
        detail: account.detail || undefined,
        api: account.api,
      });
    }),
  );

  return Object.values(roleMap);
};

const AccountList = ({
  accounts,
  search,
  sort,
  order,
  roleTypes,
}: { accounts: AccountsQuery['accounts'] } & AccountFilters) => {
  const { push } = useHistory();
  const filtered = sortAndFilterAndMap(accounts, {
    search,
    sort: (a) => a[sort] || '',
    order,
    keys: ['label', 'detail', 'roles.userId'],
    map: ({ roles, ...a }) => ({
      ...a,
      roles: roles.filter((r) => roleTypes.indexOf(r.type) !== -1),
    }),
  });

  return (
    <Row>
      <Count
        filtered={filtered.length}
        total={accounts.length}
        label="Accounts"
      />
      {mapOrEmpty(
        filtered,
        (a) => (
          <AccountInfo {...a} key={a.id} onSelect={(id) => push(linkify(id))} />
        ),
        <Row>
          <Title>No Accounts Found.</Title>
        </Row>,
      )}
    </Row>
  );
};

const RoleList = ({
  roles,
  search,
  order,
  roleTypes,
}: { roles: RoleInfoProps[] } & AccountFilters) => {
  const filtered = sortAndFilterAndMap(roles, {
    search,
    sort: (a) => a.email || '',
    order,
    keys: ['email', 'accounts.label', 'accounts.detail'],
    map: ({ accounts, ...r }) => ({
      ...r,
      accounts: accounts.filter(
        (a) => roleTypes.indexOf(a.roleType as RoleType) !== -1,
      ),
    }),
  });
  if (filtered.length === 0) {
    return (
      <Row>
        <Title>No Roles Found.</Title>
      </Row>
    );
  }

  return (
    <Row>
      <Count filtered={filtered.length} total={roles.length} label="Roles" />
      {mapOrEmpty(
        filtered,
        RoleInfo,
        <Row>
          <Title>No Roles Found.</Title>
        </Row>,
      )}
    </Row>
  );
};

export const List = () => {
  const { data } = useAccountsQuery();
  const accounts = (data && data.accounts) || [];

  const { view, search, sort, order, roleTypes } = useValues<AccountFilters>();

  return useMemo(() => {
    const filters = { view, search, sort, order, roleTypes };
    if (view === 'account') {
      return <AccountList accounts={accounts} {...filters} />;
    } else {
      const roles = mapRoles(accounts);
      return <RoleList roles={roles} {...filters} />;
    }
  }, [accounts, view, search, sort, order, roleTypes]);
};
