import type { OperationVariables } from '@apollo/client';
import { DocumentNode } from 'graphql';

import {
  useAccountIdsSubscription,
  AccountDocument,
  useTankIdsSubscription,
  TankDocument,
  useDocumentIdsSubscription,
  DocumentDocument,
  useGaugeIdsSubscription,
  GaugeDocument,
} from '../hooks';
import * as ApolloReactHooks from './hooks';

type TSubscriptionHook<
  SD,
  SV extends OperationVariables = OperationVariables,
> = (
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SD, SV>,
) => ApolloReactHooks.SubscriptionResult<SD, SV>;

const subHookFactory =
  <SD, SV extends OperationVariables>(
    useSubscription: TSubscriptionHook<SD, SV>,
    subscriptionKey: keyof SD,
    query: DocumentNode,
  ) =>
  () =>
    useSubscription({
      onData: ({ client, data: { data } }) => {
        const { ids } = (data &&
          (data[subscriptionKey] as unknown as { ids: string[] })) || {
          ids: [],
        };
        ids.forEach((id) => {
          try {
            const local = client.readQuery({
              query,
              variables: { id },
            });
            if (local)
              client.query({
                query,
                variables: { id },
                fetchPolicy: 'network-only',
              });
          } catch {}
        });
      },
    });

export const useUpdatedAccountIds = subHookFactory(
  useAccountIdsSubscription,
  'accountIds',
  AccountDocument,
);
export const useUpdatedTankIds = subHookFactory(
  useTankIdsSubscription,
  'tankIds',
  TankDocument,
);
export const useUpdatedDocumentIds = subHookFactory(
  useDocumentIdsSubscription,
  'documentIds',
  DocumentDocument,
);
export const useUpdatedGaugeIds = subHookFactory(
  useGaugeIdsSubscription,
  'gaugeIds',
  GaugeDocument,
);
