import styled from '@emotion/styled';
import { mix, transparentize } from 'polished';
import { Field } from './input.styles';

export const Search = styled(Field)`
  align-self: stretch;
`;

export const Items = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;

  .list {
    position: absolute;
    z-index: 1000;
    top: 120%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;

    opacity: 0;
    max-height: 0;
    padding: 3px;
    background: ${(props) =>
      mix(0.9, props.theme.background, props.theme.disabled)};
    overflow: scroll;

    transition: opacity 0.3s ease, max-height 0.1s ease;
  }

  &:focus-within .list {
    opacity: 1;
    max-height: 200px;

    transition: opacity 0.1s ease, max-height 0.3s ease;
  }
`;

export const Item = styled.button`
  background: none;
  border: 0;
  border-radius: 1px;

  display: flex;
  align-items: center;
  text-align: left;

  padding: 6px 10px;
  min-height: 60px;
  flex: 1;
  min-width: 0;

  &[aria-selected='true'] {
    cursor: default;
    background: ${(props) => transparentize(0.8, props.theme.borderPale)};
  }
`;

export const SelectableItem = styled(Item)`
  cursor: pointer;

  &:not([aria-selected='true']):hover {
    background: ${(props) => transparentize(0.7, props.theme.borderPale)};
  }
`;

export const Info = styled.div`
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    opacity: 0.5;
  }
`;
