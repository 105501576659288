import { useState } from 'react';

import { Stretch, Rule, Hint, apiLogo } from '@jl/assets';
import { specialCharacters } from '@jl/utils';

import { MainHeader, Logo, Links, Link } from './navigation.styles';
import { Avatar } from './avatar';
import { Button, ButtonProps } from './button';
import { Modal } from './modal';

export interface NavigationProps {
  name: string;
  home: () => void;
  links: { text: string; onClick: () => void; selected: boolean }[];
  buttons: ButtonProps[];
  version: string;
}

export const Navigation = ({
  name,
  home,
  links,
  buttons,
  version,
}: NavigationProps) => {
  const [menu, setMenu] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <>
      <MainHeader>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            home();
          }}
        >
          <Logo src={apiLogo('JoyLevel')} alt="JoyLevel" />
        </a>
        {!!links.length && (
          <Links visible={menu} onClick={() => menu && setMenu(false)}>
            <Button
              icon="bars"
              style="standard"
              onClick={() => setMenu(!menu)}
            />
            {links.map(({ text, onClick, selected }) => (
              <Link key={text} onClick={onClick} selected={selected}>
                {text}
              </Link>
            ))}
          </Links>
        )}
        <Stretch />
        <Avatar text={name} onClick={() => setModal(!modal)} />
      </MainHeader>
      <Modal
        visible={modal}
        setVisible={setModal}
        heading={<Avatar text={name} />}
        title={`${specialCharacters.nbsp}${name}`}
        small
      >
        {buttons.map((b) => (
          <Button key={b.name} style="standard" full {...b} />
        ))}
        <Rule />
        <Hint style={{ textAlign: 'center', opacity: 0.6 }}>{version}</Hint>
      </Modal>
    </>
  );
};
