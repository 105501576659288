import styled from '@emotion/styled';
import { transparentize } from 'polished';

import { IconName, TTheme } from '@jl/assets';

export type TSettingsEventStatus = 'UPDATED' | 'SENT' | 'SAVED';

export const statusIcon: Record<TSettingsEventStatus, IconName> = {
  UPDATED: 'ellipsisH',
  SENT: 'envelopeOpen',
  SAVED: 'globe',
};

export const statusColour: Record<TSettingsEventStatus, keyof TTheme> = {
  UPDATED: 'border',
  SENT: 'success',
  SAVED: 'action',
};

export const Info = styled.button`
  background: none;
  border: 0;
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 4px 12px 4px 8px;
  text-align: left;
  cursor: pointer;

  &:nth-of-type(even) {
    background-color: ${(props) => transparentize(0.9, props.theme.borderPale)};
  }
`;

export const Detail = styled.div`
  border-top: 1px dotted ${(props) => props.theme.borderPale};
  margin: 0 2rem;
  padding: 0.2rem 0;
  width: 100%;
`;

export const Events = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row-reverse;
`;

export const Event = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 0 3px;
  max-width: 23px;
  transition: max-width 0.2s ease;

  p {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    max-width: 100px;
    p {
      opacity: 1;
      transition-duration: 0.1s;
    }
  }
`;

export const EventIcon = styled.div<{ status: TSettingsEventStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;

  background-color: ${(props) => props.theme[statusColour[props.status]]};
  border: 1px solid ${(props) => props.theme.background};
  border-radius: 3rem;
`;

export const EventLabel = styled.p`
  font-size: 1rem;
  margin: 1px 0;
  white-space: nowrap;
  text-align: center;
`;
