import { useMemo } from 'react';

import { Row } from '@jl/assets';
import {
  Count,
  GaugeInfo,
  GaugeSummary,
  GaugeSummaryProps,
  useValues,
  EmptyTank,
} from '@jl/components';
import { useUnassignedGaugesQuery } from '@jl/graphql-schema';
import { sortAndFilter, mapOrEmpty, linkify } from '@jl/utils';

import { useHistory } from '../../hooks';
import { GaugeFilters, sorters } from './filters';

const views = { grid: GaugeSummary, list: GaugeInfo };
const selectView = (
  view: 'grid' | 'list',
  onSelect: GaugeSummaryProps['onSelect'],
) =>
  function GaugeView(props: GaugeSummaryProps) {
    const Element = views[view];
    return <Element key={props.id} {...props} onSelect={onSelect} />;
  };

export const GaugeList = () => {
  const { data } = useUnassignedGaugesQuery();
  const unassignedGauges = (data && data.unassignedGauges) || [];

  const { push } = useHistory();
  const { view, reportingOnly, search, sort, order } =
    useValues<GaugeFilters>();

  return useMemo(() => {
    const filtered = sortAndFilter(
      reportingOnly
        ? unassignedGauges.filter((g) => !!g.latest)
        : unassignedGauges,
      {
        search,
        order,
        sort: sorters[sort],
        keys: ['id', 'name'],
      },
    );

    return (
      <Row>
        <Count
          filtered={filtered.length}
          total={unassignedGauges.length}
          label="Unassigned Gauges"
        />
        {mapOrEmpty(
          filtered,
          selectView(view, (id) => push(linkify(id))),
          <Row>
            <EmptyTank name="Gauges" />
          </Row>,
        )}
      </Row>
    );
  }, [unassignedGauges, view, reportingOnly, search, sort, order]);
};
