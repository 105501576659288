export const mapboxSettings = {
  accessToken:
    'pk.eyJ1Ijoiam95bGV2ZWwiLCJhIjoiY2p3N2xxMG1kMHdrajRjbHdidmN2OGs0ayJ9.r2bKP3n8UX95JvR9mQ_tsw',
};

export const currentLocation = () =>
  new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
    if (!navigator.geolocation) reject(new Error(`Geolocation not available`));
    navigator.geolocation?.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        reject(new Error(error.message));
      },
    );
  });

export const reverseGeocode = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<{ display: string; latitude: number; longitude: number }> => {
  const url = new URL(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`,
  );
  url.searchParams.set('access_token', mapboxSettings.accessToken);
  try {
    const result = await fetch(url.toString()).then((r) => r.json());
    const display = result.features[0]?.place_name || '';
    return { display, latitude, longitude };
  } catch (e) {
    return { display: '', latitude, longitude };
  }
};

const coords = /(-?\d+.?\d*),(-?\d+.?\d*)/;

export const geocode = async (
  search: string,
  bias?: {
    latitude: number;
    longitude: number;
  },
): Promise<
  {
    display: string;
    latitude: number;
    longitude: number;
  }[]
> => {
  try {
    if (search.match(coords)) {
      const [num1, num2] = search.split(',').map(parseFloat);

      const first = await reverseGeocode({ latitude: num1, longitude: num2 });
      const second = await reverseGeocode({ latitude: num2, longitude: num1 });

      return [first, second].filter((l) => !!l.display);
    } else {
      const url = new URL(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          search,
        )}.json`,
      );
      url.searchParams.set('access_token', mapboxSettings.accessToken);
      url.searchParams.set('types', 'address');
      url.searchParams.set('limit', '3');
      if (bias) {
        url.searchParams.set('proximity', `${bias.longitude},${bias.latitude}`);
      }
      const result = await fetch(url.toString()).then((r) => r.json());
      if (!result.features) {
        throw new Error('No Features');
      }
      return result.features.map(
        (f: { place_name: string; center: [number, number] }) => ({
          display: f.place_name,
          latitude: f.center[1],
          longitude: f.center[0],
        }),
      );
    }
  } catch (e) {
    return [];
  }
};
