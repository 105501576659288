import { isNullOrUndefined } from './guards';

export function buildDisplayAmount(amount: number | null, unit: string) {
  if (!isNullOrUndefined(amount) && unit === 'Litres') {
    return amount > 1000 || amount < -1000
      ? { amount: (amount / 1000).toFixed(1), unit: ' kL' }
      : { amount: amount.toFixed(1), unit: ' L' };
  } else {
    return { amount, unit };
  }
}
