import { Row } from '@jl/assets';
import {
  Button,
  Checkboxes,
  Form,
  Modal,
  Select,
  useForm,
  useValues,
} from '@jl/components';
import { GaugeInput, ScheduleInput } from '@jl/graphql-schema';
import { dayKeys } from '@jl/utils';

const ScheduleForm = Form<{
  days: string[];
  every: ScheduleInput['every'];
  frequency: string;
  startHour: string;
  timeZone: string;
}>;

export const ScheduleGauge = ({
  modal,
  setModal,
}: {
  modal: boolean;
  setModal: (modal: boolean) => void;
}) => {
  const { setValue } = useForm();
  const values = useValues<GaugeInput>();

  return (
    <Modal visible={modal} setVisible={setModal} title="Update Gauge Schedule">
      <ScheduleForm
        initialValues={{
          days: dayKeys.filter(
            (day) =>
              (values.schedule?.days || {
                Monday: true,
                Tuesday: true,
                Wednesday: true,
                Thursday: true,
                Friday: true,
                Saturday: true,
                Sunday: true,
              })[day],
          ),
          every: values.schedule?.every || 'Week',
          frequency: `${values.schedule?.frequency || 24}`,
          startHour: `${values.schedule?.startTime?.[0] || 8}`,
          timeZone:
            values.timeZone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'Etc/UTC',
        }}
        onSubmit={({ days, every, frequency, startHour, timeZone }) => {
          setValue('timeZone', timeZone);
          setValue('schedule', {
            days: dayKeys.reduce(
              (acc, day) => ({ ...acc, [day]: days.indexOf(day) !== -1 }),
              {},
            ),
            every,
            frequency: parseInt(frequency, 10),
            startTime: [parseInt(startHour, 10), 0],
            endTime: 23,
          });
          setModal(false);
        }}
      >
        <Row>
          <Select
            label="Time Zone"
            name="timeZone"
            options={[
              { value: 'Australia/Sydney' },
              { value: 'Australia/Adelaide' },
              { value: 'Australia/Brisbane' },
              { value: 'Australia/Darwin' },
              { value: 'Australia/Perth' },
              { value: 'Etc/UTC' },
            ]}
          />
        </Row>
        <Row>
          <Select
            label="Start Time"
            name="startHour"
            size="compact"
            options={[
              { value: 1, label: '1 am' },
              { value: 2, label: '2 am' },
              { value: 3, label: '3 am' },
              { value: 4, label: '4 am' },
              { value: 5, label: '5 am' },
              { value: 6, label: '6 am' },
              { value: 7, label: '7 am' },
              { value: 8, label: '8 am' },
              { value: 9, label: '9 am' },
              { value: 10, label: '10 am' },
              { value: 11, label: '11 am' },
              { value: 12, label: '12 noon' },
              { value: 13, label: '1 pm' },
              { value: 14, label: '2 pm' },
              { value: 15, label: '3 pm' },
              { value: 16, label: '4 pm' },
              { value: 17, label: '5 pm' },
              { value: 18, label: '6 pm' },
              { value: 19, label: '7 pm' },
              { value: 20, label: '8 pm' },
              { value: 21, label: '9 pm' },
              { value: 22, label: '10 pm' },
              { value: 23, label: '11 pm' },
            ]}
          />
          <Select
            label="Frequency"
            name="frequency"
            size="compact"
            options={[
              { value: 24, label: 'Daily' },
              { value: 12, label: 'Every 12 Hours' },
              { value: 4, label: 'Every 4 Hours' },
              { value: 2, label: 'Every 2 Hours' },
              { value: 1, label: 'Hourly' },
            ]}
          />
          <Select
            label="Every"
            name="every"
            size="compact"
            options={[
              { value: 'Month' },
              { value: 'Fortnight' },
              { value: 'Week' },
            ]}
          />
        </Row>
        <Row>
          <Checkboxes
            label="Days"
            name="days"
            options={[
              { value: 'Monday', label: 'Mon' },
              { value: 'Tuesday', label: 'Tue' },
              { value: 'Wednesday', label: 'Wed' },
              { value: 'Thursday', label: 'Thu' },
              { value: 'Friday', label: 'Fri' },
              { value: 'Saturday', label: 'Sat' },
              { value: 'Sunday', label: 'Sun' },
            ]}
            inline
          />
        </Row>
        <Row>
          <Button
            icon="history"
            text="Update Schedule"
            type="submit"
            style="success"
            outline
          />
        </Row>
      </ScheduleForm>
    </Modal>
  );
};
