import { lighten, darken } from 'polished';

import { styled, TTheme, breakPoint } from '@jl/assets';

export const Container = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  opacity: 0.8;
  z-index: 15;
`;

export const notificationColours = (theme: TTheme) => ({
  info: darken(0.3, theme.action),
  success: darken(0.3, theme.success),
  warn: darken(0.3, theme.warning),
  error: darken(0.3, theme.danger),
});

export const NotificationText = styled.p<{ colour: string }>`
  position: relative;
  display: block;
  text-align: center;
  margin: 0;
  padding: 15px 80px;

  background-color: ${props => lighten(0.65, props.colour)};
  color: ${props => props.colour};
  font-size: 1.4rem;
  font-style: italic;

  @media (max-width: ${breakPoint}px) {
    padding: 15px 10px;
  }

  * {
    vertical-align: middle;
  }

  button {
    display: inline;
    background: none;
    border: 0;
    margin: 0;
    padding: 0 3px;
    cursor: pointer;

    font-size: inherit;
    font-style: inherit;
    font-weight: bold;
    text-decoration: underline;

    &:focus {
      outline: none;
      color: ${props => props.theme.action};
    }
  }
`;
