import { useFairbanksUsersQuery, FairbanksTank } from '@jl/graphql-schema';
import { Compute, Input, InputArray, InputFixedArray } from '@jl/components';
import { specialCharacters } from '@jl/utils';

const SelectFairbanksUser = () => {
  const { data } = useFairbanksUsersQuery();
  const options = data?.fairbanksUsers || [];

  return (
    <InputArray
      name="fairbanksUsers"
      label="FTP Users"
      selectValue={{
        placeholder: 'Select FTP User',
        options,
        valueKey: 'id',
      }}
    >
      <Input name="id" label="FTP User" readOnly />
      <InputFixedArray name="tanks">
        <Input name="tank" label="Tank" readOnly size="compact" />
        <Compute
          label="Current Level"
          calculate={(v: FairbanksTank) =>
            v.lastLevel || specialCharacters.enDash
          }
          size="compact"
        />
        <Input
          name="safeFillLevel"
          label="Safe Fill Level"
          type="number"
          size="compact"
        />
      </InputFixedArray>
    </InputArray>
  );
};

export default SelectFairbanksUser;
