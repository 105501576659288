import { useEffect } from 'react';

import { useDocumentQuery, useUpdateUserMutation } from '@jl/graphql-schema';
import { useAuth, useNotifications, useHistory } from '../../hooks';
import { silentRefresh } from '@jl/utils/src';

const id = 'jl:document:terms-of-service';

const UserTerms = () => {
  const { location, push } = useHistory();
  const { addNotification } = useNotifications();
  const { data } = useDocumentQuery({ variables: { id } });
  const [user] = useAuth();
  const [mutate] = useUpdateUserMutation();

  useEffect(() => {
    if (data?.document && user) {
      const acceptTerms = async () => {
        await mutate({
          variables: {
            fields: {
              terms: {
                version: data.document?.version || '',
                updatedAt: data.document?.updatedAt || '',
              },
            },
          },
        });
        await silentRefresh();
      };

      if (!user.terms.version || user.terms.version !== data.document.version) {
        addNotification({
          id,
          content: `By continuing to use JoyLevel, you're agreeing to our`,
          status: 'info',
          timeout: -1,
          action: {
            text: 'Terms of Service',
            onClick: async () => {
              location.pathname !== '/documents/terms-of-service' &&
                push('/documents/terms-of-service');
            },
          },
        });
        acceptTerms();
      }
    }
  }, [
    data && data.document && data.document.version,
    user && user.terms.version,
  ]);

  return null;
};

export default UserTerms;
