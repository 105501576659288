import { useRef } from 'react';

import {
  Input,
  Radio,
  Filter,
  GaugeSummaryProps,
  Switch,
} from '@jl/components';
import { SortDirection } from '@jl/utils';

export interface GaugeFilters {
  view: 'grid' | 'list';
  reportingOnly: boolean;
  search: string;
  sort: 'name' | 'time' | 'voltage';
  order: SortDirection;
}
const orderLabels = {
  name: ['A', 'Z'],
  time: ['Oldest', 'Newest'],
  voltage: ['Low', 'High'],
};
export const orderOptions = (sort: GaugeFilters['sort'] | undefined) =>
  sort
    ? [
        {
          label: `${orderLabels[sort][0]} to ${orderLabels[sort][1]}`,
          value: SortDirection.ASCENDING,
        },
        {
          label: `${orderLabels[sort][1]} to ${orderLabels[sort][0]}`,
          value: SortDirection.DESCENDING,
        },
      ]
    : [];

type SortParams = GaugeSummaryProps;
export const sorters = {
  name: ({ name }: SortParams) => name,
  time: ({ latest }: SortParams) => latest?.time || '',
  voltage: ({ latest }: SortParams) => latest?.voltage || undefined,
};

export const defaultFilters: GaugeFilters = {
  view: 'grid',
  reportingOnly: true,
  search: '',
  sort: 'time',
  order: SortDirection.DESCENDING,
};

export const GaugeFilter = () => {
  const focusRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  return (
    <Filter focusRef={focusRef}>
      <Radio
        inline
        label=""
        name="view"
        options={[
          { label: 'Grid', icon: 'grid', value: 'grid' },
          { label: 'List', icon: 'list', value: 'list' },
        ]}
      />
      <Input label="Search" name="search" inputRef={focusRef} search />
      <Switch label="Hide Non-Reporting Gauges" name="reportingOnly" />
      <Radio
        label="Sort By"
        name="sort"
        options={[
          { label: 'Gauge Name', value: 'name' },
          { label: 'Latest Update', value: 'time' },
          { label: 'Battery Level', value: 'voltage' },
        ]}
      />
      <Radio
        label="Order By"
        name="order"
        basedOn="sort"
        optionsFn={orderOptions}
      />
    </Filter>
  );
};
