import { ChangeEvent, ReactNode, useMemo } from 'react';

import { Column, Row, Stretch } from '@jl/assets';
import { isArray } from '@jl/utils';

import { useField, FormProvider } from './use-form';
import {
  FieldSet,
  FieldSetBottomRow,
  FieldSetHint,
  Legend,
} from './input.styles';
import { Button, ButtonProps } from './button';
import { SSelect } from './select.styles';
import { Icon } from './icon';
import { Label } from './label';

export const InputFixedArray = ({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) => {
  const { value, setValue, ...rest } = useField(name, {
    guard: isArray,
    default: [],
  });
  const setInnerValue = (i: number, n: string | number, v: unknown) => {
    const newValue = value?.slice() || [];
    newValue[i] = { ...(value ? value[i] : {}), [n]: v };
    setValue(newValue);
  };

  return (
    <Column>
      {(value || []).map((v, i) => (
        <FormProvider
          key={i}
          value={{
            state: { values: v, errors: {} },
            setValue: (n, v) => setInnerValue(i, n, v),
            ...rest,
          }}
        >
          <Row align="center">{children}</Row>
        </FormProvider>
      ))}
    </Column>
  );
};

export type InputArrayProps = {
  name: string;
  label: string;
  defaultValue?: () => { [k: string]: unknown };
  action?: ButtonProps[];
  selectValue?: {
    placeholder: string;
    valueKey: string;
    labelKey?: string;
    options: { [k: string]: unknown }[];
  };
  hint?: ReactNode;
  children: ReactNode;
};

export const InputArray = ({
  name,
  label,
  defaultValue,
  action,
  selectValue,
  hint,
  children,
}: InputArrayProps) => {
  const { value, setValue, ...rest } = useField(name, {
    guard: isArray,
    default: [],
  });
  const setInnerValue = (i: number, n: string | number, v: unknown) => {
    const newValue = value?.slice() || [];
    newValue[i] = { ...(value ? value[i] : {}), [n]: v };
    setValue(newValue);
  };
  const removeRow = (i: number) => {
    const newValue = value?.slice() || [];
    newValue.splice(i, 1);
    setValue(newValue);
  };
  const addRow = () => {
    if (defaultValue) {
      setValue([...(value || []), defaultValue()]);
    }
  };
  const selectRow = (e: ChangeEvent<HTMLSelectElement>) =>
    selectValue &&
    setValue([
      ...(value || []),
      {
        ...selectValue.options.find(
          (o) => o[selectValue.valueKey] === e.currentTarget.value,
        ),
      },
    ]);

  const available =
    (selectValue &&
      (selectValue.options || [])
        .filter(
          (o) =>
            !value?.find(
              (v) => v[selectValue.valueKey] === o[selectValue.valueKey],
            ),
        )
        .map((o) => ({
          label: o[selectValue.labelKey || selectValue.valueKey],
          value: o[selectValue.valueKey],
        }))) ||
    [];

  return useMemo(
    () => (
      <FieldSet>
        <Legend>{label}</Legend>
        {(value || []).map((v, i) => (
          <FormProvider
            key={i}
            value={{
              state: { values: v, errors: {} },
              setValue: (n, v) => setInnerValue(i, n, v),
              ...rest,
            }}
          >
            <Row align="center">
              {children}
              <Button
                icon="minus"
                style="danger"
                size={12}
                outline
                onClick={() => removeRow(i)}
              />
            </Row>
          </FormProvider>
        ))}
        <FieldSetBottomRow>
          {defaultValue && (
            <Button icon="plus" style="default" size={16} onClick={addRow} />
          )}
          {action?.map((a) => (
            <Button key={a.text || a.icon} size={16} {...a} />
          ))}
          {selectValue && !!available.length && (
            <Label text="">
              <SSelect value="" onChange={selectRow}>
                <option value="" disabled>
                  {selectValue.placeholder}
                </option>
                {available.map(({ label, value }) => (
                  <option key={`${value}`} value={value as string}>
                    {label as string}
                  </option>
                ))}
              </SSelect>
              <Icon name="angleDown" />
            </Label>
          )}
          {hint && (
            <>
              <Stretch />
              <FieldSetHint>{hint}</FieldSetHint>
            </>
          )}
        </FieldSetBottomRow>
      </FieldSet>
    ),
    [value, label, hint],
  );
};
