import styled from '@emotion/styled';
import { iconLibrary, IconName } from '@jl/assets';

export interface IconProps {
  name: IconName;
  title?: string;
  rotate?: number;
  size?: number;
  colour?: string;
  align?: string;
  outline?: string;
}

const Svg = styled.svg<{ align?: string; rotate?: number }>`
  ${(props) => props.align && `vertical-align: ${props.align}`};
  transform: rotate(
    ${(props) => (props.rotate !== undefined ? props.rotate : 0)}deg
  );
  transition: transform 0.2s ease;
`;

export const Icon = ({
  name,
  title,
  size,
  colour,
  align,
  rotate,
  outline,
}: IconProps) => {
  const icon = iconLibrary[name];
  const pathData = icon && icon[4];
  let path: string[];
  switch (typeof pathData) {
    case 'string': {
      path = [pathData];
      break;
    }
    case 'object': {
      if (Array.isArray(pathData)) {
        path = pathData;
        break;
      }
    }
    case 'undefined': {
      path = [];
      break;
    }
  }

  return (
    <Svg
      viewBox={`0 0 ${icon[0]} ${icon[1]}`}
      height={size || 20}
      width={size || 20}
      align={align}
      rotate={rotate}
    >
      <title>{title}</title>
      {path.map((d, i) => (
        <path
          key={i}
          d={d}
          fill={colour || 'currentColor'}
          strokeWidth={outline ? 20 : 0}
          stroke={outline}
        />
      ))}
    </Svg>
  );
};
