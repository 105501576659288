import { useCallback } from 'react';

import {
  tankValues,
  TankResult,
  useUpdateTankMutation,
  TankInput,
  tankUpdateProps,
} from '@jl/graphql-schema';
import {
  Form,
  Input,
  Select,
  Button,
  Address,
  ListInput,
} from '@jl/components';
import { Container, Row } from '@jl/assets';
import { useEvent, sendEvent, linkify, isAdmin } from '@jl/utils';

import { useAuth, useHistory } from '../../hooks';

const TankForm = ({ tank }: { tank: TankResult }) => {
  const [user] = useAuth();
  useEvent({
    category: 'Tank',
    action: 'edit',
    label: tank.id,
  });
  const [mutate] = useUpdateTankMutation();
  const { replace } = useHistory();
  const onSubmit = useCallback(
    (v: TankInput) => {
      mutate(tankUpdateProps(tank, v));
      sendEvent({
        category: 'Tank',
        action: 'submit',
        label: tank.id,
      });
      replace(linkify(tank.id));
    },
    [tank],
  );

  return (
    <Container small>
      <Form initialValues={tankValues(tank)} onSubmit={onSubmit}>
        <Input name="label" label="Name" />
        {isAdmin(user) && (
          <Input
            name="billingStartedAt"
            label="Billing Start Date"
            type="date"
          />
        )}
        <Row>
          <Select
            name="unit"
            label="Units"
            options={[
              { value: 'Litres' },
              { value: 'Gallons' },
              { value: 'US Gallons' },
            ]}
          />
          <Input name="contents" label="Contents" />
        </Row>
        <Address name="location" label="Location" />
        <Row>
          <ListInput
            name="warningLevels"
            label="Warning Levels (%)"
            type="number"
          />
          <ListInput name="warningEmails" label="Warning Emails" />
        </Row>
        <Row>
          <Button
            icon="upload"
            text="Update Tank"
            type="submit"
            style="success"
            outline
          />
        </Row>
      </Form>
    </Container>
  );
};

export default TankForm;
