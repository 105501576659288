import { useCallback, useLayoutEffect, useRef } from 'react';

import { Copy } from './click-and-copy.styles';

export interface ClickAndCopyProps {
  text: string;
  onCopy?: (text: string) => void;
}

export const ClickAndCopy = ({ text, onCopy }: ClickAndCopyProps) => {
  const ref = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.style.width = `${ref.current.value.length}ch`;
    }
  });
  const copyText = useCallback(async () => {
    await navigator.clipboard.writeText(text);
    if (onCopy) onCopy(text);
  }, []);

  return <Copy ref={ref} value={text} readOnly onClick={copyText} />;
};
