import { useRef } from 'react';

import { Input, Filter, Radio, Checkboxes, Button } from '@jl/components';
import { RoleType } from '@jl/graphql-schema';
import { SortDirection } from '@jl/utils';

import { useHistory } from '../../hooks';
import { roleOptions } from '../account/form';

export interface AccountFilters {
  view: 'account' | 'role';
  search: string;
  sort: 'label' | 'detail' | 'api';
  order: SortDirection;
  roleTypes: RoleType[];
}
export const orderOptions = [
  {
    label: `A to Z`,
    value: SortDirection.ASCENDING,
  },
  {
    label: `Z to A`,
    value: SortDirection.DESCENDING,
  },
];

export const defaultFilters: AccountFilters = {
  view: 'account',
  search: '',
  sort: 'label',
  order: SortDirection.ASCENDING,
  roleTypes: roleOptions.map(({ value }) => value),
};

export const AccountFilter = () => {
  const focusRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const { push } = useHistory();
  return (
    <Filter focusRef={focusRef}>
      <Radio
        inline
        label=""
        name="view"
        options={[
          { label: 'Account', icon: 'building', value: 'account' },
          { label: 'Role', icon: 'userTie', value: 'role' },
        ]}
      />
      <Input label="Search" name="search" inputRef={focusRef} search />
      <Radio
        label="Sort By"
        name="sort"
        options={[
          { label: 'Account Label', value: 'label' },
          { label: 'Account Detail', value: 'detail' },
          { label: 'API Type', value: 'api' },
        ]}
      />
      <Radio label="Order By" name="order" options={orderOptions} />
      <Checkboxes label="Roles" name="roleTypes" options={roleOptions} />
      <Button
        icon="plus"
        text="Create Account"
        onClick={() => push('/accounts/new')}
        outline
      />
    </Filter>
  );
};
