import { useRef } from 'react';

import { Input, Filter, Radio, UserInfoProps } from '@jl/components';
import { SortDirection } from '@jl/utils';

export interface UserFilters {
  search: string;
  sort: 'email' | 'last_login' | 'login_count';
  order: SortDirection;
}
const orderLabels = {
  email: ['A', 'Z'],
  last_login: ['Oldest', 'Newest'],
  login_count: ['Low', 'High'],
};
export const orderOptions = (sort: UserFilters['sort'] | undefined) =>
  sort
    ? [
        {
          label: `${orderLabels[sort][0]} to ${orderLabels[sort][1]}`,
          value: SortDirection.ASCENDING,
        },
        {
          label: `${orderLabels[sort][1]} to ${orderLabels[sort][0]}`,
          value: SortDirection.DESCENDING,
        },
      ]
    : [];

type SortParams = UserInfoProps;
export const sorters = {
  email: ({ email }: SortParams) => email || '',
  last_login: ({ app_metadata }: SortParams) =>
    (app_metadata && app_metadata.last_login) || '',
  login_count: ({ app_metadata }: SortParams) =>
    (app_metadata && app_metadata.login_count) || 0,
};

export const defaultFilters: UserFilters = {
  search: '',
  sort: 'email',
  order: SortDirection.ASCENDING,
};

export const UserFilter = () => {
  const focusRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  return (
    <Filter focusRef={focusRef}>
      <Input label="Search" name="search" inputRef={focusRef} search />
      <Radio
        label="Sort By"
        name="sort"
        options={[
          { label: 'Email Address', value: 'email' },
          { label: 'Last Login', value: 'last_login' },
          { label: 'Login Count', value: 'login_count' },
        ]}
      />
      <Radio
        label="Order By"
        name="order"
        basedOn="sort"
        optionsFn={orderOptions}
      />
    </Filter>
  );
};
