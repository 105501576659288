import { transparentize } from 'polished';

import { styled } from '@jl/assets';

export const TOOLTIP_HEIGHT = 60;
export const TOOLTIP_WIDTH = 130;
export const TOOLTIP_PADDING = 5;

export const TooltipLine = styled.line`
  opacity: 0.5;
  stroke: ${props => props.theme.border};
  stroke-dasharray: 1 2;
`;

export const TooltipBox = styled.div`
  width: ${TOOLTIP_WIDTH + TOOLTIP_PADDING * 2}px;
  height: ${TOOLTIP_HEIGHT}px;
  overflow: hidden;

  border-radius: 3px;
  background: ${props => transparentize(0.6, props.theme.border)};
  padding: 0 ${TOOLTIP_PADDING}px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 1px 0;

    font-size: 13px;
  }
`;
