import { useCallback } from 'react';

import {
  accountValues,
  AccountResult,
  useUpdateAccountMutation,
  AccountInput,
  accountUpdateProps,
  usePlansQuery,
} from '@jl/graphql-schema';
import {
  Form,
  Input,
  Button,
  ListInput,
  InputArray,
  Select,
  Toggle,
  Boundary,
  Switch,
  TextArea,
} from '@jl/components';
import { Container, Row } from '@jl/assets';
import { useEvent, sendEvent, timeZones, linkify } from '@jl/utils';

import FairbanksUserSelect from './fairbanks-user-select';
import GaugeSelect from './gauge-select';
import { useHistory } from '../../hooks';

export const tzOptions = timeZones.map((tz) => ({
  value: tz,
  label: tz,
}));
export const apiOptions = (
  ['Colibri', 'JoyGauge', 'ProGauge', 'RMSDelta', 'SmartFill'] as const
).map((type) => ({
  value: type,
  label: type,
}));
export const roleOptions = (
  ['Installer', 'Owner', 'User', 'Vendor', 'Payer'] as const
).map((type) => ({
  value: type,
  label: type,
}));

const AccountForm = ({ account }: { account: AccountResult }) => {
  useEvent({
    category: 'Account',
    action: 'edit',
    label: account.id,
  });
  const {
    data: { plans },
  } = usePlansQuery();
  const [mutate] = useUpdateAccountMutation();
  const { replace } = useHistory();
  const onSubmit = useCallback(
    (v: AccountInput) => {
      mutate(accountUpdateProps(account, v));
      sendEvent({
        category: 'Account',
        action: 'submit',
        label: account.id,
      });
      replace(linkify(account.id));
    },
    ['tank'],
  );

  return (
    <Container small>
      <Form initialValues={accountValues(account)} onSubmit={onSubmit}>
        <Row>
          <Input name="label" label="Name" size="compact" />
          <Input name="detail" label="Vendor" size="compact" />
          <Switch
            name="externalAPIEnabled"
            label="External API Access"
            size="compact"
          />
        </Row>
        <TextArea name="notes" label="Notes" rows={1} />
        <Select
          name="plan"
          label="Plan"
          options={[
            { value: '' },
            ...plans.map(({ name }) => ({ value: name })),
          ]}
        />
        <Select name="api" label="API Type" options={apiOptions} />
        <Toggle name="api" match="SmartFill">
          <Input name="reference" label="Reference" />
          <Input name="secret" label="Secret" />
        </Toggle>
        <Toggle name="api" match="Colibri">
          <Input name="url" label="URL" />
        </Toggle>
        <Toggle name="api" match="RMSDelta">
          <ListInput name="serialNumbers" label="Serial Numbers" />
        </Toggle>
        <Toggle name="api" match="JoyGauge">
          <Boundary spinner small>
            <GaugeSelect />
          </Boundary>
        </Toggle>
        <Toggle name="api" match="ProGauge">
          <Select name="timezone" label="Time Zone" options={tzOptions} />
          <Boundary spinner small>
            <FairbanksUserSelect />
          </Boundary>
        </Toggle>
        <InputArray
          name="roles"
          label="Account Users"
          defaultValue={() => ({
            id: `jl:role:${crypto.randomUUID()}`,
            userId: '',
            type: 'User',
          })}
        >
          <Select name="type" label="Type" options={roleOptions} />
          <Input name="userId" label="Email" />
        </InputArray>

        <Row>
          <Button
            icon="upload"
            text="Update Account"
            type="submit"
            style="success"
            outline
          />
        </Row>
      </Form>
    </Container>
  );
};

export default AccountForm;
