import { useEffect, useState } from 'react';
import {
  InMemoryCache,
  NormalizedCacheObject,
  ApolloClient,
  ApolloLink,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { loadConfig } from '@jl/utils';

import { trackQuery } from './analytics';
import { buildWSLink } from './links/ws-link';
import { httpLink } from './links/http-link';

export const getClient = async () => {
  const host = await loadConfig('graphql_host', false);
  const websocket = await loadConfig('graphql_websocket', false);

  const link = ApolloLink.split(
    ({ query, variables }) => {
      const definition = getMainDefinition(query);
      if (definition.name) {
        trackQuery(
          definition.name.value,
          (variables && variables.id) || undefined,
        );
      }

      const isSubscription =
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription';

      return !isSubscription;
    },
    httpLink,
    websocket && host ? buildWSLink(websocket, host) : undefined,
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tank: (_, { args, toReference }) =>
            toReference({ __typename: 'Tank', id: args?.id }),
          account: (_, { args, toReference }) =>
            toReference({ __typename: 'Account', id: args?.id }),
          gauge: (_, { args, toReference }) =>
            toReference({ __typename: 'Gauge', id: args?.id }),
        },
      },
      Tank: {
        fields: {
          account: (account, { variables, readField, toReference }) => {
            if (account) return account;
            const tank = toReference({ __typename: 'Tank', id: variables?.id });
            return (
              tank &&
              toReference({
                __typename: 'Account',
                id: readField('accountId', tank),
              })
            );
          },
        },
      },
    },
  });

  return new ApolloClient({ cache, link });
};

export const setupClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  useEffect(() => {
    getClient().then(setClient);
  }, []);

  return client;
};
