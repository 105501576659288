import {
  useParams as useRouterParams,
  useLocation as useRouterLocation,
  useNavigate,
} from 'react-router-dom';
import { useEffect } from 'react';

export const useParams = <K extends string>(keys: K[]) => {
  const search = new URLSearchParams(useRouterLocation().search);
  const params = useRouterParams();
  return keys.reduce(
    (acc, k) => ({
      ...acc,
      [k]: params[k] || search.get(k) || undefined,
    }),
    {} as Record<K, string | undefined>,
  );
};

export const useLocationChange = (
  fn: (location: ReturnType<typeof useRouterLocation>) => void,
) => {
  const location = useRouterLocation();
  useEffect(() => {
    fn(location);
  }, [fn, location]);
};

export const useHistory = () => {
  const location = useRouterLocation();
  const navigate = useNavigate();

  return {
    location,
    push: (page: string) => navigate(page),
    replace: (page: string) => navigate(page, { replace: true }),
    goBack: () => navigate(-1),
  };
};

export {
  BrowserRouter,
  Link,
  Navigate,
  useLocation,
  useRoutes,
} from 'react-router-dom';
