import { sendEvent, EventArgs } from '@jl/utils';

const queryCategories: Record<string, EventArgs['category']> = {
  accounts: 'Accounts',
  account: 'Account',
  tank: 'Tank',
};

export const trackQuery = (name: string, id?: string) =>
  queryCategories[name] &&
  sendEvent({
    category: queryCategories[name],
    action: 'load',
    label: id,
  });
