import { useState } from 'react';

import { Hint, Column, Emphasize, specialColours } from '@jl/assets';
import {
  formatTime,
  mapOrEmpty,
  specialCharacters,
  useShortTime,
} from '@jl/utils';

import {
  Detail,
  Event,
  EventIcon,
  EventLabel,
  Events,
  Info,
  TSettingsEventStatus,
  statusIcon,
} from './settings-info.styles';
import { Icon } from './icon';

export interface CommandEventProps {
  status: TSettingsEventStatus;
  user?: string;
  time?: string;
}

export const CommandEvent = ({ status, user, time }: CommandEventProps) => (
  <Event title={user}>
    <EventIcon status={status}>
      <Icon name={statusIcon[status]} size={14} colour={specialColours.white} />
    </EventIcon>
    <EventLabel>
      {status} ({useShortTime(time)})
    </EventLabel>
  </Event>
);

export interface SettingsInfoProps {
  sentAt?: string;
  savedAt?: string;
  timeZone?: string | null;
  updatedAt?: string | null;
  updatedBy?: { email: string } | null;
  initialDetail?: boolean;
}

export const SettingsInfo = ({
  sentAt,
  savedAt,
  updatedAt,
  updatedBy,
  detail,
  initialDetail,
}: SettingsInfoProps & { detail: [string, string | null | undefined][] }) => {
  const [visible, setVisible] = useState(initialDetail || false);
  const display = detail.filter((i) => !!i[1]);
  const timeDisplay = savedAt || sentAt || updatedAt;

  return (
    <Info onClick={() => setVisible(!visible)}>
      <Icon name="angleDown" rotate={visible ? 0 : -90} />
      <Column>
        <Hint>
          <Emphasize strong italic>
            {display.length} Setting{display.length !== 1 && 's'}{' '}
            {savedAt ? 'Retrieved' : sentAt ? 'Updated' : 'Pending'}
          </Emphasize>
        </Hint>
        <Hint>
          {timeDisplay ? formatTime(timeDisplay) : specialCharacters.enDash}
        </Hint>
      </Column>
      <Events>
        {updatedAt && (
          <CommandEvent
            status="UPDATED"
            user={updatedBy?.email}
            time={updatedAt}
          />
        )}
        {sentAt && <CommandEvent status="SENT" time={sentAt} />}
        {savedAt && <CommandEvent status="SAVED" time={savedAt} />}
      </Events>
      {visible && (
        <Detail>
          {mapOrEmpty(
            display,
            ([label, value]) => (
              <Hint key={label}>
                <Emphasize strong>{label}:</Emphasize> {value}
              </Hint>
            ),
            <Hint>{specialCharacters.enDash}</Hint>,
          )}
        </Detail>
      )}
    </Info>
  );
};
