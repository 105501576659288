import { lighten } from 'polished';
import { styled } from '@jl/assets';

export type BoxSize = 'regular' | 'compact' | 'subCompact';

const sizeValue: Record<BoxSize, number> = {
  regular: 320,
  compact: 160,
  subCompact: 80,
};

const flexValue: Record<BoxSize, string> = {
  regular: '3 3',
  compact: '2 2',
  subCompact: '1 1',
};

export const Box = styled.div<{ size?: BoxSize }>`
  display: block;
  flex: ${(props) => flexValue[props.size || 'regular']}
    ${(props) => sizeValue[props.size || 'regular']}px;
  margin: 4px 4px 8px;
  padding: 8px 4px 0;

  position: relative;
  width: auto;
`;

export const BoxLabel = styled.label<{ size?: BoxSize }>`
  display: block;
  flex: ${(props) => flexValue[props.size || 'regular']}
    ${(props) => sizeValue[props.size || 'regular']}px;
  margin: 4px 4px 8px;
  padding: 8px 4px 0;

  position: relative;
  width: auto;
`;

export const Text = styled.span`
  display: block;
  padding-bottom: 8px;

  color: ${(props) => props.theme.border};
  font-size: 1.1rem;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Action = styled.span`
  cursor: pointer;
  display: inline-block;
  color: ${(props) => lighten(0.2, props.theme.border)};
  text-decoration: underline;

  &::before {
    content: '';
    border-right: 1px solid ${(props) => props.theme.border};
    padding-right: 0.4rem;
    margin-right: 0.4rem;
    opacity: 0.4;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
    text-decoration: none;
    opacity: 0.4;
  }
`;

export const Rule = styled.hr`
  border: 0;
  border-bottom: 1px dotted ${(props) => props.theme.border};
  width: 100%;
`;
