import { DataProxy } from '@apollo/client';

import { GaugeInput, GaugeQuery, GaugeDocument, GaugeRange } from '../hooks';
import { optimisticMutation } from './general';
import { UserInfo, toGuard } from '@jl/utils';
import { z } from 'zod';

export type GaugeResult = NonNullable<GaugeQuery['gauge']>;

export const rangeOptions = [
  { label: '', value: undefined },
  {
    label: 'Rochester 0.3 Bar',
    value: {
      digital: { min: 0, max: 1024 },
      analog: { min: 0, max: 5 },
      probe: { min: 0.5, max: 4.5 },
      height: { min: 0, max: 3060 },
    },
  },
];

export const isRange = toGuard(
  z
    .object({
      digital: z.object({
        min: z.number(),
        max: z.number(),
      }),
      analog: z.object({
        min: z.number(),
        max: z.number(),
      }),
      probe: z.object({
        min: z.number(),
        max: z.number(),
      }),
      height: z.object({
        min: z.number(),
        max: z.number(),
      }),
    })
    .optional(),
);

export const rangeToLabel = (range: GaugeRange | null | undefined) =>
  rangeOptions.find(
    (o) =>
      o.value?.analog.min === range?.analog.min &&
      o.value?.analog.max === range?.analog.max &&
      o.value?.digital.min === range?.digital.min &&
      o.value?.digital.max === range?.digital.max &&
      o.value?.probe.min === range?.probe.min &&
      o.value?.probe.max === range?.probe.max &&
      o.value?.height.min === range?.height.min &&
      o.value?.height.max === range?.height.max,
  )?.label;

export const specificGravityOptions = [
  { label: 'Diesel', value: 0.87 },
  { label: 'Water', value: 1 },
];

export const gaugeValues = (gauge: GaugeResult): GaugeInput => ({
  model: gauge.model ?? undefined,
  ultrasonic: gauge.ultrasonic ?? undefined,
  notes: gauge.notes || undefined,
  probe_sg: gauge.setup?.probe_sg || 1,
  actual_sg: gauge.setup?.actual_sg || 0.87,
  offset_mm: gauge.setup?.offset_mm || 0,
  offset_litres: gauge.setup?.offset_litres || 0,
  timeZone: gauge.timeZone || undefined,
  schedule: gauge.schedule
    ? {
        days: {
          Monday: gauge.schedule.days.Monday,
          Tuesday: gauge.schedule.days.Tuesday,
          Wednesday: gauge.schedule.days.Wednesday,
          Thursday: gauge.schedule.days.Thursday,
          Friday: gauge.schedule.days.Friday,
          Saturday: gauge.schedule.days.Saturday,
          Sunday: gauge.schedule.days.Sunday,
        },
        every: gauge.schedule.every,
        frequency: gauge.schedule.frequency,
        startTime: gauge.schedule.startTime,
        endTime: gauge.schedule.endTime,
      }
    : undefined,
  strapping: (gauge.setup?.strapping || []).map((s) => ({
    mm: s.mm,
    litres: s.litres,
  })),
  range: gauge.setup?.range
    ? {
        digital: {
          min: gauge.setup.range.digital.min,
          max: gauge.setup.range.digital.max,
        },
        analog: {
          min: gauge.setup.range.analog.min,
          max: gauge.setup.range.analog.max,
        },
        probe: {
          min: gauge.setup.range.probe.min,
          max: gauge.setup.range.probe.max,
        },
        height: {
          min: gauge.setup.range.height.min,
          max: gauge.setup.range.height.max,
        },
      }
    : undefined,
});

export const gaugeUpdateProps = (
  t: GaugeResult,
  v: GaugeInput,
  user: UserInfo,
) => ({
  optimisticResponse: {
    updateGauge: optimisticMutation,
  },
  variables: {
    id: t.id,
    fields: {
      model: v.model,
      ultrasonic: v.ultrasonic,
      timeZone: v.timeZone,
      schedule: v.schedule,
      notes: v.notes,
      probe_sg: v.probe_sg,
      actual_sg: v.actual_sg,
      offset_mm: v.offset_mm,
      offset_litres: v.offset_litres,
      strapping: v.strapping,
      range: v.range,
    },
  },
  update: (proxy: DataProxy) => {
    const updated: GaugeQuery['gauge'] = {
      ...t,
      model: v.model ?? null,
      ultrasonic: v.ultrasonic ?? null,
      notes: v.notes ?? null,
      timeZone: v.timeZone ?? null,
      schedule: v.schedule ?? null,
      setup: {
        probe_sg: v.probe_sg,
        actual_sg: v.actual_sg,
        offset_mm: v.offset_mm,
        offset_litres: v.offset_litres,
        strapping: v.strapping.map((s) => ({
          mm: s.mm,
          litres: s.litres,
          __typename: 'StrapLine',
        })),
        range: v.range
          ? {
              digital: {
                min: v.range.digital.min,
                max: v.range.digital.max,
                __typename: 'Range',
              },
              analog: {
                min: v.range.analog.min,
                max: v.range.analog.max,
                __typename: 'Range',
              },
              probe: {
                min: v.range.probe.min,
                max: v.range.analog.max,
                __typename: 'Range',
              },
              height: {
                min: v.range.height.min,
                max: v.range.height.max,
                __typename: 'Range',
              },
              __typename: 'GaugeRange',
            }
          : null,
        __typename: 'GaugeSetup',
      },
      updatedAt: new Date().toISOString(),
      updatedBy: {
        __typename: 'AppUser',
        email: user.email,
        id: user.userId,
      },
    };
    proxy.writeQuery<GaugeQuery>({
      query: GaugeDocument,
      data: { gauge: updated },
      variables: { id: t.id },
    });
  },
});

export const gaugeResetActivationProps = (g: GaugeResult) => ({
  variables: { id: g.id },
  optimisticResponse: {
    resetGaugeActivation: optimisticMutation,
  },
  update: (proxy: DataProxy) => {
    proxy.writeQuery<GaugeQuery>({
      query: GaugeDocument,
      data: {
        gauge: {
          ...g,
          activatedAt: undefined,
        },
      },
      variables: { id: g.id },
    });
  },
});
