import { isNullOrUndefined } from '@jl/utils';

import { TTheme } from './theme';

/* tslint:disable:object-literal-sort-keys */

type ShadeKey =
  | 'r50'
  | 'r100'
  | 'r200'
  | 'r300'
  | 'r400'
  | 'r500'
  | 'r600'
  | 'r700'
  | 'r800'
  | 'r900';

type ShadeKeyAlt = 'a100' | 'a200' | 'a400' | 'a700';

type PaletteKey =
  | 'red'
  | 'pink'
  | 'purple'
  | 'deepPurple'
  | 'indigo'
  | 'blue'
  | 'lightBlue'
  | 'cyan'
  | 'teal'
  | 'green'
  | 'lightGreen'
  | 'lime'
  | 'yellow'
  | 'amber'
  | 'orange'
  | 'deepOrange'
  | 'brown'
  | 'grey'
  | 'blueGrey';

type Shade = { [k in ShadeKey]: string };
type AltShade = { [k in ShadeKeyAlt]?: string };
type Palette = { [k in PaletteKey]: Shade & AltShade };

export const material: Palette = {
  red: {
    r50: '#ffebee',
    r100: '#ffcdd2',
    r200: '#ef9a9a',
    r300: '#e57373',
    r400: '#ef5350',
    r500: '#f44336',
    r600: '#e53935',
    r700: '#d32f2f',
    r800: '#c62828',
    r900: '#b71c1c',
    a100: '#ff8a80',
    a200: '#ff5252',
    a400: '#ff1744',
    a700: '#d50000',
  },
  pink: {
    r50: '#fce4ec',
    r100: '#f8bbd0',
    r200: '#f48fb1',
    r300: '#f06292',
    r400: '#ec407a',
    r500: '#e91e63',
    r600: '#d81b60',
    r700: '#c2185b',
    r800: '#ad1457',
    r900: '#880e4f',
    a100: '#ff80ab',
    a200: '#ff4081',
    a400: '#f50057',
    a700: '#c51162',
  },
  purple: {
    r50: '#f3e5f5',
    r100: '#e1bee7',
    r200: '#ce93d8',
    r300: '#ba68c8',
    r400: '#ab47bc',
    r500: '#9c27b0',
    r600: '#8e24aa',
    r700: '#7b1fa2',
    r800: '#6a1b9a',
    r900: '#4a148c',
    a100: '#ea80fc',
    a200: '#e040fb',
    a400: '#d500f9',
    a700: '#aa00ff',
  },
  deepPurple: {
    r50: '#ede7f6',
    r100: '#d1c4e9',
    r200: '#b39ddb',
    r300: '#9575cd',
    r400: '#7e57c2',
    r500: '#673ab7',
    r600: '#5e35b1',
    r700: '#512da8',
    r800: '#4527a0',
    r900: '#311b92',
    a100: '#b388ff',
    a200: '#7c4dff',
    a400: '#651fff',
    a700: '#6200ea',
  },
  indigo: {
    r50: '#e8eaf6',
    r100: '#c5cae9',
    r200: '#9fa8da',
    r300: '#7986cb',
    r400: '#5c6bc0',
    r500: '#3f51b5',
    r600: '#3949ab',
    r700: '#303f9f',
    r800: '#283593',
    r900: '#1a237e',
    a100: '#8c9eff',
    a200: '#536dfe',
    a400: '#3d5afe',
    a700: '#304ffe',
  },
  blue: {
    r50: '#e3f2fd',
    r100: '#bbdefb',
    r200: '#90caf9',
    r300: '#64b5f6',
    r400: '#42a5f5',
    r500: '#2196f3',
    r600: '#1e88e5',
    r700: '#1976d2',
    r800: '#1565c0',
    r900: '#0d47a1',
    a100: '#82b1ff',
    a200: '#448aff',
    a400: '#2979ff',
    a700: '#2962ff',
  },
  lightBlue: {
    r50: '#e1f5fe',
    r100: '#b3e5fc',
    r200: '#81d4fa',
    r300: '#4fc3f7',
    r400: '#29b6f6',
    r500: '#03a9f4',
    r600: '#039be5',
    r700: '#0288d1',
    r800: '#0277bd',
    r900: '#01579b',
    a100: '#80d8ff',
    a200: '#40c4ff',
    a400: '#00b0ff',
    a700: '#0091ea',
  },
  cyan: {
    r50: '#e0f7fa',
    r100: '#b2ebf2',
    r200: '#80deea',
    r300: '#4dd0e1',
    r400: '#26c6da',
    r500: '#00bcd4',
    r600: '#00acc1',
    r700: '#0097a7',
    r800: '#00838f',
    r900: '#006064',
    a100: '#84ffff',
    a200: '#18ffff',
    a400: '#00e5ff',
    a700: '#00b8d4',
  },
  teal: {
    r50: '#e0f2f1',
    r100: '#b2dfdb',
    r200: '#80cbc4',
    r300: '#4db6ac',
    r400: '#26a69a',
    r500: '#009688',
    r600: '#00897b',
    r700: '#00796b',
    r800: '#00695c',
    r900: '#004d40',
    a100: '#a7ffeb',
    a200: '#64ffda',
    a400: '#1de9b6',
    a700: '#00bfa5',
  },
  green: {
    r50: '#e8f5e9',
    r100: '#c8e6c9',
    r200: '#a5d6a7',
    r300: '#81c784',
    r400: '#66bb6a',
    r500: '#4caf50',
    r600: '#43a047',
    r700: '#388e3c',
    r800: '#2e7d32',
    r900: '#1b5e20',
    a100: '#b9f6ca',
    a200: '#69f0ae',
    a400: '#00e676',
    a700: '#00c853',
  },
  lightGreen: {
    r50: '#f1f8e9',
    r100: '#dcedc8',
    r200: '#c5e1a5',
    r300: '#aed581',
    r400: '#9ccc65',
    r500: '#8bc34a',
    r600: '#7cb342',
    r700: '#689f38',
    r800: '#558b2f',
    r900: '#33691e',
    a100: '#ccff90',
    a200: '#b2ff59',
    a400: '#76ff03',
    a700: '#64dd17',
  },
  lime: {
    r50: '#f9fbe7',
    r100: '#f0f4c3',
    r200: '#e6ee9c',
    r300: '#dce775',
    r400: '#d4e157',
    r500: '#cddc39',
    r600: '#c0ca33',
    r700: '#afb42b',
    r800: '#9e9d24',
    r900: '#827717',
    a100: '#f4ff81',
    a200: '#eeff41',
    a400: '#c6ff00',
    a700: '#aeea00',
  },
  yellow: {
    r50: '#fffde7',
    r100: '#fff9c4',
    r200: '#fff59d',
    r300: '#fff176',
    r400: '#ffee58',
    r500: '#ffeb3b',
    r600: '#fdd835',
    r700: '#fbc02d',
    r800: '#f9a825',
    r900: '#f57f17',
    a100: '#ffff8d',
    a200: '#ffff00',
    a400: '#ffea00',
    a700: '#ffd600',
  },
  amber: {
    r50: '#fff8e1',
    r100: '#ffecb3',
    r200: '#ffe082',
    r300: '#ffd54f',
    r400: '#ffca28',
    r500: '#ffc107',
    r600: '#ffb300',
    r700: '#ffa000',
    r800: '#ff8f00',
    r900: '#ff6f00',
    a100: '#ffe57f',
    a200: '#ffd740',
    a400: '#ffc400',
    a700: '#ffab00',
  },
  orange: {
    r50: '#fff3e0',
    r100: '#ffe0b2',
    r200: '#ffcc80',
    r300: '#ffb74d',
    r400: '#ffa726',
    r500: '#ff9800',
    r600: '#fb8c00',
    r700: '#f57c00',
    r800: '#ef6c00',
    r900: '#e65100',
    a100: '#ffd180',
    a200: '#ffab40',
    a400: '#ff9100',
    a700: '#ff6d00',
  },
  deepOrange: {
    r50: '#fbe9e7',
    r100: '#ffccbc',
    r200: '#ffab91',
    r300: '#ff8a65',
    r400: '#ff7043',
    r500: '#ff5722',
    r600: '#f4511e',
    r700: '#e64a19',
    r800: '#d84315',
    r900: '#bf360c',
    a100: '#ff9e80',
    a200: '#ff6e40',
    a400: '#ff3d00',
    a700: '#dd2c00',
  },
  brown: {
    r50: '#efebe9',
    r100: '#d7ccc8',
    r200: '#bcaaa4',
    r300: '#a1887f',
    r400: '#8d6e63',
    r500: '#795548',
    r600: '#6d4c41',
    r700: '#5d4037',
    r800: '#4e342e',
    r900: '#3e2723',
  },
  grey: {
    r50: '#fafafa',
    r100: '#f5f5f5',
    r200: '#eeeeee',
    r300: '#e0e0e0',
    r400: '#bdbdbd',
    r500: '#9e9e9e',
    r600: '#757575',
    r700: '#616161',
    r800: '#424242',
    r900: '#212121',
  },
  blueGrey: {
    r50: '#eceff1',
    r100: '#cfd8dc',
    r200: '#b0bec5',
    r300: '#90a4ae',
    r400: '#78909c',
    r500: '#607d8b',
    r600: '#546e7a',
    r700: '#455a64',
    r800: '#37474f',
    r900: '#263238',
  },
};

const exceptKeys: PaletteKey[] = ['yellow', 'brown', 'grey', 'blueGrey'];
const keys = (Object.keys(material) as PaletteKey[]).filter(
  (key) => exceptKeys.indexOf(key) < 0,
);

export const colourArray = (value: ShadeKey | ShadeKeyAlt) =>
  keys.map((key) => material[key] && material[key][value]).filter(Boolean);

export default material;

export const specialColours = {
  white: '#ffffff',
  black: material.grey.r900,
  paleGreen: '#92c386',
  darkPaleGreen: '#8bb081',
};

export function percentColour(
  theme: TTheme,
  percent: number | null,
  warnings?: number[],
) {
  const dangerLevel = (warnings && warnings[0]) || 10;
  const warningLevel = (warnings && warnings[1]) || dangerLevel + 10;

  if (isNullOrUndefined(percent)) {
    return theme.disabledPale;
  }
  if (percent <= dangerLevel) {
    return theme.dangerPale;
  }
  if (percent <= warningLevel) {
    return theme.warningPale;
  }

  return theme.successPale;
}
