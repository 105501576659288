import { styled, shadowFade } from '@jl/assets';

export const Border = styled.button<{ colour: string; onClick?: () => void }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 36px;
  width: 36px;

  background-color: ${props => props.colour};
  border-width: 0;
  border-radius: 100px;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  &:focus {
    animation: ${shadowFade} 0.4s 1 ease-in-out;
    outline: none;
  }
`;

export const Text = styled.span`
  display: block;

  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 1px;
  text-transform: uppercase;
`;
