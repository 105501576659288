import { Text } from '@jl/assets';
import { specialCharacters } from '@jl/utils';

import { SettingsInfo, SettingsInfoProps } from './settings-info';

export type DeviceInfoProps = SettingsInfoProps & {
  text?: string | null;
  values?: Record<string, string | undefined | null>;
};

export const DeviceInfo = ({ values, ...options }: DeviceInfoProps) =>
  values ? (
    <SettingsInfo
      {...options}
      detail={Object.entries(values).filter(([k]) => k !== '__typename')}
    />
  ) : (
    <Text>{specialCharacters.enDash}</Text>
  );
