import { styled } from '@jl/assets';

export const Border = styled.div<{ small?: boolean }>`
  max-width: 60rem;
  margin: ${(props) => (props.small ? '5px' : '30px auto')};
  width: ${(props) => (props.small ? 'fit-content' : '90%')};
  text-align: ${(props) => (props.small ? 'left' : 'center')};
`;

export const Link = styled.a`
  padding: 3px 2px 5px;
  margin: 0;

  font-size: 1.6rem;
  line-height: 1.4;
`;
