import { Row, Subtitle, Stretch, apiLogo, Hint } from '@jl/assets';

import { Info, Logo } from './account-info.styles';

export interface AccountInfoProps {
  id: string;
  label: string;
  detail?: string | null;
  notes?: string | null;
  api: string;
  tanks: { label: string }[];
  roles: { userId: string; type: string }[];
  onSelect: (id: string) => void;
}

export const AccountInfo = ({
  id,
  label,
  detail,
  notes,
  api,
  tanks,
  roles,
  onSelect,
}: AccountInfoProps) => {
  return (
    <Info onClick={onSelect && (() => onSelect(id))} type="button">
      <Row align="center">
        <Logo src={apiLogo(api)} />
        <Subtitle>
          <strong>{label}</strong>
          {!!detail && <span> | {detail}</span>}
        </Subtitle>
        <Stretch />
      </Row>
      <Hint>
        <strong>
          {tanks.length} Tank{tanks.length !== 1 && 's'}:
        </strong>{' '}
        {tanks.map(({ label }) => label).join(', ')}
      </Hint>
      <Hint>
        <strong>
          {roles.length} User{roles.length !== 1 && 's'}:
        </strong>{' '}
        {roles
          .map(
            ({ userId, type }) => `${userId.replace('jl:user:', '')} (${type})`,
          )
          .join(', ')}
      </Hint>
      {notes && (
        <Hint>
          <strong>Notes:</strong> {notes}
        </Hint>
      )}
    </Info>
  );
};
