import { lazy, useMemo } from 'react';

import { Row } from '@jl/assets';
import { useAccountsQuery, AccountsQuery } from '@jl/graphql-schema';
import {
  TankSummary,
  useValues,
  TankSummaryProps,
  TankGauge,
  EmptyTank,
  Count,
  TankGaugeProps,
} from '@jl/components';
import {
  sortAndFilter,
  flatten,
  buildUllageString,
  buildDisplayAmount,
  sumProp,
  mapOrEmpty,
  linkify,
  downloadCSV,
  isVisibleAddress,
  isAdmin,
  emailFromUserId,
  gaugeFromTankId,
} from '@jl/utils';

import { useAuth, useHistory } from '../../hooks';
import { TankFilters, sorters } from './filters';

const Map = lazy(() => import('@jl/components/src/map.default'));

const mapAccounts = (
  accounts: AccountsQuery['accounts'],
  onSelect: TankSummaryProps['onSelect'],
): (TankSummaryProps & TankGaugeProps)[] =>
  flatten(
    accounts.map((account) =>
      account.tanks.map((tank) => ({
        key: `${account.id}-${tank.id}`,
        id: tank.id,
        label: tank.label,
        contents: tank.contents,
        activatedAt:
          account.gauges.find((g) => g.id === gaugeFromTankId(tank.id))
            ?.activatedAt || undefined,
        billingStartedAt: tank.billingStartedAt || undefined,
        plan: account.plan || undefined,
        account: {
          label: account.label,
          detail: account.detail || undefined,
          api: account.api,
        },
        payer:
          emailFromUserId(
            account.roles.find((r) => r.type === 'Payer')?.userId,
          ) || undefined,
        level: tank.latest || null,
        unit: tank.unit,
        warnings: tank.warningLevels || [],
        location: tank.location || undefined,
        onSelect,
      })),
    ),
  );

const viewElements = { grid: TankSummary, list: TankGauge };
const selectView = (view: 'grid' | 'list') =>
  function TankView(props: TankSummaryProps & TankGaugeProps) {
    const Element = viewElements[view];
    return <Element {...props} />;
  };

export const TankList = () => {
  const [user] = useAuth();
  const { data } = useAccountsQuery();
  const accounts = (data && data.accounts) || [];

  const { push } = useHistory();
  const { view, search, sort, order, useCurrentLocation } =
    useValues<TankFilters>();

  return useMemo(() => {
    const tanks = mapAccounts(accounts, (id) => push(linkify(id)));
    const filtered = sortAndFilter(tanks, {
      search,
      sort: sorters[sort],
      order,
      keys: ['label', 'account.label', 'account.detail'],
    });

    const download = () =>
      downloadCSV(
        filtered.map((t) => ({
          id: t.id,
          account: t.account.label,
          label: t.label,
          activatedAt: t.activatedAt || '',
          billingStartedAt: t.billingStartedAt || '',
          plan: t.plan || '',
          payer: t.payer || '',
          contents: t.contents,
          time: t.level?.time || '',
          value: t.level?.value || '',
          maximum: t.level?.maximum || '',
          percent: t.level?.percent || '',
          voltage: t.level?.voltage || '',
        })),
        {
          cells: [
            'id',
            'account',
            'label',
            'activatedAt',
            'billingStartedAt',
            'plan',
            'payer',
            'contents',
            'time',
            'value',
            'maximum',
            'percent',
            'voltage',
          ],
          visibility: {
            billingStartedAt: isAdmin(user),
            plan: isAdmin(user),
            payer: isAdmin(user),
          },
          headers: {
            id: 'ID',
            account: 'Account',
            label: 'Tank',
            activatedAt: 'Activation Date',
            billingStartedAt: 'Billing Start Date',
            plan: 'Plan',
            payer: 'Payer',
            contents: 'Contents',
            time: 'Time',
            value: 'Level',
            maximum: 'SFL',
            percent: 'Level Percent',
            voltage: 'Battery Percent',
          },
          file: 'tanks',
        },
      );

    const litresOnly = filtered.filter(({ unit }) => unit === 'Litres');
    const value = sumProp(litresOnly, ({ level }) => (level ? level.value : 0));
    const totalVolume = buildDisplayAmount(value, 'Litres');
    const totalUllage = buildUllageString(
      {
        value,
        maximum: sumProp(litresOnly, ({ level }) =>
          level ? level.maximum : 0,
        ),
      },
      'Litres',
    );

    return view === 'map' ? (
      <Map
        markers={filtered.filter((t) => isVisibleAddress(t.location))}
        useCurrentLocation={useCurrentLocation}
        fill
      />
    ) : (
      <Row>
        <Count
          filtered={filtered.length}
          total={tanks.length}
          label="Tanks"
          action={[
            {
              icon: 'download',
              text: 'Download as CSV',
              style: 'highlight',
              onClick: download,
            },
          ]}
        >
          <span>
            <strong>CV: </strong> {totalVolume.amount}
            {totalVolume.unit}
          </span>
          <span>
            <strong>CU:</strong> {totalUllage}
          </span>
        </Count>
        {mapOrEmpty(
          filtered,
          selectView(view),
          <Row>
            <EmptyTank />
          </Row>,
        )}
      </Row>
    );
  }, [view, accounts, search, sort, order, useCurrentLocation]);
};
