import { Row } from '@jl/assets';
import { Boundary, FilterForm } from '@jl/components';
import { useEvent, useTitle } from '@jl/utils';

import { defaultFilters, AccountFilter } from './filters';
import { List } from './list';

const Accounts = () => {
  useTitle(['Accounts']);
  useEvent({
    category: 'Accounts',
    action: 'view',
  });

  return (
    <FilterForm name="account" defaults={defaultFilters}>
      <Row>
        <AccountFilter />
        <Boundary spinner>
          <List />
        </Boundary>
      </Row>
    </FilterForm>
  );
};

export default Accounts;
