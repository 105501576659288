import { useTheme } from '@emotion/react';

import {
  Container,
  NotificationText,
  notificationColours,
} from './notification.styles';
import { Icon } from './icon';

export type Notification = {
  id: string;
  status: 'success' | 'info' | 'error' | 'warn';
  content: string;
  action?: {
    text: string;
    onClick: () => void;
  };
  timeout?: number;
};

export type NotificationProps = {
  notifications: Notification[];
  onCancel: (id: string) => void;
};

export const Notifications = ({
  onCancel,
  notifications,
}: NotificationProps) => {
  const colours = notificationColours(useTheme());
  return (
    <Container>
      {notifications.map(({ id, status, content, action }) => (
        <NotificationText key={id} colour={colours[status]}>
          <span>{content}</span>
          {action && (
            <button
              onClick={() => {
                action.onClick();
                onCancel(id);
              }}
            >
              {action.text}
            </button>
          )}
          {
            <button onClick={() => onCancel(id)}>
              <Icon name="times" colour={colours[status]} />
            </button>
          }
        </NotificationText>
      ))}
    </Container>
  );
};
