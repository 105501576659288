import { ApolloProvider, SuspenseCache } from '@apollo/client';
import { Global as GlobalStyle, ThemeProvider } from '@emotion/react';

import { globalCss } from '@jl/assets';
import { setupClient } from '@jl/graphql-schema';
import { setupAnalytics, setUser } from '@jl/utils';
import { Spinner } from '@jl/components';

import Main from './main';
import {
  setupAuth,
  AuthProvider,
  setupNotification,
  NotificationProvider,
  setupTheme,
  BrowserRouter,
} from '../hooks';

const Root = () => {
  setupAnalytics();
  const theme = setupTheme(null);
  const notification = setupNotification();
  const auth = setupAuth(notification);
  const client = setupClient();

  if (auth) setUser(auth[0].userId);

  let render = <Spinner fullScreen />;
  if (auth && client) {
    render = (
      <NotificationProvider value={notification}>
        <BrowserRouter>
          <ApolloProvider client={client} suspenseCache={new SuspenseCache()}>
            <AuthProvider value={auth}>
              <Main />
            </AuthProvider>
          </ApolloProvider>
        </BrowserRouter>
      </NotificationProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle styles={globalCss} />
      {render}
    </ThemeProvider>
  );
};

export default Root;
