import { Hint, IconName } from '@jl/assets';

import { Box, BigText, Table } from './metrics.styles';
import { Icon } from './icon';

export type BigMetricProps = {
  label: string;
  count: number;
};

export const BigMetric = ({ label, count }: BigMetricProps) => (
  <Box>
    <BigText>{count}</BigText>
    <Hint>{label}</Hint>
  </Box>
);

export type MetricTableProps = {
  label: string;
  items: { label?: string; icon?: IconName; count: number }[];
};

export const MetricTable = ({ label, items }: MetricTableProps) => (
  <Table>
    <thead>
      <tr>
        <td colSpan={2}>{label}</td>
      </tr>
    </thead>
    <tbody>
      {items.map(({ label, icon, count }) => (
        <tr key={label || icon}>
          <th>
            {icon && <Icon name={icon} />}
            {label}
          </th>
          <td>{count}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);
