let existingConfig: undefined | Record<string, string> = undefined;
let configPromise: Promise<void> | undefined = undefined;

const downloadConfig = async () => {
  const config = await fetch('/config.json').then(r => r.json());
  existingConfig = config;
};

export const loadConfig = async (key: string, shouldThrow = true) => {
  if (!existingConfig || !existingConfig[key]) {
    if (!configPromise) configPromise = downloadConfig();
    await configPromise;
    configPromise = undefined;
  }
  if (!existingConfig || (!existingConfig[key] && shouldThrow)) {
    throw new Error(
      `Could not load config for ${key}: ${JSON.stringify(existingConfig)}`,
    );
  }
  return existingConfig && existingConfig[key];
};
