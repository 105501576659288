import { Boundary } from '@jl/components';
import { sendView } from '@jl/utils';

import { Navigate, useLocationChange, useRoutes } from '../hooks';
import Global from './global';
import Emails from './dev/emails';
import Me from './pages/me';
import Callback from './pages/callback';
import Document from './pages/document';
import Tanks from './tanks';
import Tank from './tank';
import Accounts from './accounts';
import Account from './account';
import NewAccount from './account/new';
import Commands from './commands';
import Gauges from './gauges';
import Gauge from './gauge';
import Users from './users';
import Status from './status';
import { NotFound } from './not-found';
import { Lookup } from './lookup';

const Routes = () =>
  useRoutes([
    { index: true, element: <Navigate to="/tanks" /> },

    {
      path: '/tanks',
      children: [
        { index: true, element: <Tanks /> },
        { path: ':id', element: <Tank /> },
      ],
    },
    {
      path: '/accounts',
      children: [
        { index: true, element: <Accounts /> },
        { path: 'new', element: <NewAccount /> },
        { path: ':id', element: <Account /> },
      ],
    },
    {
      path: '/gauges',
      children: [
        { index: true, element: <Gauges /> },
        { path: ':id', element: <Gauge /> },
        { path: ':id/commands', element: <Commands /> },
      ],
    },

    { path: '/users', element: <Users /> },
    { path: '/status', element: <Status /> },
    { path: '/me', element: <Me /> },
    { path: '/documents/:id', element: <Document /> },
    { path: '/dev/emails', element: <Emails /> },
    { path: '/callback', element: <Callback /> },
    { path: '/:uri', element: <Lookup /> },
    { path: '*', element: <NotFound /> },
  ]);

const Main = () => {
  useLocationChange((location) =>
    sendView(`${location.pathname}${location.search}`),
  );

  return (
    <Boundary spinner>
      <Global />
      <Routes />
    </Boundary>
  );
};

export default Main;
