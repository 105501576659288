import { useGaugeQuery } from '@jl/graphql-schema';
import { Header, Boundary } from '@jl/components';
import { apiLogo } from '@jl/assets';
import { maybeAddNamespace, NotFoundError, useTitle } from '@jl/utils';

import { useParams, useHistory } from '../../hooks';
import CommandsView from './view';

const CommandsDisplay = () => {
  const { id } = useParams(['id']);
  const { goBack } = useHistory();

  const gaugeId = maybeAddNamespace('gauge', { id });
  const { data } = useGaugeQuery({
    variables: { id: gaugeId || 'UNSET' },
  });
  const gauge = data?.gauge;
  if (!gauge) throw new NotFoundError('Gauge', gaugeId || '');
  useTitle([gauge.name, 'Gauges']);

  return (
    <>
      <Header
        title="Commands"
        subtitle={[gauge.name, gauge.connection, gauge.type]
          .filter(Boolean)
          .join(' | ')}
        image={apiLogo('JoyGauge')}
        leftButton={{
          icon: 'arrowLeft',
          style: 'standard',
          onClick: goBack,
        }}
      />
      <Boundary spinner>
        <CommandsView id={id} />
      </Boundary>
    </>
  );
};

const Commands = () => (
  <Boundary spinner>
    <CommandsDisplay />
  </Boundary>
);

export default Commands;
