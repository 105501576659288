import { Fragment, InputHTMLAttributes, useMemo } from 'react';

import { isStringArray, isNumberArray } from '@jl/utils';

import { useField } from './use-form';
import { Label } from './label';
import { Field } from './input.styles';
import { Rule } from './label.styles';

export type ListInputProps = {
  name: string;
  label: string;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'disabled'>;

export const ListInput = ({ name, label, ...rest }: ListInputProps) => {
  const { value, setValue } = useField<(string | number)[]>(name, {
    guard: rest.type === 'number' ? isNumberArray : isStringArray,
    default: [],
  });

  const onChange = (index: number, item: string) => {
    const newValue = value?.slice() || [];
    newValue[index] = item;
    if (rest.type === 'number') {
      try {
        newValue[index] = parseInt(item);
      } catch {}
    }
    setValue(newValue);
  };
  const onBlur = (index: number, item: string) => {
    if (!item) {
      const newValue = value?.slice() || [];
      newValue.splice(index, 1);
      setValue(newValue);
    }
  };

  const displayValue = [...(value || []), ''];
  return useMemo(
    () => (
      <Label text={label}>
        {displayValue.map((v, i) => (
          <Fragment key={i}>
            {i > 0 ? <Rule /> : null}
            <Field
              value={v}
              onChange={(e) => onChange(i, e.currentTarget.value)}
              onBlur={(e) => onBlur(i, e.currentTarget.value)}
              {...rest}
            />
          </Fragment>
        ))}
      </Label>
    ),
    [value, label],
  );
};
