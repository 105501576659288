import { buildDisplayAmount } from './build-display-amount';
import { specialCharacters } from './special-characters';

export function buildUllageString(
  amount: {
    value: number | null;
    maximum: number;
  } | null,
  unit: string,
) {
  if (!amount) {
    return ` ${specialCharacters.enDash} `;
  }

  const ullageDisplay = buildDisplayAmount(
    amount.maximum - (amount.value || 0),
    unit,
  );
  return `${ullageDisplay.amount}${ullageDisplay.unit}`;
}
