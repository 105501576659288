import { render } from '@react-email/render';

import { Basic } from './basic';
import { TankWarning } from './tank-warning';
import { EmailBaseProps, EmailTemplate, EmailTemplateProps } from './types';

export const templates = {
  Basic,
  TankWarning,
} as const;

type Templates = typeof templates;

export type TemplateKey = keyof Templates;

export type TemplateProps<K extends TemplateKey> = EmailTemplateProps<
  Templates[K]
>;

export const templateKeys = Object.keys(templates) as TemplateKey[];

export const buildTemplate = <K extends TemplateKey>(
  key: K,
  baseProps: EmailBaseProps,
  templateProps: TemplateProps<K>,
) => {
  const builder = templates[key] as EmailTemplate<TemplateProps<K>>;
  const props = { ...baseProps, ...templateProps };
  const elements = builder.email(props);

  return {
    subject: builder.subject(props),
    customArgs: builder.tracking(props),
    html: render(elements),
    text: render(elements, { plainText: true }),
  };
};

export type BuiltTemplate = ReturnType<typeof buildTemplate>;

export * from './types';
