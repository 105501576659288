import { DataProxy } from '@apollo/client';

import {
  DocumentInput,
  Document,
  DocumentDocument,
  DocumentQuery,
} from '../hooks';
import { optimisticMutation } from './general';

export type DocumentResult = Pick<Document, 'id' | '__typename'> &
  Partial<Omit<Document, 'id' | '__typename'>>;

export const documentUpdateProps = (d: DocumentResult, v: DocumentInput) => ({
  optimisticResponse: {
    updateDocument: optimisticMutation,
  },
  variables: {
    id: d.id,
    fields: {
      title: v.title,
      content: v.content,
    },
  },
  update: (proxy: DataProxy) => {
    const updated: DocumentQuery['document'] = {
      ...d,
      version: '',
      updatedAt: new Date().toISOString(),
      title: v.title,
      content: v.content,
    };
    proxy.writeQuery<DocumentQuery>({
      query: DocumentDocument,
      data: { document: updated },
      variables: { id: d.id },
    });
  },
});
