import { Text } from '@jl/assets';
import {
  displaySchedule,
  displayStaticAlarm,
  specialCharacters,
} from '@jl/utils';

import { SettingsInfo, SettingsInfoProps } from './settings-info';

type StaticAlarmSetting = {
  enabled: boolean;
  polarity?: 'higher' | 'lower' | null;
  threshold?: number | null;
  error?: number | null;
};

type CommandSettings = {
  deactivate?: boolean | null;
  host?: string | null;
  port?: string | null;
  ultrasonic?: 'Standard' | 'WaveGuide' | null;
  schedule?: {
    startTime: number[];
    endTime?: number | null;
    frequency: number;
    every?: 'Week' | 'Fortnight' | 'Month' | null;
    days: {
      Sunday: boolean;
      Saturday: boolean;
      Friday: boolean;
      Thursday: boolean;
      Wednesday: boolean;
      Tuesday: boolean;
      Monday: boolean;
    };
  } | null;
  staticAlarm1?: StaticAlarmSetting | null;
  staticAlarm2?: StaticAlarmSetting | null;
  staticAlarm3?: StaticAlarmSetting | null;
};

type SettingsRow = {
  label: string;
  value: (
    settings: CommandSettings,
    command: { timeZone?: string | null },
  ) => string | undefined;
};

const rows: SettingsRow[] = [
  {
    label: 'Status Change',
    value: ({ deactivate }) => (deactivate ? 'Deactivate' : undefined),
  },
  {
    label: 'Redirect',
    value: ({ host, port }) => (host || port ? `${host}:${port}` : undefined),
  },
  {
    label: 'Ultrasonic',
    value: ({ ultrasonic }) => ultrasonic || undefined,
  },
  {
    label: 'Schedule',
    value: ({ schedule }, { timeZone }) =>
      schedule ? displaySchedule({ ...schedule, timeZone }) : undefined,
  },
  {
    label: 'Static Alarm 1',
    value: ({ staticAlarm1 }) =>
      staticAlarm1 ? displayStaticAlarm(staticAlarm1) : undefined,
  },
  {
    label: 'Static Alarm 2',
    value: ({ staticAlarm2 }) =>
      staticAlarm2 ? displayStaticAlarm(staticAlarm2) : undefined,
  },
  {
    label: 'Static Alarm 3',
    value: ({ staticAlarm3 }) =>
      staticAlarm3 ? displayStaticAlarm(staticAlarm3) : undefined,
  },
];

export type CommandInfoProps = SettingsInfoProps & {
  settings?: CommandSettings | null;
};

export const CommandInfo = ({
  settings,
  timeZone,
  ...options
}: CommandInfoProps) =>
  settings ? (
    <SettingsInfo
      {...options}
      key={options.updatedAt}
      detail={rows.map(({ label, value }) => [
        label,
        value(settings, { timeZone }),
      ])}
    />
  ) : (
    <Text>{specialCharacters.enDash}</Text>
  );
