import { Boundary } from '@jl/components';
import { Title, Center, Subtitle } from '@jl/assets';
import { useTitle } from '@jl/utils';

import Updates from './updates';
import ResponseTimes from './response-times';
import Analytics from './analytics';

const Status = () => {
  useTitle(['Status']);

  return (
    <Center>
      <Title>Tank Updates</Title>
      <Subtitle>Last 7 Days</Subtitle>
      <Boundary spinner>
        <Updates />
      </Boundary>
      <Title>Response Times</Title>
      <Subtitle>Last 7 Days</Subtitle>
      <Boundary spinner>
        <ResponseTimes />
      </Boundary>
      <Title>Analytics</Title>
      <Subtitle>Last 28 Days</Subtitle>
      <Boundary spinner>
        <Analytics />
      </Boundary>
    </Center>
  );
};

export default Status;
