import { useTankQuery } from '@jl/graphql-schema';
import { Header, Boundary } from '@jl/components';
import { apiLogo } from '@jl/assets';
import {
  NotFoundError,
  useTitle,
  isAdmin,
  editButton,
  maybeAddNamespace,
  linkify,
} from '@jl/utils';

import { useParams, useHistory, useAuth } from '../../hooks';
import TankView from './view';
import TankForm from './form';

const TankDisplay = () => {
  const { uri, id, edit } = useParams(['uri', 'id', 'edit']);
  const [user] = useAuth();
  const { goBack, replace, push } = useHistory();

  const tankId = maybeAddNamespace('tank', { uri, id });
  const { data } = useTankQuery({
    variables: { id: tankId || 'UNSET' },
  });

  const tank = data?.tank;
  if (!tank) throw new NotFoundError('Tank', tankId || '');
  useTitle([tank.label, 'Tanks']);

  const Display = edit ? TankForm : TankView;
  return (
    <>
      <Header
        title={tank.label}
        subtitle={[tank.account.label, tank.account.detail]
          .filter(Boolean)
          .join(' | ')}
        onSubtitleClick={
          isAdmin(user) ? () => push(linkify(tank.account.id)) : undefined
        }
        image={apiLogo(tank.account.api)}
        leftButton={{
          icon: 'arrowLeft',
          style: 'standard',
          onClick: goBack,
        }}
        rightButtons={[editButton({ edit, replace, id: tankId })]}
      />
      <Display tank={tank} />
    </>
  );
};

const Tank = () => (
  <Boundary spinner>
    <TankDisplay />
  </Boundary>
);

export default Tank;
