import { Row } from '@jl/assets';
import { Boundary, FilterForm } from '@jl/components';
import { useEvent, useTitle } from '@jl/utils';

import { defaultFilters, GaugeFilter } from './filters';
import { GaugeList } from './list';

const Gauges = () => {
  useTitle(['Gauges']);
  useEvent({
    category: 'Gauges',
    action: 'view',
  });

  return (
    <FilterForm name="gauge" defaults={defaultFilters}>
      <Row>
        <GaugeFilter />
        <Boundary spinner>
          <GaugeList />
        </Boundary>
      </Row>
    </FilterForm>
  );
};

export default Gauges;
