import { GaugeResult, rangeToLabel } from '@jl/graphql-schema';
import { Display, StrappingChart } from '@jl/components';
import { Container, Row } from '@jl/assets';
import {
  MemoizedCalculations,
  displaySchedule,
  specialCharacters,
  useMemoizedCalculations,
} from '@jl/utils';

type ViewProps = {
  latest: GaugeResult['latest'];
  setup: GaugeResult['setup'];
  calculated: MemoizedCalculations | null | undefined;
};

const ADCView = ({ latest, setup, calculated }: ViewProps) => (
  <>
    <Row>
      <Display
        label="Reading"
        text={`${latest?.digital || specialCharacters.enDash} B`}
        size="subCompact"
      />
      <Display
        label="Probe Settings"
        text={
          setup?.range ? rangeToLabel(setup.range) : specialCharacters.enDash
        }
      />
    </Row>
    <Row>
      <Display
        label="Reported Pressure"
        text={calculated?.calculatedPressure}
        size="compact"
      />
      <Display
        label="Specific Gravity"
        text={`${setup?.probe_sg || specialCharacters.enDash} : ${
          setup?.actual_sg || specialCharacters.enDash
        }`}
        size="compact"
      />
      <Display
        label="Pressure Offset"
        text={`${setup?.offset_mm || 0} mm`}
        size="compact"
      />
      <Display
        label="Calculated Pressure"
        text={calculated?.calculatedFill || specialCharacters.enDash}
        size="compact"
      />
    </Row>
  </>
);

const PressureView = ({ latest, setup, calculated }: ViewProps) => (
  <Row>
    <Display
      label="Reported Pressure"
      text={`${latest?.pressure || specialCharacters.enDash} mm`}
      size="compact"
    />
    <Display
      label="Specific Gravity"
      text={`${setup?.probe_sg || specialCharacters.enDash} : ${
        setup?.actual_sg || specialCharacters.enDash
      }`}
      size="compact"
    />
    <Display
      label="Pressure Offset"
      text={`${setup?.offset_mm || 0} mm`}
      size="compact"
    />
    <Display
      label="Calculated Pressure"
      text={calculated?.calculatedFill || specialCharacters.enDash}
      size="compact"
    />
  </Row>
);

const DistanceView = ({ latest, setup, calculated }: ViewProps) => (
  <Row>
    <Display
      label="Reported Distance To Fluid"
      text={`${latest?.distance || specialCharacters.enDash} mm`}
      size="compact"
    />
    <Display
      label="Distance From Mount To Bottom"
      text={`${setup?.offset_mm || specialCharacters.enDash} mm`}
      size="compact"
    />
    <Display
      label="Calculated Liquid Height"
      text={calculated?.calculatedFill || specialCharacters.enDash}
      size="compact"
    />
    <Display
      label="Percent of SFL"
      text={calculated?.percentFill || specialCharacters.enDash}
      size="compact"
    />
  </Row>
);

const noop = () => null;

const viewByType = {
  ADC: ADCView,
  Pressure: PressureView,
  TemperatureAndPressure: PressureView,
  Ultrasonic: DistanceView,
  Temperature: noop,
};

export const GaugeView = ({
  gauge: { model, notes, ultrasonic, schedule, timeZone, type, setup, latest },
}: {
  gauge: GaugeResult;
}) => {
  const calculated =
    latest && setup && useMemoizedCalculations(latest, type)(setup);

  const View = type ? viewByType[type] : noop;

  return (
    <Container small>
      <Row>
        <Display
          label="Notes"
          text={notes || specialCharacters.nbsp}
          preformatted
        />
      </Row>
      <Row>
        <Display
          label="Ultrasonic Measurement"
          text={ultrasonic || specialCharacters.enDash}
        />
      </Row>
      <Row>
        <Display
          label="Schedule"
          text={
            schedule
              ? displaySchedule({ ...schedule, timeZone })
              : specialCharacters.enDash
          }
        />
      </Row>
      <Row>
        <Display label="Tank Model" text={model || specialCharacters.enDash} />
      </Row>
      <View latest={latest} setup={setup} calculated={calculated} />
      <Row>
        <Display
          label="Reported Volume"
          text={calculated?.reportedVolume || specialCharacters.enDash}
          size="compact"
        />
        <Display
          label="Volume Offset"
          text={`${setup?.offset_litres || '0'} L`}
          size="compact"
        />
        <Display
          label="Displayed Volume"
          text={calculated?.calculatedVolume || specialCharacters.enDash}
          size="compact"
        />
        <Display
          label="Percent of SFL"
          text={calculated?.percentVolume || specialCharacters.enDash}
          size="compact"
        />
      </Row>
      {setup && calculated ? (
        <StrappingChart data={setup.strapping} current={calculated.line} />
      ) : undefined}
    </Container>
  );
};
