import { ZodSchema } from 'zod';

export const isArray = Array.isArray;
export const isNumber = (e: unknown): e is number => typeof e === 'number';
export const isString = (e: unknown): e is string => typeof e === 'string';
export const isBoolean = (e: unknown): e is boolean => typeof e === 'boolean';

export const isTypedArray =
  <T>(guard: (e: unknown) => e is T) =>
  (es: unknown): es is T[] =>
    isArray(es) && es.filter(guard).length === es.length;

export const isStringArray = isTypedArray(isString);
export const isNumberArray = isTypedArray(isNumber);

export const isNullOrUndefined = (e: unknown): e is null | undefined =>
  e === null || e === undefined;
export const isNotNullOrUndefined = <T>(e: T | null | undefined): e is T =>
  e !== null && e !== undefined;

export const isOrUndefined =
  <T>(guard: (e: unknown) => e is T) =>
  (eOr: unknown): eOr is T | undefined =>
    guard(eOr) || eOr === undefined;

export const isStringOrUndefined = isOrUndefined(isString);
export const isBooleanOrUndefined = isOrUndefined(isBoolean);

export const toGuard =
  <E>(schema: ZodSchema<E>) =>
  (e: unknown): e is E =>
    schema.safeParse(e).success;
