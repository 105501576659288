import { useMemo } from 'react';

import { Text } from '@jl/assets';

import { useValues } from './use-form';
import { Label } from './label';
import { BoxSize } from './label.styles';

export type ComputeProps<V, R, K extends keyof R> = {
  label: string;
  size?: BoxSize;
  calculate: (values: V) => R;
  resultKey?: K;
  children?: (values: V, result: R) => JSX.Element;
};

export const Compute = <V, R, K extends keyof R>({
  label,
  size,
  calculate,
  children,
  resultKey,
}: ComputeProps<V, R, K>) => {
  const values = useValues<V>();

  return useMemo(() => {
    const result = calculate(values);
    return (
      <Label text={label} size={size}>
        {children ? (
          children(values, result)
        ) : (
          <Text>{`${resultKey ? result[resultKey] : result}`}</Text>
        )}
      </Label>
    );
  }, [values, label, resultKey]);
};
