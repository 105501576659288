import { loadConfig } from './load-config';

const location =
  typeof window === 'object' ? window.location : { hostname: '', origin: '' };

export const getAuthConfig = async () => {
  const host = process.env.AUTH_HOST || `auth.${location.hostname}`;
  const clientId = await loadConfig('auth_client_id');
  return {
    host,
    clientId,
    userInfoUrl: `https://${host}/userinfo`,
    resetPasswordUrl: `https://${host}/dbconnections/change_password`,
    logoutUrl: `https://${host}/v2/logout?returnTo=${location.origin}&client_id=${clientId}`,
  };
};

export const buildAuthorizeUrl = async (
  {
    response_mode,
    prompt,
    action,
    nonce,
  }: {
    response_mode: 'web_message' | undefined;
    prompt: 'none' | undefined;
    action: 'verification' | undefined;
    nonce: string | undefined;
  } = {
    response_mode: undefined,
    prompt: undefined,
    action: undefined,
    nonce: undefined,
  },
) => {
  const config = await getAuthConfig();
  const params = new URLSearchParams({
    client_id: config.clientId,
    redirect_uri: new URL('callback', window.location.origin).toString(),
    audience: 'joylevel.io',
    response_type: 'token id_token',
    scope: 'openid email',
    nonce:
      nonce || window.crypto.getRandomValues(new Uint32Array(1))[0].toString(),
  });
  if (response_mode) params.set('response_mode', response_mode);
  if (action) params.set('action', action);
  if (prompt) params.set('prompt', prompt);

  return `https://${config.host}/authorize?${params.toString()}`;
};
