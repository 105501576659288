import { linkify } from '@jl/utils';

import { Navigate, useParams } from '../hooks';
import { NotFound } from './not-found';

export const Lookup = () => {
  const { uri } = useParams(['uri']);
  const path = uri && linkify(uri);

  return path ? <Navigate to={path} replace /> : <NotFound />;
};
