import transparentize from 'polished/lib/color/transparentize';
import hideVisually from 'polished/lib/mixins/hideVisually';

import { styled } from '@jl/assets';

export const RadioLabel = styled.label<{ icon?: boolean; checked?: boolean }>`
  display: block;
  margin: 4px 4px 8px;
  padding: ${(props) => (props.icon ? '8px' : '8px 4px 0')};

  position: relative;
  width: auto;
  cursor: pointer;

  background: ${(props) =>
    props.icon && props.checked
      ? transparentize(0.6, props.theme.borderPale)
      : 'none'};
  border-radius: 3px;

  input + svg {
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  input:checked + svg {
    opacity: 1;
  }
`;

export const RadioText = styled.span<{ hide?: boolean }>`
  font-size: 1.6rem;
  line-height: 1.4;
  margin-left: 8px;
  vertical-align: bottom;

  ${(props) => props.hide && hideVisually()};
`;
