import {
  GaugeResult,
  gaugeResetActivationProps,
  useResetGaugeActivationMutation,
} from '@jl/graphql-schema';
import { Display } from '@jl/components';
import { Container, Row } from '@jl/assets';
import {
  formatPossibleTime,
  formatTime,
  isSetupUser,
  specialCharacters,
} from '@jl/utils';

import { useAuth } from '../../hooks';

export const GaugeBasics = ({ gauge }: { gauge: GaugeResult }) => {
  const [user] = useAuth();
  const [onReset, reset] = useResetGaugeActivationMutation();

  const { latest, activatedAt, updatedAt, updatedBy } = gauge;
  if (!latest) {
    return null;
  }

  const { time, voltage, temperature, signal } = latest;

  return (
    <Container small>
      <Row>
        <Display
          label="Activated At"
          action={
            activatedAt && isSetupUser(user)
              ? [
                  {
                    text: 'Clear Activation',
                    onClick: () => onReset(gaugeResetActivationProps(gauge)),
                    disabled: reset.loading,
                  },
                ]
              : undefined
          }
          text={formatPossibleTime(activatedAt)}
        />
        <Display label="Last Reported" text={formatTime(time)} />
      </Row>
      <Row>
        <Display
          label="Last Updated"
          text={
            [formatPossibleTime(updatedAt), updatedBy?.email]
              .filter(Boolean)
              .join(' by ') || specialCharacters.enDash
          }
        />
      </Row>
      <Row>
        <Display
          size="compact"
          label="Signal Strength"
          icon="signal"
          text={`${
            typeof signal === 'number'
              ? ((signal / 31) * 100).toFixed()
              : specialCharacters.enDash
          }%`}
        />
        <Display
          size="compact"
          label="Temperature"
          icon="thermometer"
          text={`${temperature || specialCharacters.enDash}${
            specialCharacters.degree
          }C`}
        />
        <Display
          size="compact"
          label="Voltage"
          icon="carBattery"
          text={`${voltage}%`}
        />
      </Row>
    </Container>
  );
};
