import { useMemo } from 'react';

import { IconName } from '@jl/assets';
import { specialCharacters } from '@jl/utils';
import { Icon } from './icon';
import { InfoListStatus, Item, List } from './info-list.styles';

type InfoListItem<V extends string | number | null | undefined> = {
  text: V;
  unit?: string;
  icon?: IconName;
  strong?: boolean;
  hidden?: boolean;
  status?: (value: V) => InfoListStatus | undefined;
};

export type InfoListProps<
  I extends InfoListItem<string | number | null | undefined>[],
> = {
  items: I;
};

export const InfoList = <
  I extends InfoListItem<string | number | null | undefined>[],
>({
  items,
}: InfoListProps<I>) => (
  <List>
    {items
      .filter((i) => !i.hidden)
      .map(({ text, unit, icon, strong, status }) => (
        <Item
          strong={strong}
          status={useMemo(() => status && status(text), [text])}
          key={text}
        >
          {icon && <Icon name={icon} size={12} />}{' '}
          {text || specialCharacters.enDash}
          {unit}
        </Item>
      ))}
  </List>
);
