import { downloadFile } from './download-file';

export const wrapCell = (value: unknown) => {
  const asString = `${value ?? ''}`.replaceAll('"', '""');
  return asString.includes(',') ? `"${asString}"` : asString;
};

const QUOTE_REPLACEMENT = '----|||QUOTE|||----';
const COMMA_REPLACEMENT = '----|||COMMA|||----';

export const unwrapCell = <V>(
  value: string,
  parse: V extends string ? undefined : (value: string) => V,
): V => {
  const unwrapped = (value || '')
    .replace(/^"(.+)"$/, '$1')
    .replaceAll(QUOTE_REPLACEMENT, '"')
    .replaceAll(COMMA_REPLACEMENT, ',');

  return parse ? parse(unwrapped) : (unwrapped as V);
};

export type CSVFieldParser<I extends object, K extends keyof I = keyof I> = {
  [k in K]-?: I[k] extends string ? undefined : (val: string) => I[k];
};

export type CSVOptions<I extends object, K extends keyof I = keyof I> = {
  cells: K[];
  headers: Record<K, string>;
  visibility?: Partial<Record<K, boolean>>;
};

export const toCSV = <I extends object, K extends keyof I>(
  items: I[],
  { cells, headers, visibility }: CSVOptions<I, K>,
): string => {
  const visibleCells = cells.filter((c) => visibility?.[c] !== false);

  const rows = [
    visibleCells.map((cell) => wrapCell(headers[cell])),
    ...(items || []).map((row) =>
      visibleCells.map((cell) => wrapCell(row[cell])),
    ),
  ];
  return rows.map((r) => r.join(',')).join('\r\n');
};

export const downloadCSV = <I extends object, K extends keyof I>(
  items: I[],
  { file, ...options }: CSVOptions<I, K> & { file: string },
) => {
  const asBlob = new Blob([toCSV(items, options)], { type: 'text/csv' });
  downloadFile(asBlob, `${file}.csv`);
};

export const fromCSV = <I extends object, K extends keyof I = keyof I>(
  csv: string,
  { cells, headers, parse }: CSVOptions<I, K> & { parse: CSVFieldParser<I, K> },
): Partial<I>[] => {
  const rows = csv
    .trim()
    .replaceAll('""', QUOTE_REPLACEMENT)
    .replace(/"[^"]+"/g, (v) => v.replace(/,/g, COMMA_REPLACEMENT))
    .split(/\r?\n/)
    .map((r) => r.trim().split(','));

  const headerRow = rows.shift()?.map((h) => unwrapCell<string>(h, undefined));
  if (!headerRow) return [];

  const indexes = cells.map((k) => [k, headerRow.indexOf(headers[k])] as const);

  return rows.map(
    (row) =>
      Object.fromEntries(
        indexes.map(([key, index]) => [
          key,
          index >= 0 ? unwrapCell(row[index], parse[key]) : undefined,
        ]),
      ) as I,
  );
};
