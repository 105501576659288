import { Title, Subtitle, Container } from '@jl/assets';

import { Border, Display, Image, Text } from './header.styles';
import { Button, ButtonProps } from './button';
import { isNotNullOrUndefined } from '@jl/utils';

export interface HeaderProps {
  title?: string;
  subtitle?: string;
  onSubtitleClick?: () => void;
  leftButton?: Pick<ButtonProps, 'icon' | 'style' | 'onClick'>;
  rightButtons?: (
    | (Pick<ButtonProps, 'icon' | 'style' | 'onClick'> & {
        key: string;
      })
    | undefined
  )[];
  image?: string;
  link?: string;
}

export const Header = ({
  title,
  subtitle,
  onSubtitleClick,
  leftButton,
  rightButtons,
  image,
}: HeaderProps) => (
  <Container>
    <Border>
      {leftButton && <Button {...leftButton} />}
      <Display>
        {image && <Image src={image} />}
        <Text>
          {subtitle && (
            <Subtitle onClick={onSubtitleClick}>{subtitle}</Subtitle>
          )}
          {title && <Title>{title}</Title>}
        </Text>
      </Display>
      {rightButtons && rightButtons.filter(isNotNullOrUndefined).map(Button)}
    </Border>
  </Container>
);
