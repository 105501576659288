import { Component, ReactNode, Suspense } from 'react';

import { Spinner } from './spinner';
import { ErrorDisplay } from './error-display';

export interface BoundaryProps {
  spinner?: boolean;
  small?: boolean;
  onError?: typeof ErrorDisplay;
  children: ReactNode;
}

class ErrorBoundary extends Component<{
  small?: boolean;
  fallback: typeof ErrorDisplay;
  children: ReactNode;
}> {
  static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  state: { error: Error | null } = { error: null };

  render() {
    if (this.state && this.state.error) {
      return this.props.fallback({
        small: this.props.small,
        error: this.state.error,
      });
    }

    return this.props.children;
  }
}

export const Boundary = ({
  spinner,
  small,
  children,
  onError,
}: BoundaryProps) => (
  <Suspense fallback={spinner ? <Spinner padded small={small} /> : <></>}>
    <ErrorBoundary small={small} fallback={onError || ErrorDisplay}>
      {children}
    </ErrorBoundary>
  </Suspense>
);
