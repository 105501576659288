import {
  ChangeEvent,
  Context,
  createContext,
  FormEvent,
  useContext,
  useReducer,
} from 'react';

export interface FormState<V> {
  values: V;
  errors: Partial<Record<keyof V, string>>;
  status?: string;
}
export interface FormProps<V> {
  initialValues: V;
  onSubmit?: (values: V) => void;
  onChange?: (values: V) => void;
}
export interface FormContext<V> {
  state: FormState<V>;
  setValue: <K extends keyof V>(name: K, value: V[K]) => void;
  setError: <K extends keyof V>(name: K, value: string | undefined) => void;
  setStatus: (status: string) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}
type FormAction<V> =
  | { type: 'STATUS'; data: [string] }
  | { type: 'VALUE'; data: [keyof V, V[keyof V]] }
  | { type: 'ERROR'; data: [keyof V, string | undefined] };

export const formContext = createContext<FormContext<{ [k: string]: unknown }>>(
  {
    state: {
      values: {},
      errors: {},
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setValue: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setError: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStatus: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSubmit: () => {},
  },
);
formContext.displayName = 'FormContext';
export const FormProvider = formContext.Provider;

export const useFormProvider = <V>({
  initialValues,
  onSubmit,
  onChange,
}: FormProps<V>) => {
  const [state, dispatch] = useReducer(
    (state: FormState<V>, action: FormAction<V>): FormState<V> => {
      if (action.type === 'STATUS') {
        return { ...state, status: action.data[0] };
      }
      if (action.type === 'VALUE') {
        const newValues = { ...state.values, [action.data[0]]: action.data[1] };
        if (onChange) onChange(newValues);
        return {
          ...state,
          values: newValues,
        };
      }
      if (action.type === 'ERROR') {
        return {
          ...state,
          errors: { ...state.errors, [action.data[0]]: action.data[1] },
        };
      }
      return state;
    },
    {
      values: initialValues,
      errors: {},
    },
  );

  const value: FormContext<V> = {
    state,
    setStatus: (...data) => dispatch({ type: 'STATUS', data }),
    setValue: (...data) => dispatch({ type: 'VALUE', data }),
    setError: (...data) => dispatch({ type: 'ERROR', data }),
    handleSubmit: (e) => {
      e.preventDefault();
      if (onSubmit) onSubmit(state.values);
    },
  };

  return {
    value,
    Provider: (formContext as unknown as Context<FormContext<V>>).Provider,
  };
};

export const useForm = () => useContext(formContext);

export const useValues = <V>() => {
  const context = useForm() as unknown as FormContext<V>;
  return context.state.values;
};

export type UseFieldOptions<V> = {
  guard?: (e: unknown) => e is V;
  default?: V;
};

export const useField = <V>(name: string, opts?: UseFieldOptions<V>) => {
  const context = useForm();
  const value = context.state.values[name];

  const guard = opts && opts.guard;
  const optDefault = opts && opts.default;

  const returnValue: V | undefined =
    (!guard || guard(value) ? (value as V) : undefined) ?? optDefault;
  return {
    value: returnValue,
    error: context.state.errors[name],
    setValue: (newValue: V) =>
      (!guard || guard(newValue)) && context.setValue(name, newValue),
    setError: context.setError,
    setStatus: context.setStatus,
    handleSubmit: context.handleSubmit,
    onChange: (
      e: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      switch (e.currentTarget.type) {
        case 'checkbox': {
          context.setValue(name, (e.currentTarget as HTMLInputElement).checked);
          break;
        }
        case 'number': {
          if (e.currentTarget.value === '') {
            context.setValue(name, undefined);
          } else {
            const num = parseFloat(e.currentTarget.value);
            if (!Number.isNaN(num)) context.setValue(name, num);
          }
          break;
        }
        default: {
          context.setValue(name, e.currentTarget.value);
          break;
        }
      }
    },
  };
};
