import { styled } from '@jl/assets';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
`;
