import { ButtonHTMLAttributes } from 'react';

import { IconName, useTheme } from '@jl/assets';

import { Border, Text, TButtonScheme, buttonScheme } from './button.styles';
import { Icon } from './icon';

export type ButtonProps = {
  name?: string;
  value?: string;
  text?: string;
  icon?: IconName;
  size?: number;
  style?: TButtonScheme;
  outline?: boolean;
  full?: boolean;
  link?: boolean;
  centre?: boolean;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'disabled' | 'onClick'
>;

export const Button = ({
  text,
  icon,
  size,
  style,
  outline,
  disabled,
  ...rest
}: ButtonProps) => {
  const contentSize = size || 20;
  const scheme = style || 'default';
  const styleObject = buttonScheme({ scheme, disabled, theme: useTheme() });

  return (
    <Border
      type="button"
      outline={outline}
      square={!text}
      aria-label={rest.value}
      ratio={contentSize / 20}
      scheme={scheme}
      disabled={disabled}
      {...rest}
    >
      {icon && (
        <Icon
          name={icon}
          size={contentSize}
          colour={outline ? styleObject.background : styleObject.foreground}
        />
      )}
      {text && (
        <Text size={contentSize} spaced={!!icon && !!text}>
          {text}
        </Text>
      )}
    </Border>
  );
};
