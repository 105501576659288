import { hideVisually } from 'polished';

import { styled } from '@jl/assets';

export const SForm = styled.form`
  margin: 10px auto;
  max-width: 100%;
  min-width: 80%;
`;

export const Area = styled.textarea`
  align-self: stretch;
  flex: 1 1 380px;
  margin: 0;
  padding: 3px 5px 5px 2px;
  width: 100%;

  font-size: 1.6rem;
  line-height: 1.4;

  background: none;
  border: 0;
  border-radius: 0;
  resize: none;

  &:disabled {
    color: ${(props) => props.theme.disabled};
    font-style: italic;
  }
  &:focus {
    outline: none;
  }
  &:focus + hr {
    border-color: ${(props) => props.theme.action};
  }
`;

export const Field = styled.input`
  flex: 1;
  margin: 0;
  padding: 3px 2px 5px;
  width: 100%;

  font-size: 1.6rem;
  line-height: 1.4;

  background: none;
  border: 0;
  border-radius: 0;

  &:disabled {
    color: ${(props) => props.theme.disabled};
    font-style: italic;
  }
  &:focus {
    outline: none;
  }
  &:focus + hr {
    border-color: ${(props) => props.theme.action};
  }
`;

export const Hidden = styled.input`
  ${hideVisually()};
`;

export const FieldSet = styled.fieldset`
  position: relative;

  border: 1px solid ${(props) => props.theme.borderPale};
  border-radius: 3px;
  margin: 2rem 0;
  padding: 5px;
`;

export const Legend = styled.legend`
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 300;
  margin-left: 10px;
  padding: 5px;
`;

export const FieldSetBottomRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`;

export const FieldSetHint = styled.div`
  padding-right: 5px;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 8px;
  bottom: 13px;

  display: flex;
  align-items: center;
  padding: 0.2rem;
  border-radius: 100%;
  border: 0;
  background-color: ${(props) => props.theme.disabled};

  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
