import { useState, useEffect } from 'react';
import {
  formatDistance,
  formatRelative,
  differenceInDays,
  format,
} from 'date-fns';

import { specialCharacters } from './special-characters';

const formatDistanceLocale: { [key: string]: string } = {
  aboutXHours: '{{count}}h',
  aboutXMonths: '{{count}}M',
  aboutXYears: '{{count}}y',
  almostXYears: '{{count}}y',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  lessThanXSeconds: '{{count}}s',
  overXYears: '{{count}}y',
  xDays: '{{count}}d',
  xHours: '{{count}}h',
  xMinutes: '{{count}}m',
  xMonths: '{{count}}M',
  xSeconds: '{{count}}s',
  xYears: '{{count}}y',
};

export const locale = {
  formatDistance: (token: string, count: number) =>
    (formatDistanceLocale[token] || '').replace('{{count}}', `${count}`),
};

type TimeOptions = {
  end?: Date;
  short?: boolean;
  dateOnly?: boolean;
};

export function formatTime(
  start: Date | number | string,
  options?: TimeOptions,
) {
  try {
    if (!start) throw new Error(`start undefined - ${start}`);
    let startDate;
    if (typeof start === 'string' && start.match(/^\d+$/)) {
      startDate = parseInt(start, 10);
    } else if (typeof start === 'string') {
      startDate = new Date(start);
    } else {
      startDate = start;
    }

    const compare = (options && options.end) || Date.now();

    if (options && options.short) {
      return formatDistance(startDate, compare, { locale });
    }

    const days = differenceInDays(startDate, compare);
    if (days <= 6 && days >= -6) {
      const relative = formatRelative(startDate, compare);
      return `${relative.charAt(0).toLocaleUpperCase()}${relative.slice(
        1,
      )}`.replace(/ [ap]m$/i, (m) => m.toLocaleLowerCase().trim());
    } else {
      return format(
        startDate,
        `MMM d, y${options?.dateOnly ? '' : " 'at' h:mmaaaaa'm'"}`,
      );
    }
  } catch {
    return specialCharacters.infinity;
  }
}

export function formatPossibleTime(
  start: Date | number | string | undefined | null,
  options?: TimeOptions,
) {
  if (!start) return specialCharacters.enDash;
  return formatTime(start, options);
}

export function useShortTime(t: Date | number | string | undefined) {
  const [display, setDisplay] = useState<string>();
  useEffect(() => {
    const set = () =>
      setDisplay(t ? formatTime(t, { short: true }) : specialCharacters.enDash);
    set();

    const interval = setInterval(set, 60000);
    return () => clearInterval(interval);
  }, [t]);
  return display;
}
