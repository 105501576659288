import { isBoolean } from '@jl/utils';

import { useField } from './use-form';
import { Label } from './label';
import { BoxSize } from './label.styles';
import { Hidden } from './input.styles';
import { SwitchToggle } from './switch.styles';

export interface SwitchProps {
  name: string;
  label: string;
  size?: BoxSize;
}

export const Switch = ({ name, label, size }: SwitchProps) => {
  const { value, ...inputProps } = useField(name, {
    guard: isBoolean,
    default: false,
  });

  return (
    <Label text={label} size={size}>
      <Hidden type="checkbox" {...inputProps} checked={value} />
      <SwitchToggle size={20} />
    </Label>
  );
};
