import { colourArray, useTheme } from '@jl/assets';

import { Border, Text } from './avatar.styles';

const colours = colourArray('r500');
const mod = (dividend: number, divisor: number) =>
  ((dividend % divisor) + divisor) % divisor;
const hashCode = (fromString = '') =>
  // tslint:disable-next-line:no-bitwise
  fromString
    .split('')
    .reduce((hash, letter) => letter.charCodeAt(0) + ((hash << 5) - hash), 0);
const getColour = (fromString: string, fallback: string) =>
  (fromString && colours[mod(hashCode(fromString), colours.length)]) ||
  fallback;

export interface AvatarProps {
  text: string;
  onClick?: () => void;
}

export const Avatar = ({ text, onClick }: AvatarProps) => {
  const theme = useTheme();
  return (
    <Border onClick={onClick} colour={getColour(text, theme.border)}>
      <Text>{text[0]}</Text>
    </Border>
  );
};
