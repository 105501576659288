import { Header } from '@jl/components';
import { useEvent } from '@jl/utils';

import { useHistory } from '../../hooks';
import AccountForm from './form';

const NewAccount = () => {
  useEvent({
    category: 'Account',
    action: 'new',
  });
  const { goBack } = useHistory();
  return (
    <>
      <Header
        title="New Account"
        leftButton={{
          icon: 'arrowLeft',
          style: 'standard',
          onClick: goBack,
        }}
      />
      <AccountForm
        account={{
          __typename: 'Account',
          id: `jl:account:${crypto.randomUUID()}`,
          label: '',
          detail: '',
          api: 'RMSDelta',

          reference: '',
          secret: '',
          url: '',
          timezone: 'Australia/Sydney',
          serialNumbers: [],

          roles: [],
          tanks: [],
          gauges: [],
        }}
      />
    </>
  );
};

export default NewAccount;
