import { useCallback, useEffect } from 'react';

import { useTankHistoryLazyQuery } from '@jl/graphql-schema';
import { downloadCSV, isAdmin } from '@jl/utils';
import { Button, Form, Input, Modal, Spinner } from '@jl/components';
import { Row } from '@jl/assets';
import { useAuth } from '../../hooks';

const TankDownload = ({
  id,
  label,
  visible,
  setVisible,
}: {
  id: string;
  label: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}) => {
  const [user] = useAuth();
  const [fetchHistory, historyResult] = useTankHistoryLazyQuery();

  const canModify = isAdmin(user);

  const downloadHistory = useCallback(
    async (opts?: { days?: number }) => {
      const { data: history } = await fetchHistory({
        variables: { id, days: opts?.days || 120 },
      });
      downloadCSV(history?.tankHistory?.levels || [], {
        cells: [
          'time',
          'value',
          'maximum',
          'percent',
          'voltage',
          ...(canModify ? (['rssi', 'src'] as const) : []),
        ],
        headers: {
          time: 'Time',
          value: 'Level',
          maximum: 'SFL',
          percent: 'Level Percent',
          voltage: 'Battery Percent',
          rssi: 'RSSI',
          src: 'SRC',
        },
        file: `${label.replace(/ /g, '-').toLocaleLowerCase()}-history`,
      });
      setVisible(false);
    },
    [fetchHistory],
  );

  useEffect(() => {
    if (visible && !canModify) {
      downloadHistory().catch(console.error);
    }
  }, [visible, canModify]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title={`Download CSV for ${label}`}
      hideClose
      internal
    >
      {historyResult.loading || !canModify ? (
        <Spinner small fullScreen />
      ) : (
        <Form initialValues={{ days: 120 }} onSubmit={downloadHistory}>
          <Input name="days" label="How Many Days?" type="number" />
          <Row>
            <Button
              size={16}
              icon="download"
              text="Download"
              type="submit"
              outline
            />
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default TankDownload;
