import Fuse from 'fuse.js';
import { isNotNullOrUndefined } from '.';

import { isNullOrUndefined } from './guards';

export enum SortDirection {
  ASCENDING = 1,
  DESCENDING = -1,
}

export type SortOptions<T> = {
  sort: (i: T) => string | number | undefined;
  order: SortDirection;
};

export type FilterOptions = {
  search: string | undefined;
  keys: string[];
};

export type MapOptions<T, R> = {
  map: (i: T) => R | undefined;
};

export const sort = <T>(items: T[], filters: SortOptions<T>) =>
  items.slice(0).sort((a, b) => {
    const aSort = filters.sort(a);
    const bSort = filters.sort(b);

    let sortValue = 1;

    if (isNullOrUndefined(aSort)) {
      sortValue = filters.order;
    } else if (isNullOrUndefined(bSort)) {
      sortValue = filters.order * -1;
    } else if (typeof aSort === 'string' && typeof bSort === 'string') {
      sortValue = aSort.localeCompare(bSort);
    } else if (typeof aSort === 'number' && typeof bSort === 'number') {
      sortValue = aSort - bSort;
    }

    return sortValue * filters.order;
  });

export const filter = <T>(items: T[], filters: FilterOptions) => {
  if (!filters.search) return items;

  const fuse = new Fuse(items, {
    distance: 100,
    keys: filters.keys,
    location: 0,
    shouldSort: false,
    threshold: 0.2,
  });

  return fuse.search(filters.search).map((r) => r.item);
};

export const map = <T, R>(items: T[], filters: MapOptions<T, R>) =>
  items.map(filters.map).filter(isNotNullOrUndefined);

export const sortAndMap = <T, R>(
  items: T[],
  filters: SortOptions<T> & MapOptions<T, R>,
) => map(sort(items, filters), filters);

export const sortAndFilter = <T>(
  items: T[],
  filters: SortOptions<T> & FilterOptions,
) => sort(filter(items, filters), filters);

export const sortAndFilterAndMap = <T, R>(
  items: T[],
  filters: SortOptions<T> & FilterOptions & MapOptions<T, R>,
) => map(sort(filter(items, filters), filters), filters);
