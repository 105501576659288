import { DataProxy } from '@apollo/client';

import { TankInput, TankQuery, TankDocument } from '../hooks';
import { optimisticMutation } from './general';

export type TankResult = NonNullable<TankQuery['tank']>;

export const tankValues = (v: TankResult): TankInput => ({
  label: v.label,
  unit: v.unit,
  contents: v.contents,
  warningLevels: v.warningLevels || [],
  warningEmails: v.warningEmails || [],
  billingStartedAt: v.billingStartedAt?.split('T')[0] || undefined,
  location: v.location
    ? {
        latitude: v.location.latitude,
        longitude: v.location.longitude,
        display: v.location.display,
      }
    : undefined,
});

export const tankUpdateProps = (t: TankResult, v: TankInput) => ({
  optimisticResponse: {
    updateTank: optimisticMutation,
  },
  variables: {
    id: t.id,
    accountId: t.accountId,
    fields: {
      label: v.label,
      unit: v.unit,
      contents: v.contents,
      warningLevels: v.warningLevels?.sort((a, b) => a - b),
      warningEmails: v.warningEmails,
      location: v.location,
      billingStartedAt:
        v.billingStartedAt && `${v.billingStartedAt}T12:00:00.000Z`,
    },
  },
  update: (proxy: DataProxy) => {
    const updated: TankQuery['tank'] = {
      ...t,
      label: v.label,
      unit: v.unit,
      contents: v.contents,
      warningLevels: v.warningLevels?.sort((a, b) => a - b),
      warningEmails: v.warningEmails,
      billingStartedAt:
        v.billingStartedAt && `${v.billingStartedAt}T12:00:00.000Z`,
      location: v.location
        ? {
            display: v.location.display,
            latitude: v.location.latitude,
            longitude: v.location.longitude,
            __typename: 'Location',
          }
        : null,
    };
    proxy.writeQuery<TankQuery>({
      query: TankDocument,
      data: { tank: updated },
      variables: { id: t.id, accountId: t.accountId },
    });
  },
});
