export const regularIcons = {
  'battery-bolt': [
    640,
    512,
    [],
    'f376',
    'M445.394 223.522L304.616 469.519c-3.522 6.654-9.943 10.481-16.623 10.481-12.266 0-21.553-12.557-18.677-25.843l36.847-166.382h-94.961c-11.6 0-20.566-11.186-19.031-23.775l25.597-213.775C219.04 39.792 227.177 32 236.8 32h108.8c12.604 0 21.8 13.087 18.552 26.411L336.458 192h92.321c14.785 0 24.011 17.55 16.615 31.522zM48 144h110.197l5.747-48H48c-26.51 0-48 21.49-48 48v224c0 26.51 21.49 48 48 48h180.604l10.63-48H48V144zm568 16h-8v-16c0-26.51-21.49-48-48-48H405.38l-9.951 48H560v64h32v96h-32v64H418.017l-27.469 48H560c26.51 0 48-21.49 48-48v-16h8c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24z',
  ],
  'battery-empty': [
    640,
    512,
    [],
    'f244',
    'M560 144v64h32v96h-32v64H48V144h512m0-48H48c-26.51 0-48 21.49-48 48v224c0 26.51 21.49 48 48 48h512c26.51 0 48-21.49 48-48v-16h8c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24h-8v-16c0-26.51-21.49-48-48-48z',
  ],
  'battery-full': [
    640,
    512,
    [],
    'f240',
    'M560 144v64h32v96h-32v64H48V144h512m0-48H48c-26.51 0-48 21.49-48 48v224c0 26.51 21.49 48 48 48h512c26.51 0 48-21.49 48-48v-16h8c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24h-8v-16c0-26.51-21.49-48-48-48zm-48 96H96v128h416V192z',
  ],
  'battery-half': [
    640,
    512,
    [],
    'f242',
    'M320 320H96V192h224v128zm240-176H48v224h512v-64h32v-96h-32v-64m0-48c26.51 0 48 21.49 48 48v16h8c13.255 0 24 10.745 24 24v144c0 13.255-10.745 24-24 24h-8v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V144c0-26.51 21.49-48 48-48h512z',
  ],
  'battery-quarter': [
    640,
    512,
    [],
    'f243',
    'M224 320H96V192h128v128zm336-176H48v224h512v-64h32v-96h-32v-64m0-48c26.51 0 48 21.49 48 48v16h8c13.255 0 24 10.745 24 24v144c0 13.255-10.745 24-24 24h-8v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V144c0-26.51 21.49-48 48-48h512z',
  ],
  'battery-slash': [
    640,
    512,
    [],
    'f377',
    'M36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM616 160h-8v-16a48 48 0 0 0-48-48H232.24l61.39 48H560v64h32v96h-32v48.25l44.18 34.53A47.74 47.74 0 0 0 608 368v-16h8a24 24 0 0 0 24-24V184a24 24 0 0 0-24-24zM48 368V134.74l-32.79-25.63A47.74 47.74 0 0 0 0 144v224a48 48 0 0 0 48 48h359.76l-61.39-48z',
  ],
  'battery-three-quarters': [
    640,
    512,
    [],
    'f241',
    'M416 320H96V192h320v128zm144-176H48v224h512v-64h32v-96h-32v-64m0-48c26.51 0 48 21.49 48 48v16h8c13.255 0 24 10.745 24 24v144c0 13.255-10.745 24-24 24h-8v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V144c0-26.51 21.49-48 48-48h512z',
  ],
  download: [
    640,
    512,
    [],
    '',
    'M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96zM383.6 255.6c-4.7-4.7-12.4-4.7-17.1.1L312 311.5V172c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v139.5l-54.5-55.8c-4.7-4.8-12.3-4.8-17.1-.1l-16.9 16.9c-4.7 4.7-4.7 12.3 0 17l104 104c4.7 4.7 12.3 4.7 17 0l104-104c4.7-4.7 4.7-12.3 0-17l-16.9-16.9z',
  ],
  envelopeOpen: [
    512,
    512,
    [],
    'f658',
    'M494.59 164.52c-1.52-1.26-13.86-11.2-30.59-24.66V96c0-26.51-21.49-48-48-48h-66.13C327.24 28.85 293.77 0 256 0c-37.65 0-70.9 28.63-93.85 48H96c-26.51 0-48 21.49-48 48v43.85c-16.81 13.52-29.15 23.46-30.48 24.56A48.002 48.002 0 0 0 0 201.48V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.51c0-14.31-6.38-27.88-17.41-36.99zM96 96h320v156.66c-36.26 29.32-78.69 63.67-86.59 69.95C311.25 337.12 279.6 368 256 368c-23.69 0-55.86-31.37-73.41-45.39-7.9-6.28-50.33-40.64-86.59-69.97V96zm368 362c0 3.31-2.69 6-6 6H54c-3.31 0-6-2.69-6-6V275.56c38.96 31.48 95.95 77.65 104.66 84.58C174.71 377.76 212.55 416 256 416c43.21 0 80.64-37.72 103.34-55.86 9-7.15 65.84-53.19 104.66-84.56V458zM176 192h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16H176c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16zm176 64v-16c0-8.84-7.16-16-16-16H176c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16z',
  ],
  globe: [
    496,
    512,
    [],
    'f57e',
    'M403.31 322.49l-11.91-11.91a8.008 8.008 0 0 1-2.34-5.66V292c0-2.21-1.79-4-4-4H379c-1.78 0-3.35 1.18-3.84 2.88l-4.2 14.47a3.996 3.996 0 0 1-3.84 2.88h-3.8a3.99 3.99 0 0 1-3.69-2.46l-5.35-12.85a8.003 8.003 0 0 0-7.39-4.93H334.8c-1.66 0-3.29.52-4.64 1.48l-23.71 16.89a26.355 26.355 0 0 1-5.59 3.05l-39.34 15.74a7.996 7.996 0 0 0-5.03 7.43v10.21c0 2.12.84 4.16 2.34 5.66l11.91 11.91c3 3 7.07 4.69 11.31 4.69h10.34c1.31 0 2.61-.16 3.88-.48l21.27-5.32c9.12-2.28 18.77.39 25.42 7.04l13.01 13.01c3 3 7.07 4.69 11.31 4.69h15.16c4.24 0 8.31-1.69 11.31-4.69l9.57-9.57c3-3 4.69-7.07 4.69-11.31V333.8c-.01-4.24-1.7-8.31-4.7-11.31zM248 8C111.04 8 0 119.03 0 256s111.04 248 248 248 248-111.03 248-248S384.96 8 248 8zm0 448c-99.37 0-181.8-72.91-197.19-168h62.57c4.24 0 8.31-1.69 11.31-4.69l19.47-19.46c3.86-3.86 10.37-2.8 12.81 2.08l22.62 45.23c2.71 5.42 8.25 8.85 14.31 8.85h6.1c8.84 0 16-7.16 16-16v-9.37c0-4.24-1.69-8.31-4.69-11.31l-5.66-5.66c-3.12-3.12-3.12-8.19 0-11.31l5.66-5.66c3-3 7.07-4.69 11.31-4.69h.31c5.62 0 10.83-2.95 13.72-7.77l17.37-28.95c1.8-3 6.2-2.83 7.76.3a7.996 7.996 0 0 0 7.15 4.42H272c4.42 0 8-3.58 8-8V137.9c0-6.06-3.42-11.6-8.84-14.31l-10.83-5.41c-5.49-2.75-5.97-10.4-.86-13.81l50.16-38.53C389.83 91.88 448 167.23 448 256c0 110.28-89.72 200-200 200z',
  ],
  mouse: [
    384,
    512,
    [],
    'f8cc',
    'M224 0h-64A160 160 0 0 0 0 160v192a160 160 0 0 0 160 160h64a160 160 0 0 0 160-160V160A160 160 0 0 0 224 0zm112 160v16H216V48h8a112.12 112.12 0 0 1 112 112zM160 48h8v128H48v-16A112.12 112.12 0 0 1 160 48zm64 416h-64A112.12 112.12 0 0 1 48 352V224h288v128a112.12 112.12 0 0 1-112 112z',
  ],
  planeDeparture: [
    640,
    512,
    [],
    'f5b0',
    'M624 464H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM74.64 367.77A48.002 48.002 0 0 0 110.62 384h.21l124.25-.54c12.03-.02 24.23-3.07 35.13-8.82L550.46 226.7c30.06-15.87 54.47-38.04 70.58-64.11 19.42-31.42 23.95-58.48 13.46-80.44-10.38-21.78-33.53-34.06-68.79-36.44-3.23-.22-6.46-.32-9.68-.32-26.8 0-54.1 7.23-81.12 21.5l-88.68 46.82-.36.19-.39-.15-201-78.46a47.99 47.99 0 0 0-39.86 2.26L108.7 56.52a48.002 48.002 0 0 0-3.84 82.64l103.92 67.88.29.19-.35.18-66.79 35.26-.42.22-.42-.22-54.64-28.65a47.96 47.96 0 0 0-22.29-5.49c-7.7 0-15.4 1.85-22.41 5.55l-16.16 8.53A47.987 47.987 0 0 0 .78 256.42a47.981 47.981 0 0 0 11.23 40.4l62.63 70.95zM64.16 256.52l77.43 40.6 161.98-85.51L131.11 98.97 167.03 80l221.83 86.59 108.46-57.25c20.05-10.59 39.81-15.95 58.72-15.95 2.14 0 4.29.07 6.44.21 15.03 1.02 26.56 4.71 28.71 9.24 2.08 4.36.46 16.01-10.98 34.51-11.62 18.8-29.66 35.02-52.16 46.9L247.81 332.19c-4.04 2.13-8.52 3.26-12.94 3.27l-124.25.54L48 265.05l16.16-8.53z',
  ],
  search: [
    512,
    512,
    [],
    'f002',
    'M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z',
  ],
  truckLoading: [
    640,
    512,
    [],
    'f4de',
    'M640 48V0H384c-17.7 0-32 14.3-32 32v340.6L5.9 465.4c-4.3 1.1-6.8 5.5-5.7 9.8l8.3 30.9c1.1 4.3 5.5 6.8 9.8 5.7L416.5 405c2.7 59.5 51.4 107 111.5 107 61.9 0 112-50.1 112-112s-50.1-112-112-112c-44.6 0-82.8 26.3-100.8 64H400V48h240zM528 336c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM50.2 375.6c2.3 8.5 11.1 13.6 19.6 11.3l216.4-58c8.5-2.3 13.6-11.1 11.3-19.6l-49.7-185.5c-2.3-8.5-11.1-13.6-19.6-11.3L151 133.3l24.8 92.7-61.8 16.5-24.8-92.7-77.3 20.7C3.4 172.8-1.7 181.6.6 190.1l49.6 185.5z',
  ],
  upload: [
    640,
    512,
    [],
    'f0ee',
    'M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96zM296.5 150.5c-4.7-4.7-12.3-4.7-17 0l-104 104c-4.7 4.7-4.7 12.3 0 17l16.9 16.9c4.7 4.7 12.4 4.7 17.1-.1l54.5-55.8V372c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12V232.5l54.5 55.8c4.7 4.8 12.3 4.8 17.1.1l16.9-16.9c4.7-4.7 4.7-12.3 0-17l-104-104z',
  ],
  sync: [
    512,
    512,
    [],
    'f021',
    'M500 8h-27.711c-6.739 0-12.157 5.548-11.997 12.286l2.347 98.575C418.212 52.043 342.256 8 256 8 134.813 8 33.933 94.924 12.296 209.824 10.908 217.193 16.604 224 24.103 224h28.576c5.674 0 10.542-3.982 11.737-9.529C83.441 126.128 161.917 60 256 60c79.545 0 147.942 47.282 178.676 115.302l-126.39-3.009c-6.737-.16-12.286 5.257-12.286 11.997V212c0 6.627 5.373 12 12 12h192c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-12.103 280h-28.576c-5.674 0-10.542 3.982-11.737 9.529C428.559 385.872 350.083 452 256 452c-79.546 0-147.942-47.282-178.676-115.302l126.39 3.009c6.737.16 12.286-5.257 12.286-11.997V300c0-6.627-5.373-12-12-12H12c-6.627 0-12 5.373-12 12v192c0 6.627 5.373 12 12 12h27.711c6.739 0 12.157-5.548 11.997-12.286l-2.347-98.575C93.788 459.957 169.744 504 256 504c121.187 0 222.067-86.924 243.704-201.824 1.388-7.369-4.308-14.176-11.807-14.176z',
  ],
} as const;
