import {
  useEffect,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react';

import {
  checkAuth,
  UserInfo,
  fetchUserInfo,
  requestVerification,
} from '@jl/utils';

import { NotificationContext } from './notification';

type AuthContext = [UserInfo, () => Promise<void>];
const authContext = createContext<AuthContext>([
  {
    userId: '',
    email: '',
    email_verified: false,
    sub: '',
    roles: [],
    terms: {},
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async () => {},
]);
authContext.displayName = 'AuthContext';
export const AuthProvider = authContext.Provider;

export const setupAuth = ({ addNotification }: NotificationContext) => {
  const [user, setUser] = useState<UserInfo>();
  useEffect(() => {
    checkAuth().then((user) => {
      setUser(user);
      if (user && !user.email_verified) {
        addNotification({
          id: 'userinfo',
          content: 'Your email has not been verified.',
          status: 'warn',
          timeout: -1,
          action: {
            text: 'Resend Verification',
            onClick: async () => {
              await requestVerification();
              addNotification({
                id: 'verification',
                content: `Email sent to ${user.email}.`,
                status: 'success',
              });
            },
          },
        });
      }
      return user;
    });
  }, []);
  const checkUser = useCallback(() => {
    fetchUserInfo(true).then(setUser);
  }, []);

  return user ? ([user, checkUser] as AuthContext) : undefined;
};

export const useAuth = () => useContext(authContext);
