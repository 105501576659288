import { useGaugeQuery } from '@jl/graphql-schema';
import { Header, Boundary } from '@jl/components';
import { apiLogo } from '@jl/assets';
import {
  editButton,
  isSetupUser,
  linkify,
  maybeAddNamespace,
  NotFoundError,
  useTitle,
} from '@jl/utils';

import { useParams, useHistory, useAuth } from '../../hooks';
import { GaugeBasics } from './basics';
import { GaugeForm } from './form';
import { GaugeView } from './view';

const GaugeDisplay = () => {
  const [user] = useAuth();
  const { uri, id, edit } = useParams(['uri', 'id', 'edit']);
  const { goBack, push, replace } = useHistory();

  const gaugeId = maybeAddNamespace('gauge', { uri, id });
  const { data } = useGaugeQuery({
    variables: { id: gaugeId || 'UNSET' },
  });
  const gauge = data?.gauge;
  if (!gauge) throw new NotFoundError('Gauge', gaugeId || '');

  useTitle([gauge.name, 'Gauges']);

  const Display = edit ? GaugeForm : GaugeView;
  return (
    <>
      <Header
        title={gauge.name}
        subtitle={[gauge.connection, gauge.type].filter(Boolean).join(' | ')}
        image={apiLogo('JoyGauge')}
        leftButton={{
          icon: 'arrowLeft',
          style: 'standard',
          onClick: goBack,
        }}
        rightButtons={[
          isSetupUser(user) && !edit
            ? {
                key: 'commands',
                icon: 'code',
                style: 'standard',
                onClick: () => push(linkify(gauge.id, { path: 'commands' })),
              }
            : undefined,
          editButton({ edit, replace, id: gaugeId }),
        ]}
      />
      <GaugeBasics gauge={gauge} />
      <Display gauge={gauge} />
    </>
  );
};

const Gauge = () => (
  <Boundary spinner>
    <GaugeDisplay />
  </Boundary>
);

export default Gauge;
