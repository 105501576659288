import { transparentize } from 'polished';

import { styled } from '@jl/assets';

const TANK_SIZE = 240;
export const Wall = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  position: relative;
  margin: 1rem;
  height: ${TANK_SIZE}px;
  width: ${TANK_SIZE}px;

  background-color: transparent;
  border-radius: 4px;
  border: 1px solid ${(props) => transparentize(0.65, props.theme.foreground)};
  box-shadow: 2px 2px 0px 1px
    ${(props) => transparentize(0.65, props.theme.foreground)};
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
  text-align: center;

  &:focus {
    outline: none;
  }

  h3,
  h4,
  p {
    position: relative;

    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
`;

export const AirGap = styled.div`
  position: absolute;
  top: -1%;
  height: 5%;
  width: 102%;
  left: -1%;
  right: -1%;

  background-color: ${(props) => transparentize(0.8, props.theme.disabled)};
`;

export const Marker = styled.div<{ percent: number }>`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  height: ${(props) => props.percent}%;
  border-top: 1px dashed ${(props) => props.theme.border};
  opacity: 0.5;
`;

export const Fill = styled.svg`
  display: block;
  overflow: visible;

  position: absolute;
  top: auto;
  bottom: 0;
  left: -1%;
  right: -1%;
  width: 102%;

  opacity: 0.4;
`;

export const Top = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;

  position: absolute;
  top: 12px;
  left: 0;
  right: 0;

  p {
    padding: 0 6px;

    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p:last-child {
    flex-shrink: 0;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  text-align: left;

  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;

  p {
    padding: 0 6px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Logo = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;

  margin: 0 6px;
  height: 25px;
  width: 100px;
`;

export const IconList = styled.ul`
  position: relative;
  list-style: none;
  margin: 5px 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin: 5px 0;

    span {
      padding-left: 5px;
      font-size: 1.4rem;
    }
  }
`;
