import { ChangeEvent, InputHTMLAttributes, useCallback, useMemo } from 'react';

import { material } from '@jl/assets';

import { useField } from './use-form';
import { Label } from './label';
import { RadioLabel, RadioText } from './radio.styles';
import { Hidden } from './input.styles';
import { isStringArray } from '@jl/utils';
import { Icon } from './icon';

export type CheckboxOptions = {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}[];

export type CheckboxesProps = {
  name: string;
  label: string;
  options: CheckboxOptions;
  inline?: boolean;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'disabled'>;

export const Checkboxes = ({
  name,
  label,
  options,
  inline,
  ...rest
}: CheckboxesProps) => {
  const fieldProps = useField<string[]>(name, {
    guard: isStringArray,
    default: [],
  });
  const setValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        fieldProps.value?.indexOf(e.target.value) === -1
          ? [...fieldProps.value, e.target.value]
          : fieldProps.value?.filter((v) => v !== e.target.value);
      fieldProps.setValue(value || []);
    },
    [fieldProps.value, fieldProps.setValue],
  );

  return useMemo(
    () => (
      <Label text={label} inline={inline} containsLabels>
        {options.map(({ label, value }) => (
          <RadioLabel key={value} checked={value == fieldProps.value}>
            <Hidden
              {...rest}
              type="checkbox"
              name={name}
              value={value}
              checked={fieldProps.value?.indexOf(value) !== -1}
              onChange={setValue}
            />
            <Icon name="checkCircle" colour={material.green.r700} />
            <RadioText>{label}</RadioText>
          </RadioLabel>
        ))}
      </Label>
    ),
    [fieldProps, options],
  );
};
