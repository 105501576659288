import { ReactNode, RefObject, useCallback, useEffect, useState } from 'react';

import { Outer, Inner, Toggle, Contents } from './filter.styles';

export type FilterProps = {
  focusRef?: RefObject<HTMLInputElement & HTMLTextAreaElement>;
  children: ReactNode;
};

export const Filter = ({ focusRef, children }: FilterProps) => {
  const [visible, setVisible] = useState(false);

  const onClick = useCallback(
    (force?: boolean) => {
      const next = force ?? !visible;
      setVisible(next);
      if (next) focusRef?.current?.focus();
    },
    [visible, focusRef],
  );
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'f' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onClick(true);
      }
      if (e.code === 'Escape') {
        onClick(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, []);

  return (
    <Outer>
      <Toggle
        value="toggle filters"
        icon={visible ? 'times' : 'search'}
        outline
        onClick={() => onClick()}
      />
      <Inner
        show={visible}
        onClick={(e) =>
          e.target === e.currentTarget && visible && setVisible(false)
        }
      >
        <Contents>{children}</Contents>
      </Inner>
    </Outer>
  );
};
