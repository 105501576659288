import { ReactNode } from 'react';

import { Form } from './form';

interface FilterFormProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaults: any;
  children?: ReactNode;
}

export const FilterForm = ({ name, defaults, children }: FilterFormProps) => {
  const key = `${name}_filters`;
  return (
    <Form
      initialValues={{
        ...defaults,
        ...JSON.parse(localStorage.getItem(key) || '{}'),
      }}
      onChange={(v) => localStorage.setItem(key, JSON.stringify(v))}
    >
      {children}
    </Form>
  );
};
