import Colibri from '../img/Colibri.png';
import JoyLevel from '../img/JoyLevel.png';
import RMSDelta from '../img/JoyLevel.png';
import SmartFill from '../img/SmartFill.png';
import JoyGauge from '../img/JoyGauge.png';
import ProGauge from '../img/ProGauge.png';

const apiLogos: Record<string, string> = {
  Colibri,
  JoyLevel,
  RMSDelta,
  SmartFill,
  JoyGauge,
  ProGauge,
};

export const apiLogo = (api?: string): string =>
  (api && apiLogos[api]) || apiLogos.JoyLevel;
