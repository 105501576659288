import { useCallback, useMemo, useState } from 'react';

import { Container, Instruction, Row, Title, Warning } from '@jl/assets';
import {
  Button,
  Compute,
  Display,
  Form,
  Icon,
  Input,
  InputArray,
  Label,
  Select,
  StrappingChart,
  TextArea,
  useField,
} from '@jl/components';
import {
  GaugeInput,
  GaugeResult,
  gaugeUpdateProps,
  gaugeValues,
  isRange,
  rangeOptions,
  rangeToLabel,
  useUpdateGaugeMutation,
} from '@jl/graphql-schema';
import {
  MemoizedCalulate,
  displaySchedule,
  linkify,
  sendEvent,
  specialCharacters,
  useEvent,
  useMemoizedCalculations,
} from '@jl/utils';

import { SSelect } from '@jl/components/src/select.styles';
import { useAuth, useHistory } from '../../hooks';
import { CopyGauge } from './copy';
import { ScheduleGauge } from './schedule';

type FieldsProps = {
  latest: GaugeResult['latest'];
  calculate: MemoizedCalulate;
};

const PressureFields = ({ latest, calculate }: FieldsProps) => (
  <Row>
    <Display
      label="Reported Pressure"
      text={`${latest?.pressure} mm`}
      size="compact"
    />
    <Input
      name="probe_sg"
      label="Probe SG"
      type="number"
      step="any"
      size="subCompact"
    />
    <Input
      name="actual_sg"
      label="Actual SG"
      type="number"
      step="any"
      size="subCompact"
    />
    <Input
      name="offset_mm"
      label="Pressure Offset"
      type="number"
      size="compact"
    />
    <Compute
      label="Calculated Pressure"
      calculate={calculate}
      resultKey="calculatedFill"
      size="compact"
    />
  </Row>
);

const ADCFields = ({ latest, calculate }: FieldsProps) => {
  const { setValue, value } = useField('range', {
    guard: isRange,
    default: undefined,
  });
  const rangeValue = useMemo(() => rangeToLabel(value), [value]);

  return (
    <>
      <Row>
        <Display
          label="Reading"
          text={`${latest?.digital || specialCharacters.enDash} B`}
          size="subCompact"
        />
        <Label text="Probe Setup">
          <SSelect
            name="range"
            onChange={(e) =>
              setValue(
                rangeOptions.find((o) => o.label === e.currentTarget.value)
                  ?.value,
              )
            }
            value={rangeValue}
          >
            {rangeOptions.map(({ label }) => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </SSelect>
          <Icon name="angleDown" />
        </Label>
      </Row>
      <Row>
        <Compute
          label="Reported Pressure"
          calculate={calculate}
          resultKey="calculatedPressure"
          size="compact"
        />
        <Input
          name="probe_sg"
          label="Probe SG"
          type="number"
          step="any"
          size="subCompact"
        />
        <Input
          name="actual_sg"
          label="Actual SG"
          type="number"
          step="any"
          size="subCompact"
        />
        <Input
          name="offset_mm"
          label="Pressure Offset"
          type="number"
          size="compact"
        />
        <Compute
          label="Calculated Pressure"
          calculate={calculate}
          resultKey="calculatedFill"
          size="compact"
        />
      </Row>
    </>
  );
};

const DistanceFields = ({ latest, calculate }: FieldsProps) => (
  <>
    <Row>
      <Select
        label="Ultrasonic Measurement"
        name="ultrasonic"
        options={[{ value: 'Standard' }, { value: 'WaveGuide' }]}
      />
    </Row>
    <Row>
      <Display
        label="Reported Distance To Fluid"
        text={`${latest?.distance} mm`}
        size="compact"
      />
      <Input
        name="offset_mm"
        label="Distance From Mount To Bottom"
        type="number"
        size="compact"
      />
      <Compute
        label="Calculated Liquid Height"
        calculate={calculate}
        resultKey="calculatedFill"
        size="compact"
      />
      <Compute
        label="Percent of SFL"
        calculate={calculate}
        resultKey="percentFill"
        size="compact"
      />
    </Row>
  </>
);

const noop = () => (
  <Container small>
    <Row>
      <Title>Cannot Process Gauge.</Title>
    </Row>
  </Container>
);

const fieldsByType = {
  ADC: ADCFields,
  Pressure: PressureFields,
  TemperatureAndPressure: PressureFields,
  Ultrasonic: DistanceFields,
  Temperature: noop,
};

export const GaugeForm = ({ gauge }: { gauge: GaugeResult }) => {
  const [scheduleModal, setScheduleModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  useEvent({
    category: 'Tank',
    action: 'edit',
    label: gauge.id,
  });
  const [user] = useAuth();
  const [mutate] = useUpdateGaugeMutation();
  const { replace } = useHistory();
  const onSubmit = useCallback(
    (v: GaugeInput) => {
      mutate(gaugeUpdateProps(gauge, v, user));
      sendEvent({ category: 'Gauge', action: 'submit', label: gauge.id });
      replace(linkify(gauge.id));
    },
    [gauge],
  );

  const calculate = useMemoizedCalculations(gauge.latest, gauge.type);
  const calculateSchedule = useCallback(
    (v: GaugeInput) =>
      v.schedule
        ? displaySchedule({ ...v.schedule, timeZone: v.timeZone })
        : specialCharacters.enDash,
    [],
  );
  const Fields = gauge.type ? fieldsByType[gauge.type] : noop;

  return (
    <Container small>
      <Form
        initialValues={gaugeValues(gauge)}
        onSubmit={onSubmit}
        portalElements={
          <>
            <ScheduleGauge modal={scheduleModal} setModal={setScheduleModal} />
            <CopyGauge modal={copyModal} setModal={setCopyModal} />
          </>
        }
      >
        <Row>
          <TextArea label="Notes" name="notes" rows={1} />
        </Row>
        <Row align="center">
          <Compute label="Schedule" calculate={calculateSchedule} />
          <Button
            icon="history"
            style="highlight"
            onClick={() => setScheduleModal(true)}
          />
        </Row>
        <Row align="center">
          <Input label="Tank Model" name="model" />
          <Button
            icon="copy"
            style="highlight"
            onClick={() => setCopyModal(true)}
          />
        </Row>
        <InputArray
          name="strapping"
          label="Strapping Table"
          defaultValue={() => ({})}
          hint={
            <Instruction>
              <Warning>
                <strong>Note:</strong> Largest value will be used as SFL
              </Warning>
            </Instruction>
          }
        >
          <Input
            name="mm"
            label="Millimetres"
            type="number"
            size="subCompact"
            autoFocus
          />
          <Input name="litres" label="Litres" type="number" size="subCompact" />
        </InputArray>
        <Fields latest={gauge.latest} calculate={calculate} />
        <Row>
          <Compute
            label="Reported Volume"
            calculate={calculate}
            resultKey="reportedVolume"
            size="compact"
          />
          <Input
            name="offset_litres"
            label="Volume Offset"
            type="number"
            size="compact"
          />
          <Compute
            label="Calculated Volume"
            calculate={calculate}
            resultKey="calculatedVolume"
            size="compact"
          />
          <Compute
            label="Percent of SFL"
            calculate={calculate}
            resultKey="percentVolume"
            size="compact"
          />
        </Row>
        <Compute label="Strapping Table" calculate={calculate}>
          {(v, r) => <StrappingChart data={v.strapping} current={r.line} />}
        </Compute>
        <Row>
          <Button
            icon="upload"
            text="Update Gauge"
            type="submit"
            style="success"
            outline
          />
        </Row>
      </Form>
    </Container>
  );
};
