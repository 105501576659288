import { Container, Row } from '@jl/assets';
import { BigMetric, MetricTable } from '@jl/components';
import { useMetricsAnalyticsQuery } from '@jl/graphql-schema';

const Analytics = () => {
  const { data } = useMetricsAnalyticsQuery();
  const analytics = data && data.metrics.analytics;
  if (!analytics) return null;

  return (
    <Container>
      <Row>
        <BigMetric count={analytics.allUsers} label="Total Users" />
        <BigMetric count={analytics.newUsers} label="New Users" />
      </Row>
      <Row>
        <MetricTable
          label="Top Users"
          items={analytics.topUsers.map(({ email, count }) => ({
            label: email,
            count,
          }))}
        />
        <MetricTable
          label="Top Actions"
          items={analytics.actions.map(({ category, action, count }) => ({
            label: `${category} ${action}`,
            count,
          }))}
        />
        <MetricTable
          label="Cities"
          items={analytics.cities.map(({ city, count }) => ({
            label: city,
            count,
          }))}
        />
        <MetricTable
          label="Devices"
          items={analytics.devices.map(({ device, count }) => ({
            icon: device === 'desktop' ? 'desktop' : 'mobile',
            count,
          }))}
        />
      </Row>
    </Container>
  );
};

export default Analytics;
