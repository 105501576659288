import { Notifications } from '@jl/components';
import { useNotifications } from '../../hooks';

const UserNotifications = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <Notifications
      onCancel={removeNotification}
      notifications={notifications}
    />
  );
};

export default UserNotifications;
