import { styled } from '@jl/assets';

export const Box = styled.div`
  padding: 10px;
  text-align: center;
`;

export const BigText = styled.p`
  font-size: 4.8rem;
  font-weight: 300;
  margin: 0;
`;

export const Table = styled.table`
  font-size: 1.4rem;
  margin: 1rem;

  thead {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    tr td {
      border-bottom: 1px dotted ${props => props.theme.border};
      padding: 0.8rem;
    }
  }

  tbody {
    th {
      font-weight: normal;
      font-style: italic;
      text-align: right;
      padding: 0.4rem;
    }
    td {
      text-align: left;
      padding: 0.4rem;
    }
  }
`;
