import { useState, useEffect } from 'react';

import { TThemeKey, TTheme, themes } from '@jl/assets';
import { preferredScheme } from '@jl/utils';

export const setupTheme = (forceTheme: TThemeKey | null): TTheme => {
  const [theme, setTheme] = useState<TThemeKey>('light');

  useEffect(() => {
    if (forceTheme) {
      setTheme(forceTheme);
      return undefined;
    }

    const { value, unsubscribe } = preferredScheme((theme) => setTheme(theme));
    setTheme(value);
    return unsubscribe;
  }, [forceTheme]);

  return themes[theme];
};
