import { Column, Emphasize, Hint, specialColours } from '@jl/assets';
import { useShortTime, formatTime } from '@jl/utils';

import { Icon } from './icon';
import {
  Warning,
  Events,
  Event,
  EventIcon,
  EventLabel,
  TWarningEventStatus,
  statusIcon,
} from './tank-warning.styles';

export interface TankWarningEventProps {
  id: string;
  status: TWarningEventStatus;
  reason: string;
  time: string;
  ip?: string | null;
  url?: string | null;
}

export const TankWarningEvent = ({
  id,
  status,
  reason,
  time,
}: TankWarningEventProps) => (
  <Event key={id} title={reason}>
    <EventIcon status={status}>
      <Icon name={statusIcon[status]} size={14} colour={specialColours.white} />
    </EventIcon>
    <EventLabel>
      {status} ({useShortTime(time)})
    </EventLabel>
  </Event>
);

export interface TankWarningProps {
  id: string;
  email: string;
  lastUpdatedAt: string;
  events: TankWarningEventProps[];
}

export const TankWarning = ({
  id,
  email,
  lastUpdatedAt,
  events,
}: TankWarningProps) => {
  return (
    <Warning key={id}>
      <Column>
        <Hint>
          <Emphasize strong italic>
            {email}
          </Emphasize>
        </Hint>
        <Hint>{formatTime(lastUpdatedAt)}</Hint>
      </Column>
      <Events>
        {events
          .slice()
          .reverse()
          .filter((e, i, a) => i === a.findIndex((f) => f.status === e.status))
          .map(TankWarningEvent)}
      </Events>
    </Warning>
  );
};
