import { clearAuthToken } from '@jl/utils';
import { useNotifications, useLocation, Navigate } from '../../hooks';

const Callback = () => {
  const { search } = useLocation();
  const { addNotification } = useNotifications();

  const params = new URLSearchParams(search);
  const message = params.get('message');
  const success = params.get('success') == 'true';
  if (message) {
    addNotification({
      id: 'callback',
      content: message,
      status: success ? 'success' : 'warn',
    });
  }
  if (success) {
    clearAuthToken();
  }
  return <Navigate to="/" />;
};

export default Callback;
