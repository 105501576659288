import { useTheme as useEmotionTheme, Theme } from '@emotion/react';
import emotionStyled from '@emotion/styled';
import { material, specialColours } from './colours';

declare module '@emotion/react' {
  export interface Theme {
    foreground: string;
    background: string;

    action: string;
    success: string;
    successPale: string;
    warning: string;
    warningPale: string;
    danger: string;
    dangerPale: string;

    border: string;
    borderPale: string;
    disabled: string;
    disabledPale: string;

    map: string;
  }
}

export type TThemeKey = 'light' | 'dark';
export type TTheme = Theme;

export const themes: Record<TThemeKey, TTheme> = {
  light: {
    foreground: material.grey.r900,
    background: material.grey.r100,

    action: material.blue.r400,
    danger: material.red.r600,
    dangerPale: material.red.r400,
    warning: material.orange.r600,
    warningPale: material.orange.r400,
    success: material.green.r500,
    successPale: specialColours.paleGreen,

    border: material.blueGrey.r800,
    borderPale: material.blueGrey.r300,
    disabled: material.grey.r600,
    disabledPale: material.grey.r200,

    map: 'mapbox://styles/mapbox/light-v10',
  },
  dark: {
    foreground: material.grey.r200,
    background: material.grey.r800,

    action: material.blue.r400,
    danger: material.red.r600,
    dangerPale: material.red.r400,
    warning: material.orange.r600,
    warningPale: material.orange.r400,
    success: material.green.r500,
    successPale: specialColours.darkPaleGreen,

    border: material.blueGrey.r300,
    borderPale: material.blueGrey.r700,
    disabled: material.grey.r200,
    disabledPale: material.grey.r600,

    map: 'mapbox://styles/mapbox/dark-v10',
  },
};

export const useTheme = useEmotionTheme;
export const styled = emotionStyled;
