import { transparentize } from 'polished';

import { styled } from '@jl/assets';

export const Info = styled.button`
  background: transparent;
  border: none;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  margin: 0 5px;
  padding: 25px 5px 5px;
  border-bottom: 1px solid ${props => transparentize(0.8, props.theme.foreground)};

  display: block;
  width: 100%;
  text-align: left;

  p {
    margin: 0.6rem 0;
  }

  &:focus {
    outline: none;
  }
`;

export const Logo = styled.div<{ src: string }>`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});

  height: 25px;
  width: 50px;
  margin-right: 15px;
`;
