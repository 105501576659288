import { transparentize } from 'polished';
import { styled } from '@jl/assets';

export const Box = styled.div`
  width: 100%;
`;

export const Display = styled.p<{ filtered?: boolean }>`
  padding: 5px 10px;
  margin: 0 auto;
  width: fit-content;

  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;

  border-radius: 3px;
  background-color: ${(props) =>
    props.filtered
      ? transparentize(0.6, props.theme.warningPale)
      : 'transparent'};
`;

export const Action = styled.p`
  padding: 0;
  margin: 0 auto;
  width: fit-content;
`;
