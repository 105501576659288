import { TankResult } from '@jl/graphql-schema';
import { Boundary, TankGauge, Label, Display } from '@jl/components';
import { Container, Row } from '@jl/assets';
import {
  useEvent,
  specialCharacters,
  formatPossibleTime,
  isAdmin,
} from '@jl/utils';

import { TankDetailView } from './detail';
import { GaugeField } from './gauge-field';
import { useAuth } from '../../hooks';

const TankView = ({ tank }: { tank: TankResult }) => {
  const [user] = useAuth();
  useEvent({
    category: 'Tank',
    action: 'view',
    label: tank.id,
  });

  return (
    <Container small>
      <Row>
        {isAdmin(user) && (
          <Display
            label="Billing Start Date"
            text={formatPossibleTime(tank.billingStartedAt, { dateOnly: true })}
          />
        )}
        <Display
          label="Last Reported"
          text={formatPossibleTime(tank.latest?.time)}
        />
      </Row>
      <Row>
        {tank.latest?.voltage && (
          <Display
            label="Battery"
            text={`${Math.round(tank.latest.voltage)}%`}
          />
        )}
        <Boundary spinner small>
          <GaugeField tank={tank} />
        </Boundary>
      </Row>
      <Label text="Current Volume">
        <TankGauge
          id={tank.id}
          contents={tank.contents}
          unit={tank.unit}
          level={tank.latest || null}
          warnings={tank.warningLevels || []}
        />
      </Label>
      <Display
        label="Tank Location"
        action={
          tank.location
            ? [
                {
                  text: 'Get Directions',
                  onClick: () => {
                    if (tank.location)
                      window.open(
                        `https://www.google.com/maps/dir/?api=1&destination=${tank.location.latitude},${tank.location.longitude}`,
                        '_blank',
                      );
                  },
                },
              ]
            : undefined
        }
        text={tank.location?.display || specialCharacters.nbsp}
      />
      <Display
        label="Warning Emails"
        text={tank.warningEmails?.join(', ') || specialCharacters.enDash}
      />
      <Boundary spinner>
        <TankDetailView tank={tank} />
      </Boundary>
    </Container>
  );
};

export default TankView;
