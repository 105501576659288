import { InputHTMLAttributes, useMemo } from 'react';

import { useTheme } from '@jl/assets';

import { useField } from './use-form';
import { Label } from './label';
import { Icon, IconProps } from './icon';
import { RadioLabel, RadioText } from './radio.styles';
import { Hidden } from './input.styles';

export type RadioOptions = {
  label: string;
  icon?: IconProps['name'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}[];

export type RadioProps = {
  name: string;
  label: string;
  options?: RadioOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsFn?: (v: any) => RadioOptions;
  basedOn?: string;
  inline?: boolean;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'disabled'>;

export const Radio = ({
  name,
  label,
  options,
  optionsFn,
  basedOn,
  inline,
  ...rest
}: RadioProps) => {
  const theme = useTheme();
  const fieldProps = useField(name);
  let actualOptions: RadioOptions = [];
  if (optionsFn && basedOn) {
    const basedOnProps = useField(basedOn);
    actualOptions = optionsFn(basedOnProps.value);
  } else if (options) {
    actualOptions = options;
  }

  return useMemo(
    () => (
      <Label text={label} inline={inline} containsLabels>
        {actualOptions.map(({ label, icon, value }) => (
          <RadioLabel
            key={value}
            icon={!!icon}
            checked={value == fieldProps.value}
          >
            {icon && <Icon name={icon} colour={theme.border} />}
            <Hidden
              {...rest}
              type="radio"
              name={name}
              value={value}
              checked={value == fieldProps.value}
              onChange={fieldProps.onChange}
            />
            {!icon && <Icon name="checkCircle" colour={theme.success} />}
            <RadioText hide={!!icon}>{label}</RadioText>
          </RadioLabel>
        ))}
      </Label>
    ),
    [fieldProps.value, actualOptions],
  );
};
