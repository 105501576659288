import { Row, Subtitle, Stretch, Hint } from '@jl/assets';

import { Info, Logo } from './account-info.styles';

export interface RoleInfoProps {
  userId: string;
  email: string;
  accounts: {
    roleId: string;
    roleType: string;
    label: string;
    detail?: string;
    api: string;
  }[];
}

export const RoleInfo = ({ userId, email, accounts }: RoleInfoProps) => {
  return (
    <Info key={userId} type="button">
      <Row align="center">
        <Logo
          src={`https://cdn.auth0.com/avatars/${email
            .slice(0, 2)
            .toLocaleLowerCase()}.png`}
        />
        <Subtitle>
          <strong>{email}</strong>
        </Subtitle>
        <Stretch />
      </Row>
      <Hint>
        <strong>
          {accounts.length} Account{accounts.length !== 1 && 's'}:
        </strong>{' '}
        {accounts
          .map(({ label, roleType }) => `${label} (${roleType})`)
          .join(', ')}
      </Hint>
    </Info>
  );
};
