export class NotFoundError extends Error {
  public type: string;
  public id: string;

  constructor(type: string, id: string) {
    const message = `Could not load ${id}. Please check the link or contact support.`;
    super(message);
    this.type = type;
    this.id = id;
  }
}

export class ApiError extends Error {
  public graphQLErrors: readonly { message: string }[];
  public networkError: Error | null;

  constructor({
    message,
    graphQLErrors,
    networkError,
  }: {
    message: string;
    graphQLErrors: readonly { message: string }[];
    networkError: Error | null;
  }) {
    super(message);
    this.graphQLErrors = graphQLErrors;
    this.networkError = networkError;
  }
}
