import { styled, breakPoint } from '@jl/assets';

export const Border = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  padding: 0 0 5px 0;
  width: 100%;

  border-bottom: 1px solid ${(props) => props.theme.borderPale};

  @media (max-width: ${breakPoint}px) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

export const Display = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex: 1;

  @media (max-width: ${breakPoint}px) {
    flex: 1 1 100%;
    order: 100;
  }
`;

export const Image = styled.div<{ src: string }>`
  margin: 0 10px;
  height: 40px;
  width: 50px;

  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Text = styled.div`
  flex: 1;
  padding: 0 10px;

  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;
