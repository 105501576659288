import { lightIcons } from './light';
import { regularIcons } from './regular';
import { solidIcons } from './solid';

export const iconLibrary = {
  ...lightIcons,
  ...regularIcons,
  ...solidIcons,
};

export type IconName = keyof typeof iconLibrary;
