import { Box, Circle, Mask, Line } from './spinner.styles';

export interface SpinnerProps {
  fullScreen?: boolean;
  padded?: boolean;
  small?: boolean;
}

export const Spinner = (props: SpinnerProps) => (
  <Box {...props}>
    <Circle />
    <Mask>
      <Line />
    </Mask>
  </Box>
);
