import { ReactNode, useMemo } from 'react';

import { useForm, useFormProvider, FormProps } from './use-form';

const FormInner = ({ children }: { children?: ReactNode }) => {
  const { handleSubmit } = useForm();

  return useMemo(
    () => <form onSubmit={handleSubmit}>{children}</form>,
    [handleSubmit, children],
  );
};

export const Form = <V extends Record<string, unknown>>({
  children,
  portalElements,
  ...rest
}: FormProps<V> & { children?: ReactNode; portalElements?: ReactNode }) => {
  const { Provider, value } = useFormProvider(rest);
  return (
    <Provider value={value}>
      <FormInner>{children}</FormInner>
      {portalElements}
    </Provider>
  );
};
