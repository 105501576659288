import { material, styled } from '@jl/assets';

export const SSelect = styled.select`
  appearance: none;
  flex: 1;
  margin: 0;
  padding: 3px 30px 5px 2px;
  width: 100%;

  font-size: 1.6rem;
  line-height: 1.4;

  background: none;
  border: 0;
  border-radius: 0;

  &:disabled {
    color: ${material.grey.r600};
    font-style: italic;
  }
  &:focus {
    outline: none;
  }
  &:focus ~ hr {
    border-color: ${(props) => props.theme.action};
  }
  & + svg {
    position: absolute;
    right: 10px;
    bottom: 11px;
    pointer-events: none;
  }
`;

export const OptionRow = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;

  p {
    padding: 0;
  }
`;
