import { DataProxy } from '@apollo/client';
import { specialCharacters } from '@jl/utils';

import {
  AccountInput,
  AccountQuery,
  AccountDocument,
  AccountsQuery,
  AccountsDocument,
  RoleFieldsFragment,
  RoleType,
} from '../hooks';
import { optimisticMutation } from './general';

export type AccountResult = NonNullable<AccountQuery['account']>;

export const accountValues = (v: AccountResult): AccountInput => ({
  label: v.label,
  detail: v.detail,
  notes: v.notes,
  plan: v.plan,
  api: v.api,
  externalAPIEnabled: v.apiAccess?.enabled,

  reference: v.reference,
  secret: v.secret,
  url: v.url,
  serialNumbers: v.serialNumbers,
  gaugeIds: v.gaugeIds,
  timezone: v.timezone,
  fairbanksUsers: v.fairbanksUsers?.map((u) => ({
    id: u.id,
    tanks: u.tanks?.map((t) => ({
      tank: t.tank,
      lastLevel: t.lastLevel,
      safeFillLevel: t.safeFillLevel,
    })),
  })),
  roles: v.roles.map((r) => ({
    id: r.id,
    userId: r.userId.replace('jl:user:', ''),
    type: r.type,
  })),
});

export const accountUpdateProps = (a: AccountResult, v: AccountInput) => ({
  optimisticResponse: {
    updateAccount: optimisticMutation,
  },
  variables: {
    id: a.id,
    fields: {
      label: v.label,
      detail: v.detail,
      notes: v.notes,
      plan: v.plan,
      externalAPIEnabled: v.externalAPIEnabled,
      api: v.api,
      reference: v.reference,
      secret: v.secret,
      url: v.url,
      serialNumbers: v.serialNumbers,
      gaugeIds: v.gaugeIds,
      timezone: v.timezone,
      fairbanksUsers: v.fairbanksUsers?.map((u) => ({
        id: u.id,
        tanks: u.tanks?.map((t) => ({
          tank: t.tank,
          lastLevel: t.lastLevel,
          safeFillLevel: t.safeFillLevel,
        })),
      })),
      roles: v.roles.map((r) => ({
        id: r.id,
        userId: `jl:user:${r.userId}`,
        type: r.type,
      })),
    },
  },
  update: (proxy: DataProxy) => {
    const updated: AccountQuery['account'] = {
      ...a,
      label: v.label,
      detail: v.detail || null,
      notes: v.notes,
      plan: v.plan || null,
      apiAccess:
        typeof v.externalAPIEnabled === 'boolean'
          ? {
              enabled: v.externalAPIEnabled,
              value: specialCharacters.infinity,
            }
          : null,
      api: v.api,
      reference: v.reference || null,
      secret: v.secret || null,
      url: v.url || null,
      serialNumbers: v.serialNumbers || null,
      gaugeIds: v.gaugeIds || null,
      timezone: v.timezone || null,
      fairbanksUsers:
        v.fairbanksUsers?.map((u) => ({
          id: u.id,
          tanks: u.tanks?.map((t) => ({
            tank: t.tank,
            lastLevel: t.lastLevel,
            safeFillLevel: t.safeFillLevel,
            __typename: 'FairbanksTank',
          })),
          __typename: 'FairbanksUser',
        })) || null,
      roles: v.roles.map((r) => ({
        accountId: a.id,
        id: r.id,
        userId: `jl:user:${r.userId}`,
        type: r.type,
        __typename: 'Role',
      })),
    };
    proxy.writeQuery<AccountQuery>({
      query: AccountDocument,
      data: { account: updated },
      variables: { id: a.id },
    });

    try {
      const data = proxy.readQuery<AccountsQuery>({
        query: AccountsDocument,
      });
      if (data) {
        const filtered = data.accounts.filter(({ id }) => a.id !== id);
        proxy.writeQuery<AccountsQuery>({
          query: AccountsDocument,
          data: { accounts: [...filtered, updated] },
        });
      }
    } catch {}
  },
});

export const accountDeleteProps = (a: AccountResult) => ({
  variables: { id: a.id },
  optimisticResponse: {
    deleteAccount: optimisticMutation,
  },
  update: (proxy: DataProxy) => {
    try {
      const data = proxy.readQuery<AccountsQuery>({
        query: AccountsDocument,
      });
      if (data) {
        const filtered = data.accounts.filter(({ id }) => a.id !== id);
        proxy.writeQuery<AccountsQuery>({
          query: AccountsDocument,
          data: { accounts: filtered },
        });
      }
    } catch {}
  },
});

export const accountHasRole = (
  { roles }: { roles: Array<RoleFieldsFragment> },
  { userId, roleType }: { userId: string; roleType: RoleType },
) => roles.find((r) => r.userId === userId && r.type === roleType);
