import { Text, Container, Link, Hr } from '@react-email/components';
import { CSSProperties } from 'react';

import { container } from './styles';
import { EmailBaseProps } from './types';

const disclaimer: CSSProperties = {
  color: '#aaaaaa',
  fontStyle: 'italic',
  fontSize: '14px',
  lineHeight: '18px',
};

const info: CSSProperties = {
  color: '#666666',
  fontSize: '12px',
  lineHeight: '18px',
  margin: '4px',
};

export const Footer = ({ address, support }: EmailBaseProps) => (
  <Container style={container}>
    <Hr />
    <Text style={disclaimer}>
      Why are you receiving this? Your email was used to sign up to JoyLevel. If
      that wasn&apos;t you, just reply to this email and we&apos;ll sort it out
      straight away.
    </Text>
    <Text style={info}>
      <Link href={`mailto:${support}`}>{support}</Link>
    </Text>
    <Text style={info}>{address}</Text>
  </Container>
);
