import { useState, useEffect } from 'react';

import { loadConfig } from '@jl/utils';

export const useConfig = (key: string): string | undefined => {
  const [value, setValue] = useState<string>();

  useEffect(() => {
    (async () => {
      const result = await loadConfig(key);
      if (result) setValue(result);

    })();
  }, [key]);

  return value;
};
