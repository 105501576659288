import {
  OperationVariables,
  SuspenseQueryHookOptions,
  useSuspenseQuery_experimental as useSuspenseQuery,
} from '@apollo/client';

export type {
  LazyQueryHookOptions,
  MutationHookOptions,
  SubscriptionHookOptions,
  SubscriptionResult,
} from '@apollo/client';

export { useLazyQuery, useMutation, useSubscription } from '@apollo/client';

export type QueryHookOptions<
  TData,
  TVariables extends OperationVariables = OperationVariables,
> = SuspenseQueryHookOptions<TData, TVariables>;

export const useQuery = useSuspenseQuery;
