import { css, keyframes } from '@emotion/react';

import { TTheme, styled } from './theme';

export const breakPoint = 595;

export const globalCss = (theme: TTheme) => css`
  html {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-size: 62.5%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
    color: inherit;
  }

  body {
    color: ${theme.foreground};
    background-color: ${theme.background};
    margin: 0;
    padding: 10px;
  }

  a {
    color: ${theme.action};
  }
`;

export const shadowFade = keyframes({
  '0%': { boxShadow: '0 0 4px 2px rgba(41, 182, 246, 0)' },
  '40%': { boxShadow: '0 0 4px 2px rgba(41, 182, 246, 0.8)' },
  '90%': { boxShadow: '0 0 4px 2px rgba(41, 182, 246, 0)' },
});

export const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const Stretch = styled.div`
  flex: 1;
`;

export const Container = styled.div<{ small?: boolean }>`
  margin: 0 auto;
  max-width: ${(props) => (props.small ? '1000px' : '1400px')};
  width: 98%;
`;

export const Row = styled.div<{ align?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const Column = styled.div``;

export const Center = styled.div`
  text-align: center;
`;

export const Rule = styled.hr`
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.borderPale};
  margin: 10px 0;
  opacity: 0.6;
`;
