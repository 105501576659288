import { buildDisplayAmount } from './build-display-amount';
import { isNullOrUndefined } from './guards';

export function buildAmountString(
  amount: {
    value: number | null;
    maximum: number;
  } | null,
  unit: string,
) {
  if (!amount || isNullOrUndefined(amount.value)) {
    return '\xa0';
  }

  const currentDisplay = buildDisplayAmount(amount.value, unit);
  const maximumDisplay = buildDisplayAmount(amount.maximum, unit);
  if (currentDisplay.unit === maximumDisplay.unit) {
    currentDisplay.unit = '';
  }

  return `${currentDisplay.amount}${currentDisplay.unit} / ${maximumDisplay.amount}${maximumDisplay.unit}`;
}
