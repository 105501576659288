import { buildAuthorizeUrl } from './build-authorize-url';
import { setAuthToken } from './auth-token';
import { sendEvent } from './analytics';

export const silentRefresh = async (action?: 'verification') => {
  const nonce = window.crypto.getRandomValues(new Uint32Array(1))[0].toString();
  const authorizeUrl = await buildAuthorizeUrl({
    response_mode: 'web_message',
    prompt: 'none',
    action,
    nonce,
  });
  return new Promise<{
    id_token: string;
    access_token: string;
    expires_in: string;
  }>((resolve, reject) => {
    sendEvent({
      category: 'Token',
      action: 'load',
    });

    const iframe = window.document.createElement('iframe');
    iframe.style.display = 'none';

    const cleanup = () => {
      iframe.remove();
      window.removeEventListener('message', eventListener);
      clearTimeout(timeout);
    };
    const timeout = setTimeout(() => {
      reject(new Error('timed_out'));
      cleanup();
    }, 10000);
    const eventListener = (e: MessageEvent) => {
      if (e.data.type === 'authorization_response') {
        if (e.data.response.error) {
          reject(new Error(e.data.response.error));
        } else {
          setAuthToken(e.data.response.access_token);
          resolve({
            id_token: e.data.response.id_token,
            access_token: e.data.response.access_token,
            expires_in: e.data.response.expires_in,
          });
        }
        cleanup();
      }
    };

    window.addEventListener('message', eventListener);

    window.document.body.append(iframe);
    iframe.src = authorizeUrl;
  });
};
