import {
  Title,
  Hint,
  apiLogo,
  percentColour,
  useTheme,
  IconName,
} from '@jl/assets';
import {
  buildAmountString,
  buildUllageString,
  useShortTime,
  specialCharacters,
  isNullOrUndefined,
} from '@jl/utils';

import {
  Wall,
  AirGap,
  Fill,
  Top,
  Bottom,
  Logo,
  Marker,
} from './tank-summary.styles';
import { Icon } from './icon';

export interface TankSummaryProps {
  id: string;
  label: string;
  account: {
    label: string;
    detail?: string;
    api: string;
  };
  level: {
    time: Date | string;
    value: number;
    percent: number;
    maximum: number;
    voltage?: number | null;
  } | null;
  unit: string;
  warnings: number[];
  activatedAt?: string;
  billingStartedAt?: string;
  plan?: string;
  payer?: string;
  location?: {
    latitude: number;
    longitude: number;
    display: string;
  };
  onSelect?: (id: string) => void;
}

const batteryIcons: IconName[] = [
  'battery-slash',
  'battery-quarter',
  'battery-half',
  'battery-three-quarters',
  'battery-full',
];
const batteryIcon = (voltage: number): IconName => {
  const index = Math.floor((voltage / 100) * (batteryIcons.length - 1));
  return batteryIcons[index];
};

export const TankFill = ({
  percent,
  colour,
}: {
  percent: number | null;
  colour: string;
}) => (
  <Fill
    viewBox="0, 0, 200, 200"
    preserveAspectRatio="xMidYMin slice"
    height={`${
      (isNullOrUndefined(percent) ? 50 : percent > 100 ? 100 : percent) * 0.95
    }%`}
  >
    <path
      d="M0,200 L0,5.551 Q60,-2.775 120,5.551 L120,200 z"
      fill={colour}
      opacity="0.5"
    />
    <path
      d="M0,200 L0,2.775 Q60,8.326 110,2.775 Q160,-2.775 200,2.775 L200,200 z"
      fill={colour}
    />
  </Fill>
);

export const TankSummary = ({
  id,
  label,
  account,
  level,
  unit,
  warnings,
  onSelect,
}: TankSummaryProps) => {
  const theme = useTheme();
  const colour = percentColour(theme, level && level.percent, warnings);

  return (
    <Wall
      type="button"
      disabled={!level}
      onClick={() => onSelect && onSelect(id)}
    >
      <AirGap />
      <TankFill percent={level && level.percent} colour={colour} />
      {warnings?.map((w, i) => (
        <Marker key={i} percent={w * 0.95} />
      ))}

      <Hint>
        <strong>{label}</strong>
      </Hint>
      <Title>
        {level ? Math.round(level.percent) : ` ${specialCharacters.enDash} `}%
      </Title>
      <Hint>{buildAmountString(level, unit)}</Hint>
      {level?.voltage && (
        <Hint>
          <Icon
            name={batteryIcon(level.voltage)}
            title={`${level.voltage}%`}
            size={20}
          />
        </Hint>
      )}

      <Top>
        <Hint>
          <strong>{account.label}</strong>
          {account.detail && <br />}
          {account.detail}
        </Hint>
        <Hint>
          <strong>U: </strong>
          {buildUllageString(level, unit)}{' '}
        </Hint>
      </Top>
      <Bottom>
        <Hint>
          <Icon name="history" size={11} />{' '}
          <span>{useShortTime(level?.time)}</span>
        </Hint>
        <Logo src={apiLogo(account.api)} />
      </Bottom>
    </Wall>
  );
};

export const EmptyTank = ({ name }: { name?: string }) => (
  <Wall type="button" disabled>
    <Title>
      No {name || 'Tanks'}
      <br />
      Available
    </Title>
  </Wall>
);
