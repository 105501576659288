import { Row } from '@jl/assets';
import { Boundary, FilterForm } from '@jl/components';
import { useEvent, useTitle } from '@jl/utils';

import { defaultFilters, TankFilter } from './filters';
import { TankList } from './list';

const Tanks = () => {
  useTitle(['Tanks']);
  useEvent({
    category: 'Tanks',
    action: 'view',
  });
  return (
    <FilterForm name="tank" defaults={defaultFilters}>
      <Row>
        <TankFilter />
        <Boundary spinner>
          <TankList />
        </Boundary>
      </Row>
    </FilterForm>
  );
};

export default Tanks;
