import { useAccountQuery } from '@jl/graphql-schema';
import { Header, Boundary } from '@jl/components';
import { apiLogo } from '@jl/assets';
import {
  editButton,
  maybeAddNamespace,
  NotFoundError,
  useTitle,
} from '@jl/utils';

import { useParams, useHistory } from '../../hooks';
import AccountView from './view';
import AccountForm from './form';

const AccountDisplay = () => {
  const { uri, id, edit } = useParams(['uri', 'id', 'edit']);
  const { goBack, replace } = useHistory();

  const accountId = maybeAddNamespace('account', { uri, id });
  const { data } = useAccountQuery({
    variables: { id: accountId || 'UNSET' },
  });
  const account = data && data.account;
  if (!account) throw new NotFoundError('Account', accountId || '');
  useTitle([account.label, 'Accounts']);

  const Display = edit ? AccountForm : AccountView;
  return (
    <>
      <Header
        title={account.label}
        subtitle={account.detail || ''}
        image={apiLogo(account.api)}
        leftButton={{
          icon: 'arrowLeft',
          style: 'standard',
          onClick: goBack,
        }}
        rightButtons={[editButton({ edit, replace, id: accountId })]}
      />
      <Display account={account} />
    </>
  );
};

const Account = () => (
  <Boundary spinner>
    <AccountDisplay />
  </Boundary>
);

export default Account;
