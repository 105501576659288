import { Container } from '@jl/assets';
import { StatusLine, StatusBar } from '@jl/components';
import { useMetricsUpdatesQuery } from '@jl/graphql-schema';

import { apiOptions } from '../account/form';

const Updates = () => {
  const { data } = useMetricsUpdatesQuery();
  const updates = data && data.metrics.updates;
  if (!updates) return null;

  const warnings = updates.find(({ Label }) => Label === 'warnings');

  return (
    <Container>
      {apiOptions.map(({ value, label }) => {
        const durations = updates.find(
          ({ Label }) => Label === `${label} Duration`,
        );
        const invocations = updates.find(
          ({ Label }) => Label === `${label} Invocations`,
        );

        return (
          <StatusLine
            key={value}
            label={label}
            timestamps={durations?.Timestamps || []}
            invocations={invocations?.Values || []}
            durations={durations?.Values || []}
          />
        );
      })}
      <StatusBar
        label="Warnings"
        timestamps={warnings?.Timestamps || []}
        values={warnings?.Values || []}
      />
    </Container>
  );
};

export default Updates;
