import { useRef } from 'react';

import {
  Input,
  Radio,
  Filter,
  TankSummaryProps,
  Toggle,
  Switch,
} from '@jl/components';
import { SortDirection } from '@jl/utils';

export interface TankFilters {
  view: 'grid' | 'list' | 'map';
  useCurrentLocation?: boolean;
  search: string;
  sort: 'level' | 'label' | 'account' | 'voltage' | 'time';
  order: SortDirection;
}
const orderLabels = {
  level: ['Low', 'High'],
  label: ['A', 'Z'],
  account: ['A', 'Z'],
  voltage: ['Low', 'High'],
  time: ['Oldest', 'Newest'],
};
export const orderOptions = (sort: TankFilters['sort'] | undefined) =>
  sort
    ? [
        {
          label: `${orderLabels[sort][0]} to ${orderLabels[sort][1]}`,
          value: SortDirection.ASCENDING,
        },
        {
          label: `${orderLabels[sort][1]} to ${orderLabels[sort][0]}`,
          value: SortDirection.DESCENDING,
        },
      ]
    : [];

type SortParams = TankSummaryProps;
export const sorters = {
  level: ({ level }: SortParams) => (level && level.percent) || -1,
  label: ({ label }: SortParams) => label,
  account: ({ account }: SortParams) => account.label,
  voltage: ({ level }: SortParams) => (level && level.voltage) || undefined,
  time: ({ level }: SortParams) => (level && level.time.toString()) || '',
};

export const defaultFilters: TankFilters = {
  view: 'grid',
  useCurrentLocation: false,
  search: '',
  sort: 'level',
  order: SortDirection.ASCENDING,
};

export const TankFilter = () => {
  const focusRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  return (
    <Filter focusRef={focusRef}>
      <Radio
        inline
        label=""
        name="view"
        options={[
          { label: 'Grid', icon: 'grid', value: 'grid' },
          { label: 'List', icon: 'list', value: 'list' },
          { label: 'Map', icon: 'globe', value: 'map' },
        ]}
      />
      <Toggle name="view" match="map">
        <Switch name="useCurrentLocation" label="Use Current Location" />
      </Toggle>
      <Input label="Search" name="search" inputRef={focusRef} search />
      <Radio
        label="Sort By"
        name="sort"
        options={[
          { label: 'Tank Level', value: 'level' },
          { label: 'Tank Name', value: 'label' },
          { label: 'Account Name', value: 'account' },
          { label: 'Battery Level', value: 'voltage' },
          { label: 'Latest Update', value: 'time' },
        ]}
      />
      <Radio
        label="Order By"
        name="order"
        basedOn="sort"
        optionsFn={orderOptions}
      />
    </Filter>
  );
};
