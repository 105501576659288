import { useMemo } from 'react';

import { Feedback } from '@jl/components';
import { sendEvent } from '@jl/utils';

import { useAuth } from '../../hooks';

const UserFeedback = () => {
  const [user] = useAuth();
  return useMemo(
    () => (
      <Feedback
        onShow={() =>
          sendEvent({
            category: 'Feedback',
            action: 'view',
          })
        }
        onSubmit={async ({ type, request }) => {
          const body = new FormData();
          body.append('helpdesk_ticket[email]', user.email);
          body.append('helpdesk_ticket[subject]', `New Website Request`);
          body.append(
            'helpdesk_ticket[ticket_body_attributes][description_html]',
            [
              `<b>URL:</b> ${window.location.href}`,
              '',
              '<b>Description</b>',
              request.replace('\n', '<br/>'),
            ].join('<br/>'),
          );
          body.append('helpdesk_ticket[ticket_type]', type);
          body.append('meta[user_agent]', navigator.userAgent);
          await fetch(
            'https://joylevel.freshdesk.com/widgets/feedback_widget',
            {
              body,
              method: 'POST',
              mode: 'no-cors',
            },
          );
          sendEvent({
            category: 'Feedback',
            action: 'submit',
          });
        }}
      />
    ),
    [],
  );
};

export default UserFeedback;
