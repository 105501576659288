import { createContext, useReducer, useContext } from 'react';

import { Notification } from '@jl/components';

export type NotificationContext = {
  notifications: Notification[];
  addNotification: (n: Notification) => void;
  removeNotification: (id: string) => void;
};

type NotificationActions =
  | { type: 'ADD'; data: Notification }
  | { type: 'REMOVE'; data: { id: string } };

const notificationContext = createContext<NotificationContext>({
  notifications: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addNotification: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeNotification: () => {},
});
notificationContext.displayName = 'NotificationContext';
export const NotificationProvider = notificationContext.Provider;

export const setupNotification = () => {
  const [notifications, dispatch] = useReducer(
    (state: Notification[], action: NotificationActions) => {
      if (action.type === 'ADD') {
        return [
          ...state.filter(({ id }) => id !== action.data.id),
          action.data,
        ];
      }
      if (action.type === 'REMOVE') {
        return state.filter(({ id }) => id !== action.data.id);
      }
      return state;
    },
    [],
  );

  return {
    notifications,
    addNotification: (data: Notification) => {
      dispatch({ type: 'ADD', data });
      if (!data.timeout || data.timeout > 0) {
        setTimeout(() => {
          dispatch({ type: 'REMOVE', data: { id: data.id } });
        }, data.timeout || 5000);
      }
    },
    removeNotification: (id: string) =>
      dispatch({ type: 'REMOVE', data: { id } }),
  };
};

export const useNotifications = () => useContext(notificationContext);
