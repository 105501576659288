import {
  Html,
  Head,
  Preview,
  Body,
  Container,
  Text,
  Img,
  Link,
  Hr,
} from '@react-email/components';
import { CSSProperties } from 'react';
import { Footer } from './footer';
import { body, container, title, logo, subtitle } from './styles';

import { EmailTemplate } from './types';

type TankWarningProps = {
  tanks: {
    id: string;
    accountId: string;
    name: string;
    colour: string;
    level: string;
  }[];
};

const tankText: CSSProperties = {
  margin: '10px 0 0',
};

const tankLink: CSSProperties = {
  color: '#333333',
  fontSize: '18px',
  fontWeight: '300',
  textDecoration: 'none',
};

export const TankWarning: EmailTemplate<TankWarningProps> = {
  subject: () => `Your Tank Level Warnings`,
  tracking: ({ tanks }) => ({
    ids: tanks.map((t) => ({ tankId: t.id, accountId: t.accountId })),
  }),
  email: ({ tanks, host, support, address }) => (
    <Html>
      <Head />
      <Preview>{`${tanks.length} ${
        tanks.length > 1 ? 'tanks have' : 'tank has'
      } reach a warning level`}</Preview>
      <Body style={body}>
        <Container style={container}>
          <Img
            alt="JoyLevel"
            src="https://joylevel.io/JoyLevel.png"
            width="82px"
            style={logo}
          />
          <Text style={title}>Your Tank Level Warnings</Text>
          <Text style={subtitle}>
            The following tanks have reached their warning levels. To see more
            detail, visit {host}:
          </Text>
          <Hr />
          {tanks.map((tank) => (
            <Text key={tank.id} style={tankText}>
              <Link href={`https://${host}/${tank.id}`} style={tankLink}>
                {tank.name}:{' '}
                <strong style={{ color: tank.colour }}>{tank.level}</strong>
              </Link>
            </Text>
          ))}
          <Footer host={host} support={support} address={address} />
        </Container>
      </Body>
    </Html>
  ),
};
