import { useUnassignedGaugesQuery } from '@jl/graphql-schema';
import { MultiSelect } from '@jl/components';

const SelectGauge = () => {
  const { data } = useUnassignedGaugesQuery();
  const options = (data?.unassignedGauges || [])
    .filter((g) => !!g.latest)
    .map((g) => ({
      label: g.name,
      value: g.id,
    }));

  return (
    <MultiSelect
      name="gaugeIds"
      label="Gauges"
      placeholder="Select a Gauge"
      options={options}
    />
  );
};

export default SelectGauge;
