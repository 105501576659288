import { ReactNode } from 'react';

import { useField } from './use-form';

export interface ToggleProps {
  name: string;
  match: unknown;
  children: ReactNode;
}

export const Toggle = ({ name, match, children }: ToggleProps) => {
  const { value } = useField(name);

  return value == match ? <>{children}</> : null;
};
