import { styled } from '@jl/assets';

export const Copy = styled.input`
  background: none;
  border: none;
  width: auto;

  font-size: inherit;
  line-height: inherit;

  &:focus {
    outline: none;
  }
`;
