import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient, Middleware } from 'subscriptions-transport-ws';
import * as graphqlPrinter from 'graphql/language/printer';

import { getAuthToken } from '@jl/utils';

const TIMEOUT_MILLISECONDS = 5 * 60 * 1000;

const buildAuthHeader = (host: string) => ({
  host,
  Authorization: getAuthToken(),
});
const buildConnectionParams = (host: string) =>
  `header=${btoa(JSON.stringify(buildAuthHeader(host)))}&payload=${btoa(
    JSON.stringify({}),
  )}`;

const buildWSClass = (host: string) =>
  class AuthedWebSocket extends WebSocket {
    constructor(ws: string, protocols = undefined) {
      const uri = `${ws}?${buildConnectionParams(host)}`;
      super(uri, protocols);
    }

    set onmessage(handler: (event: unknown) => void) {
      super.onmessage = (event) => {
        if (event.data) {
          let data;
          try {
            data = JSON.parse(event.data);
          } catch {}
          if (data?.type === 'start_ack') return;
        }

        return handler(event);
      };
    }
  };

const buildOperationAdapter = (host: string): Middleware => ({
  applyMiddleware: async (options, next) => {
    // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
    options.data = JSON.stringify({
      query:
        typeof options.query === 'string'
          ? options.query
          : options.query && graphqlPrinter.print(options.query),
      variables: options.variables,
    });

    // AppSync only permits authorized operations
    options.extensions = { authorization: buildAuthHeader(host) };

    // AppSync does not care about these properties
    delete options.operationName;
    delete options.variables;
    // Not deleting "query" property as SubscriptionClient validation requires it

    next();
  },
});

export const buildWSLink = (ws: string, host: string) =>
  new WebSocketLink(
    new SubscriptionClient(
      ws,
      { timeout: TIMEOUT_MILLISECONDS, reconnect: true, lazy: true },
      buildWSClass(host),
    ).use([buildOperationAdapter(host)]),
  );
