type Range = { min: number; max: number };

type StrapLine = { mm: number; litres: number };

export type PressureCalculationType =
  | 'Temperature'
  | 'Pressure'
  | 'TemperatureAndPressure'
  | 'Ultrasonic'
  | 'ADC'
  | undefined
  | null;

export type PressureCalculationOptions = {
  probe_sg: number;
  actual_sg: number;
  offset_mm: number;
  offset_litres: number;
  strapping: StrapLine[];
  range?: { digital: Range; analog: Range; probe: Range; height: Range } | null;
};

const convertRange = (
  input: number,
  { rangeIn, rangeOut }: { rangeIn: Range; rangeOut: Range },
) => {
  const ratio = (rangeOut.max - rangeOut.min) / (rangeIn.max - rangeIn.min);
  const portion = (input - rangeIn.min) * ratio;
  return rangeOut.min + portion;
};

export const calculateInitialPressure = (
  initialDigital: number | undefined | null,
  { range }: PressureCalculationOptions,
) => {
  if (!range) throw new Error(`Range is required for ADC gauges`);

  const { digital, analog, probe, height } = range;
  const analogValue = convertRange(initialDigital || 0, {
    rangeIn: digital,
    rangeOut: analog,
  });
  const probeValue = convertRange(analogValue, {
    rangeIn: probe,
    rangeOut: height,
  });
  return { pressure: probeValue };
};

export const calculateActualPressure = (
  initialPressure: number | undefined | null,
  { probe_sg, actual_sg, offset_mm }: PressureCalculationOptions,
) => ({
  pressure: (initialPressure || 0) * (probe_sg / actual_sg) + offset_mm,
});

export const strappingVolumeSFL = ({
  strapping,
}: PressureCalculationOptions) => ({
  volumeSFL:
    strapping.length &&
    strapping.slice(0).sort((a, b) => b.litres - a.litres)[0].litres,
});

export const strappingFillSFL = ({
  strapping,
}: PressureCalculationOptions) => ({
  fillSFL:
    strapping.length && strapping.slice(0).sort((a, b) => b.mm - a.mm)[0].mm,
});

export const calculateActualFill = (
  initialUllage: number | undefined | null,
  options: PressureCalculationOptions,
) => ({
  fill: options.offset_mm - (initialUllage || 0),
});

export const sortStrappingTable = ({ strapping }: PressureCalculationOptions) =>
  strapping.slice(0).sort((a, b) => a.mm - b.mm);

export const estimateVolume = (
  mm: number,
  { below, above }: { below: StrapLine; above: StrapLine },
) => {
  const ratio = (above.litres - below.litres) / (above.mm - below.mm);
  const portion = (mm - below.mm) * ratio;
  return below.litres + portion;
};

export const estimateVolumeFromTable = (
  options: PressureCalculationOptions,
  actual: number,
) => {
  const sorted = sortStrappingTable(options);
  const index = sorted.findIndex(({ mm }) => mm > actual);

  let below = sorted[index - 1];
  let above = sorted[index];
  if (index === 0) {
    below = sorted[0];
    above = sorted[1];
  } else if (index < 1) {
    below = sorted[sorted.length - 2];
    above = sorted[sorted.length - 1];
  }

  return estimateVolume(actual, { below, above });
};

export const estimateDistance = (
  litres: number,
  { below, above }: { below: StrapLine; above: StrapLine },
) => {
  const ratio = (above.mm - below.mm) / (above.litres - below.litres);
  const portion = (litres - below.litres) * ratio;
  return below.mm + portion;
};

export const estimateDistanceFromTable = (
  options: PressureCalculationOptions,
  actual: number,
) => {
  const sorted = sortStrappingTable(options);
  const index = sorted.findIndex(({ litres }) => litres > actual);

  let below = sorted[index - 1];
  let above = sorted[index];
  if (index === 0) {
    below = sorted[0];
    above = sorted[1];
  } else if (index < 1) {
    below = sorted[sorted.length - 2];
    above = sorted[sorted.length - 1];
  }

  return estimateDistance(actual, { below, above });
};

export const roundFloat = (float: number) =>
  Math.round((float + Number.EPSILON) * 100) / 100;

export type PressureCalculationValues =
  | {
      pressure?: number | null | undefined;
      distance?: number | null | undefined;
      digital?: number | null | undefined;
    }
  | null
  | undefined;

export const calculateVolume = (
  input: PressureCalculationValues,
  options: PressureCalculationOptions,
  type: PressureCalculationType,
) => {
  if (options.strapping.length < 2)
    throw new Error('Strapping table must have at least 2 records');

  let calculatedFill: number;
  switch (type) {
    case 'ADC':
      const calculatedPressure =
        calculateInitialPressure(input?.digital, options).pressure || 0;
      calculatedFill =
        calculateActualPressure(calculatedPressure, options).pressure || 0;
      break;
    case 'Pressure':
    case 'TemperatureAndPressure':
      calculatedFill =
        calculateActualPressure(input?.pressure, options).pressure || 0;
      break;
    case 'Ultrasonic':
      calculatedFill = calculateActualFill(input?.distance, options).fill || 0;
      break;
    default:
      throw new Error(`Unable to calculate for ${type}`);
  }

  const { fillSFL } = strappingFillSFL(options);
  const { volumeSFL } = strappingVolumeSFL(options);
  const litres = estimateVolumeFromTable(options, calculatedFill);
  const reportedVolume = roundFloat(litres);
  const calculatedVolume = roundFloat(litres + options.offset_litres);

  return {
    calculatedFill,
    fillSFL,
    volumeSFL,
    reportedVolume,
    calculatedVolume,
  };
};

export const calculateLimit = (
  warningLevel: number,
  options: PressureCalculationOptions,
  type: PressureCalculationType,
) => {
  if (options.strapping.length < 2)
    throw new Error('Strapping table must have at least 2 records');

  const { volumeSFL } = strappingVolumeSFL(options);
  const warningVolume =
    (warningLevel / 100) * volumeSFL + options.offset_litres;
  const warningDistance = estimateDistanceFromTable(options, warningVolume);

  let warningAmount: number;
  switch (type) {
    case 'ADC':
      if (!options.range) throw new Error(`Range is required for ADC gauges`);
      const warningHeight =
        (warningDistance - options.offset_mm) /
        (options.probe_sg / options.actual_sg);
      const analogValue = convertRange(warningHeight, {
        rangeIn: options.range.height,
        rangeOut: options.range.probe,
      });
      warningAmount = convertRange(analogValue, {
        rangeIn: options.range.analog,
        rangeOut: options.range.digital,
      });
      break;
    case 'Pressure':
    case 'TemperatureAndPressure':
      warningAmount =
        (warningDistance - options.offset_mm) /
        (options.probe_sg / options.actual_sg);
      break;
    case 'Ultrasonic':
      warningAmount = options.offset_mm - warningDistance;
      break;
    default:
      throw new Error(`Unable to calculate for ${type}`);
  }

  return warningAmount;
};

export const calculateStaticAlarm = (
  warningLevel: number | undefined,
  options: PressureCalculationOptions | undefined,
  type: PressureCalculationType,
):
  | { enabled: false }
  | {
      enabled: true;
      polarity: 'higher' | 'lower';
      error: number;
      threshold: number;
    } => {
  if (!warningLevel || !options) return { enabled: false };

  try {
    const limit = Math.floor(calculateLimit(warningLevel, options, type) / 10);
    return {
      enabled: true,
      polarity: 'higher',
      error: 10,
      threshold: limit,
    };
  } catch (e) {
    return { enabled: false };
  }
};
