import { styled } from '@jl/assets';

export const SwitchToggle = styled.div<{ size: number }>`
  display: flex;
  margin: 3px 0 2px;
  padding: 2px;

  background-color: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.borderPale};
  border-radius: 100px;
  cursor: pointer;

  transition: background-color 0.3s ease;
  width: ${(props) => `${props.size * 2.5}px`};

  &::after {
    content: '';
    background-color: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.borderPale};
    border-radius: 100px;
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};

    position: relative;
    left: 0;
    transition: left 0.3s ease, opacity 0.3s ease;
  }

  input:checked + & {
    background-color: ${(props) => props.theme.successPale};
  }
  input:checked + &::after {
    left: ${(props) => `${props.size * 1.5 - 6}px`};
  }
`;

export const MultiSwitchToggle = styled.div<{
  setting?: boolean;
  size: number;
}>`
  display: flex;
  margin: 3px 0 2px;
  padding: 2px;
  position: relative;

  background-color: ${(props) =>
    props.setting === undefined
      ? props.theme.background
      : props.setting === true
      ? props.theme.successPale
      : props.theme.borderPale};
  border: 1px solid ${(props) => props.theme.borderPale};
  border-radius: 100px;
  cursor: pointer;

  transition: background-color 0.3s ease;
  width: ${(props) => `${props.size * 3}px`};

  &::after {
    content: '';
    background-color: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.borderPale};
    border-radius: 100px;
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};

    position: relative;
    left: ${(props) =>
      `${
        props.setting === undefined
          ? props.size - 3
          : props.setting === true
          ? props.size * 2 - 6
          : 0
      }px`};
    transition: left 0.3s ease, opacity 0.3s ease;
  }
`;

export const MultiSwitchButton = styled.button<{
  size: number;
  setting: boolean;
}>`
  cursor: pointer;
  position: absolute;
  border: 0;
  border-radius: 100px;
  background: none;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size * 1.2}px`};
  ${(props) => (props.setting ? 'right' : 'left')}: 0;
`;
